export default function Award(props) {
  return (
    <div className="flex-col justify-start items-start gap-2 inline-flex">
      <p className="text-black text-opacity-90 text-xs font-medium">
        {props.awardFrom}
      </p>
      <p className="text-zinc-400 text-xs font-medium">
        {props.awardName}
      </p>
      <p className="text-indigo-700 text-sm font-medium">
        {props.awardTitle}
      </p>
      <p className=" text-black text-opacity-60 text-xs font-normal">
        {props.year}
      </p>
    </div>
  );
}
