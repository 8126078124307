import React, { useState } from "react";
import ReactHtmlParser from 'react-html-parser';

function ReadMoreText({ text, maxLength }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <p className=" font-medium text-sm text-black">{/* text-[#4E5161] */}
        {isExpanded ? ReactHtmlParser(text) : ReactHtmlParser(text?.slice(0, maxLength))} {" "}
        {text?.length > maxLength && (
          <span>
            {isExpanded ? (
              <button
                onClick={toggleReadMore}
                className="font-medium italic text-[#5843BD]"
              >
                Read less
              </button>
            ) : (
              <button
                onClick={toggleReadMore}
                className="font-medium italic text-[#5843BD]"
              >
                <span className="font-normal text-sm text-[#4E5161]">...</span>{" "}
                Read more
              </button>
            )}
          </span>
        )}
      </p>
    </div>
  );
}

export default ReadMoreText;
