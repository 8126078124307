import React from "react";

export default function TextInput(props) {
  return (
    <input
      type="text"
      className="py-2 px-4 w-full rounded-lg border border-indigo-400 border-opacity-30 text-gray-900 focus:ring-indigo-400 focus:border-indigo-400 font-['Poppins'] text-base "
      placeholder={props.placeholder}
      id={props.id}
      name={props.name}
      onChange={props.onChangeHandler}
      disabled={props.disabled}
    />
  );
}
