
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";

export default function DashboardStatItem({ bgColor, btn_text, header, value, link, percentageDifference }) {
  const sidebar = useSelector(e => e.sidebarOpen.message);
  const navigate = useNavigate();
  console.log(percentageDifference);

  return (
    <div className={`w-full dash-stat  flex flex-col gap-y-2 rounded-[1rem] px-3 md:px-6 ${sidebar && 'lg:px-3  xl:px-6'} py-3 md:py-5 bg-white`}>
      <div className="py-1 flex items-center gap-x-2">
        <p className={`truncate ${sidebar ? 'text-xs lg:text-sm xl:text-base' : 'text-xs md:text-base leading-[1.5]'}`}>{header}
        </p>
        {percentageDifference || percentageDifference !== 0 ? (
          <div className="text-xs flex items-center gap-x-1 leading-normal">
            <p className={percentageDifference > 0 ? 'text-[#5843BD]' : 'text-[#FF6633]'}>
              {Math.abs(percentageDifference)}%
            </p>
            {percentageDifference > 0 ? (
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.51693 1.08398L0.683594 6.91732M6.51693 1.08398H1.26693M6.51693 1.08398V6.33398" stroke="#5843BD" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            ) : (
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.11458 6.91602L1.28125 1.08268M7.11458 6.91602H1.86458M7.11458 6.91602V1.66602" stroke="#FF6633" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            )}
          </div>
        ) : null}
      </div>
      <p className={`${sidebar ? 'lg:text-[20px] xl:text-[24px]' : 'text-[24px]'} text-[#898989] leading-[1.5] font-medium`}><span className={`text-black ${sidebar ? 'lg:text-[2rem] xl:text-[48px]' : 'text-[48px]'}`}>{value}</span> total</p>

      <button className="flex gap-x-1 items-center" onClick={() => navigate(link)}>
        <span className="text-xs zsm:text-sm text-[#5843BD] font-normal leading-normal">{btn_text}</span>
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.33333 6.5L6 4.16667M8.33333 6.5H3.66667M8.33333 6.5L6 8.83333M6 1.25C5.31056 1.25 4.62787 1.3858 3.99091 1.64963C3.35395 1.91347 2.7752 2.30018 2.28769 2.78769C1.80018 3.2752 1.41347 3.85395 1.14963 4.49091C0.885795 5.12787 0.75 5.81056 0.75 6.5C0.75 7.18944 0.885795 7.87213 1.14963 8.50909C1.41347 9.14605 1.80018 9.7248 2.28769 10.2123C2.7752 10.6998 3.35395 11.0865 3.99091 11.3504C4.62787 11.6142 5.31056 11.75 6 11.75C7.39239 11.75 8.72774 11.1969 9.71231 10.2123C10.6969 9.22774 11.25 7.89239 11.25 6.5C11.25 5.10761 10.6969 3.77226 9.71231 2.78769C8.72774 1.80312 7.39239 1.25 6 1.25Z" stroke="#5843BD" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    </div>
  )
}