import React, { useRef, useEffect } from "react";
import Datepicker from "flowbite-datepicker/Datepicker";
import $ from "jquery";

export default function CustomDate(props) {
  const datepickerRef = useRef(null);

  useEffect(() => {
    if (datepickerRef.current) {
      new Datepicker(datepickerRef.current, {
        // options
      });
    }
  }, []);

  useEffect(() => {
    // Assuming the component renders an element with class 'flowbite-datepicker'
    const datePickerElement = document.querySelector('.datepicker');

    if (datePickerElement) {
      // Apply the notranslate attribute to prevent automatic translation
      datePickerElement.setAttribute('data-notranslate', 'true');
      // datePickerElement.addClass('notranslate');
    }
  }, []);

  useEffect(() => {
    $(function () {
      $('.datepicker').on('focus', function () {
          $('.datepicker').addClass('notranslate');
          // $('.days').addClass('notranslate');
          $('.days-of-week').addClass('notranslate');
      });
    });
  }, [])

  // const handleClick = () => {
  //   $(function () {
  //     $('.datepicker').on('focus', function () {
  //         $('.datepicker').addClass('notranslate');
  //         // $('.days').addClass('notranslate');
  //         $('.days-of-week').addClass('notranslate');
  //     });
  //   });
  // }

  return (
      <div className="notranslate relative w-full" data-notranslate>
        <div className="relative" data-notranslate>
          <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
            <svg
              class="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
            </svg>
          </div>
          <div data-notranslate>
            <input 
              ref={datepickerRef}
              datepicker 
              datepicker-autohide 
              type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              placeholder="Select date"
              value={props.selectedDate}
              name={props.name}
              onSelect={props.ChangeHandler} 
            />
            {/* <input
              ref={datepickerRef}
              datepicker
              datepicker-autohide
              datepicker-orientation="bottom right"
              type="text"
              data-notranslate
              className="border border-indigo-400 border-opacity-30 text-gray-900 rounded-lg focus:ring-indigo-400 focus:border-indigo-400 block w-full pl-12 p-4 font-['Poppins'] text-base pickdate notranslate"
              placeholder="Select date"
              value={props.selectedDate}
              name={props.name}
              onSelect={props.ChangeHandler}
              // onClick={handleClick}
            /> */}
          </div>
        </div>
      </div>
   
  );
}
