import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import {
  getAllJobsPosted,
  job_loading,
  job_posting_details_loading,
  JobDescription_loading,
  JobDetails_loading,
  posting_loading,
  job_to_update_loading,
  job_applicants_loading,
  individual_job_cat_loading,
  notification_success,
  jobs_posted_success,
  candidate_type_success,
  getEmployerAnalyticsWeekly,
  candidate_category_success,
  recommended_candid,
  total_page,
  userName_from_db,
  job_doc_loading
} from "../../Redux/ActionCreator";
import DashboardOptionLink from "../common/dashboard_options_link";
import JobPostItem from "../common/job_posts_item";
import { ZoomSvg } from "../common/svgs/svgs";
import { useNavigate } from "react-router-dom";
import PaginationBtn from "../common/paginationBtn";

import { useAnalytics } from "../../context/AnalyticsContext";
import ClosedJobsItem from "../common/closed_jobs_items";
import DashboardStatItem from "../common/dashboard_stat_item";
import DashboardCandidateItem from "../common/dashboard_candidate_item";
import JobDetailItem from "../common/job_detail_item";
import JobDetailItemMobile from "../common/job_detail_item_mobile";
import HelmetProvide from "../common/helmet";
import { Popover } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { createPortal } from "react-dom";
import { DateRangePicker } from "@matharumanpreet00/react-daterange-picker";
import { getRecommendedCandidateByCategory, getRecommendedCandidate } from "../../utils/recommendationAlgo";

export default function EmployerJobs() {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const userInfo = useSelector(e => e.userInfo.user);
  const analyticsCus = useSelector(e => e.analytics_weekly.message);
  const recommend = useSelector((e) => e.recommended_candidate.message);
  const recommend_category = useSelector((e) => e.candidate_category.message);
  const jobsPostedState = useSelector(store => store.jobs_posted);
  const EmployerProfile = useSelector((prev) => prev.employer);
  const total_page_number = useSelector((prev) => prev.total_page);
  let tabSwitch = useSelector((e) => e.candidate_type.message);
  const profile = EmployerProfile.message;
  const jobs = jobsPostedState.message;
  const navigate = useNavigate();
  const [jobsToShow, setJobsToShow] = useState(jobs !== null && jobs !== undefined ? jobs : null);
  const upload_status = useSelector((state) => state.jobs_doc);
  const [activeTab, setActiveTab] = useState('all');
  const [filterJob, setFilterJob] = useState(false);
  let [tabColor, setTabColor] = useState(null);
  const [timeFrame, setTimeFrame] = useState("last 7 days");
  const [dateFrame, setDateFrame] = useState(() => {
    const currentDate = new Date();
    const pastDate = new Date();
    pastDate.setDate(currentDate.getDate() - 7); // Set the date 30 days before the current date
    return [pastDate, currentDate];
  });
  const [anchorEL, setAnchorEL] = useState(null);
  const open = Boolean(anchorEL);
  const idDuration = open ? 'duration-popover' : undefined;
  let [load, setLoad] = useState(false);
  const ourtotalpage = total_page_number.message;
  const [currentDashboardPage, setCurrentDashboardPage] = useState(1);

  const notify = useSelector(e => e.notification);
  const sidebar = useSelector(e => e.sidebarOpen.message);
  let notification_list = notify.message;
  let [saveNotification, setSaveNotification] = useState([]);
  const [closedJobsShown, setClosedJobsShown] = useState(5);
  const [modal, setModal] = useState(false);
  const startDateRef = useRef();
  const endDateRef = useRef();

  useEffect(() => {
    dispatch(userName_from_db());
    dispatch(getEmployerAnalyticsWeekly(dateFrame[0], dateFrame[1], 'last 7 days'));
  }, []);

  useEffect(() => {
    setJobsToShow(jobs);
  }, [jobs]);

  useEffect(() => {
    dispatch(job_loading(true));
    dispatch(posting_loading(true));
    dispatch(JobDetails_loading(true));
    dispatch(JobDescription_loading(true));
    dispatch(job_posting_details_loading(true));
    dispatch(job_to_update_loading(true));
    dispatch(job_applicants_loading(true));
    dispatch(individual_job_cat_loading(true));
    if (upload_status.message !== null) {
      dispatch(job_doc_loading(true));
    }
    if (profile?.message !== null) {
      dispatch(getAllJobsPosted(currentDashboardPage)); // pass in the page value, i default it to 1
    }

  }, [profile?.message, currentDashboardPage]);

  const handlePost = () => {
    if (profile !== null) {
      analytics.track("Post job btn click", {
        Type: "Employer",
        time: new Date(),
        Email: profile?.email,
        Company_name: profile?.name,
        profileId: profile?._id,
        userId: profile?.userId
      });
    }
    navigate("/employer/upload");
  }

  const handleClick = (event) => {
    if (anchorEL) {
      setAnchorEL(null); // close popover if open
    } else {
      setAnchorEL(event.currentTarget); // open popover if closed
    }
  };

  const handleClose = () => {
    setAnchorEL(null);
  };

  const showPendingJobs = () => {
    let pendingJ = jobs.filter(items => items.availability === true && items.display === false)
    setJobsToShow(pendingJ);
    setActiveTab('pending');
  }

  const showActiveJobs = () => {
    let activeJ = jobs.filter(items => items.availability === true && items.display === true)
    setJobsToShow(activeJ);
    setActiveTab('active');
  }

  const showClosedJobs = () => {
    let closedJ = jobs.filter(items => items.availability === false && items.display === false)
    setJobsToShow(closedJ);
    setActiveTab('closed');
  }

  const filterJobFunc = () => {
    setFilterJob(!filterJob)
  }

  const getRecommendedEmployeeByLastJobTitle = () => {
    // setLoad(true);
    setTabColor("title");
    dispatch(candidate_type_success("titlet"));
    let recommendation = getRecommendedCandidate(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
    recommendation.then(resp => resp.json())
      .then(resp => {
        setLoad(false);
        if (resp.success == true) {
          if (resp?.data !== null && resp?.data?.result?.length !== 0) {
            dispatch(recommended_candid(resp.data?.result));
            dispatch(total_page(resp.data?.page_num));
          } else {
            // do sth here;
            dispatch(recommended_candid([]));
            dispatch(total_page(1));
          }
        } else {
          // do sth here;
          // if (resp?.title === "Not Found") {
          //   dispatch(recommended_candid([]));
          //   dispatch(total_page(1));
          // }
          if (resp.success == false) {
            navigate("/");
          }
        }
      }).catch(err => {
        console.log("An error occured", err);
        setLoad(false);
        setTimeout(() => {
          toast.error("An Error occured. Please check your network and try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      })
  }

  const getTotalViews = (jobsArray) => {
    let views = jobsArray
      ?.map(job => job.impressions.length)  // Get the number of impressions for each job
      ?.reduce((total, impressions) => total + impressions, 0);  // Sum them up
    console.log(views);
    return views;
  };

  const getRecommendedEmployeeByCategory = useCallback(
    () => {
      // setLoad(true);
      setTabColor("category");
      dispatch(candidate_type_success("categoryc"));
      let recommendation = getRecommendedCandidateByCategory(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
      recommendation.then(resp => resp.json())
        .then(resp => {
          setLoad(false);
          if (resp.success == true) {
            if (resp?.data !== null && resp?.data?.result?.length !== 0) {
              dispatch(candidate_category_success(resp.data?.result));
              dispatch(total_page(resp.data?.page_num));
            } else {
              // do sth here;
              dispatch(candidate_category_success([]));
              dispatch(total_page(1));
            }
          } else {
            // do sth here;
            // dispatch(candidate_category_success([]));
            // dispatch(total_page(1));
            if (resp.success == false) {
              navigate("/");
            }
          }
        }).catch(err => {
          console.log("An error occured", err);
          setLoad(false);
          setTimeout(() => {
            toast.error("An Error occured. Please check your network and try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
        })
    }, []
  )

  const handleSetClick = () => {
    // Retrieve the values from the input fields
    const startDateValue = startDateRef.current.value;
    const endDateValue = endDateRef.current.value;

    // Validate the input dates
    if (!startDateValue || !endDateValue) {
      toast.error('Please select both start and end dates.');
      return;
    }
    const ranges = {
      startDate: startDateValue,
      endDate: endDateValue,
    };

    // Parse the dates
    const startDate = new Date(ranges.startDate);
    const endDate = new Date(ranges.endDate);
    setDateFrame([startDate, endDate]);
    dispatch(getEmployerAnalyticsWeekly(startDate, endDate, 'custom'));

    // Close the modal
    setModal(false);
  };

  let showModal = modal ? createPortal(
    <div onClick={() => setModal(false)} className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center md:bg-black md:bg-opacity-70 backdrop-blur-[2px] sm:bg-white xsm:bg-white">
      <div className="hidden md:block md:w-max" onClick={(e) => e.stopPropagation()} >
        <DateRangePicker
          open={true}
          onChange={(ranges) => {
            console.log(ranges);
            const startDate = new Date(ranges.startDate);
            const endDate = new Date(ranges.endDate);
            setDateFrame([startDate, endDate]);
            dispatch(getEmployerAnalyticsWeekly(startDate, endDate, 'custom'));
            setModal(false);
          }}
        />
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-y-4 rounded-md  xsm:max-w-[320px] w-full bg-white p-6 md:hidden shadow-lg"
      >
        <div className="flex flex-col gap-y-4">
          <input
            ref={startDateRef}
            type="date"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
            aria-label="Start Date"
          />
          <input
            ref={endDateRef}
            type="date"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
            aria-label="End Date"
          />
        </div>

        <button
          className="rounded-[3px] bg-[#5843BD] text-white px-4 py-2 hover:bg-[#4831A3] focus:outline-none focus:ring-2 focus:ring-[#5843BD] transition-colors cursor-pointer"
          onClick={handleSetClick}
        >
          Set
        </button>
      </div>
    </div>, document.getElementById("modal")
  ) : null;

  return (
    <>
      <HelmetProvide
        title="Moil | Jobs"
        description="Check out your jobs analytics and manage your jobs"
      />

      <div className=" h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex justify-center">
        <div className="w-full flex flex-col gap-8 md:px-0 lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]">
          <div className="flex lg:items-center gap-y-4 flex-col lg:flex-row lg:justify-between w-full">
            <div className="">
              <p className="text-[20px] md:text-[24px] leading-[1.5] font-semibold text-black">Jobs</p>
              <p className="text-base leading-[1.5] font-semibold text-black">Here you have everything to know about your jobs and hires.</p>
            </div>

            <DashboardOptionLink
              header="Post a Job"
              description={`Use our AI technology to create a job post in quick, easy steps.`}
              ellipseStyle="bg-white left-[60%] top-[10%]  bg-opacity-80 rounded-full blur-[77.64px] ipad:left-[181px] top-[-100px]"
              bgBack="dash-link-grad"
              svgType="postJob"
              onClick={handlePost}
              leftBordStyle="bg-[#FF6633]"
            />
          </div>

          <div className="p-4 rounded-[32px]">
            <p className="px-4 text-[20px] md:text-[24px] leading-[1.5] font-semibold text-[#131313] ">Quick Stats</p>
            <div className="flex center-center gap-4 justify-between flex-wrap">
              <DashboardStatItem
                percentageDifference={
                  parseInt(analyticsCus?.percentageDifference?.viewPercentage) || 0
                }
                header="Total jobs Impressions"
                btn_text="All jobs"
                bgColor="bg-white"
                value={analyticsCus?.totalView || 0}
                link="/employer/jobs"
              />
              <DashboardStatItem
                percentageDifference={
                  analyticsCus?.percentageDifference?.jobPercentage || 0
                }
                header="Total jobs posted"
                btn_text="All jobs"
                bgColor="bg-white"
                value={analyticsCus?.totalJobs || 0}
                link="/employer/jobs"
              />
              <DashboardStatItem
                percentageDifference={
                  analyticsCus?.percentageDifference?.applicationPercentage || 0
                }
                header="Applications"
                btn_text="applications"
                bgColor="bg-[#F1FBFF]"
                value={analyticsCus?.totalApplications || 0}
                link="/employer/jobs"
              />
              <DashboardStatItem
                percentageDifference={0}
                header="Active jobs"
                btn_text="active jobs"
                bgColor="bg-white"
                value={analyticsCus?.totalActive || 0}
                link="/employer/jobs"
              />
              <DashboardStatItem
                percentageDifference={0}
                header="Pending jobs"
                btn_text="pending jobs"
                bgColor="bg-[#FBF5F6]"
                value={analyticsCus?.pendingJob || 0}
                link="/employer/jobs"
              />
            </div>
          </div>

          <div className="flex gap-x-2 w-full items-center justify-end">
            <p className="text-base leading-normal font-medium text-[#131313]">Data Range</p>

            <div>
              <div aria-describedby={idDuration} onClick={handleClick} className="bg-white rounded-lg text-base p-2 cursor-pointer flex items-center gap-x-1 leading-normal text-[#5843BD]">
                <p className="capitalize">{timeFrame}</p>
                <svg className={open && 'rotate-180'} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.5 5.25L7 8.75L10.5 5.25" stroke="#7D7D7D" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <Popover
                id={idDuration}
                open={open}
                anchorEl={anchorEL}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <div className="p-4 leading-normal text-[#000000CC] flex justify-start min-w-[150px] md:min-w-[200px] flex-col gap-y-4 bg-white rounded-[4px] ">
                  <div onClick={() => {
                    const currentDate = new Date();
                    const sevenDaysAgo = new Date(currentDate);
                    sevenDaysAgo.setDate(currentDate.getDate() - 7);
                    setDateFrame([sevenDaysAgo, currentDate]);
                    setTimeFrame('last 7 days'.toLowerCase());
                    dispatch(getEmployerAnalyticsWeekly(sevenDaysAgo, currentDate, 'last 7 days'));
                    handleClose();
                  }}
                    className="cursor-pointer">
                    Last 7 days
                  </div>
                  <div className="cursor-pointer" onClick={() => {
                    const currentDate = new Date();
                    const thirtDaysAgo = new Date(currentDate);
                    thirtDaysAgo.setDate(currentDate.getDate() - 30);
                    setDateFrame([thirtDaysAgo, currentDate]);
                    setTimeFrame('last 30 days'.toLowerCase());
                    dispatch(getEmployerAnalyticsWeekly(thirtDaysAgo, currentDate, 'last 30 days'));
                    handleClose();
                  }}>Last 30 days</div>

                  <div className="cursor-pointer" onClick={() => {
                    setModal(true);
                    setTimeFrame('custom'.toLowerCase());
                    handleClose();
                  }}>Custom</div>
                </div>
              </Popover>
            </div>
          </div>

          <div className="bg-white flex flex-col gap-y-4 rounded-[16px] py-4">
            <div className="px-4  flex flex-col gap-y-4">
              <div className="flex gap-y-4 items-start md:items-center flex-col md:flex-row justify-between">
                <p className="text-[20px] lg:text-[24px] leading-[1.5] font-semibold text-[#131313]">Total jobs posted <span className="text-[#898989]">({jobs?.length})</span></p>

                <div className="flex items-center gap-x-1 sm:gap-x-4">
                  <div onClick={() => { setJobsToShow(jobs); setActiveTab('all'); }} className={`cursor-pointer px-4 py-2 text-xs font-medium text-[#111111] ${activeTab === 'all' && 'bg-[#5843BD26]'} whitespace-nowrap rounded-[16px] leading-normal`}>All jobs</div>
                  <div onClick={showActiveJobs} className={`cursor-pointer px-4 py-2 text-xs font-medium text-[#5843BD] ${activeTab === 'active' && 'bg-[#5843BD26]'} rounded-[16px] leading-normal`}>Active</div>
                  <div onClick={showPendingJobs} className={`cursor-pointer px-4 py-2 text-xs font-medium text-[#CE856C] ${activeTab === 'pending' && 'bg-[#5843BD26]'} rounded-[16px] leading-normal`}>Pending</div>
                  <div onClick={showClosedJobs} className={`cursor-pointer px-4 py-2 text-xs font-medium text-[#FF6633] ${activeTab === 'closed' && 'bg-[#5843BD26]'} rounded-[16px] leading-normal`}>Closed</div>
                </div>
              </div>
              <p className="text-sm md:text-base leading-[1.5]  font-normal text-black">This table displays the total number jobs you have posted. To change the table content, click on any of the capsules above or the ‘Switch table’ drop-down above.</p>
            </div>

            <div className="md:px-0 px-4">
              <table className="hidden md:table w-full rounded-lg">
                <thead className='bg-[#EFEFEF]'>
                  <tr className="leading-normal font-[200]  bg-[#5843BD1A]">
                    <th className="pl-4 xl:pl-8 py-3 w-[50%] lg:w-[45%] max-w-[350px] pr-4 rounded-tl-[8px]">
                      <p className="whitespace-nowrap truncate text-[#000000] text-start text-sm lg:text-base font-medium">Job Title</p>
                    </th>
                    <th className="w-[15%] max-w-[150px] pr-4">
                      <p className="whitespace-nowrap truncate text-[#000000]  text-center text-sm lg:text-base font-medium ">Applications</p>

                    </th>
                    <th className="w-[15%] pr-4">
                      <p className="whitespace-nowrap truncate text-[#000000] text-center text-sm lg:text-base font-medium ">Views</p>

                    </th>

                    <th className="w-[15%] px-4">
                      <p className="whitespace-nowrap truncate text-[#000000] text-center text-sm lg:text-base font-medium ">Status</p>
                    </th>

                    <th className="w-full pr-8 rounded-tr-[8px]">
                      <p className="whitespace-nowrap truncate text-[#000000] text-center text-sm lg:text-base font-medium ">Action</p>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {
                    jobsToShow?.length !== 0 ? jobsToShow?.map((job, index) => (
                      <>{job?.JobId !== null ?
                        <JobDetailItem
                          jobPosition={job?.jobTitle}
                          logo={profile?.photo}
                          urlToDetails={job?.display == true ? `/employer/jobs/${job.custom_name}` : `/employer/create-job/preview?jN=${job.custom_name}&complete=false`}
                          key={index}
                          status={job?.availability == true && job?.display == true ? "Active" : job?.availability == true && job?.display == false ? "Pending" : "Closed"}
                          createdAt={job?.createdAt}
                          totalApplicationReceived={job?.applications?.length}
                          views={job?.impressions?.length}
                        /> : null}</>
                    )) : <div></div>
                  }
                </tbody>
              </table>

              <div className="md:hidden flex gap-y-4 flex-col ">
                {
                  jobsToShow?.length !== 0 ? jobsToShow?.map((job, index) => (
                    <>{job?.JobId !== null ?
                      <JobDetailItemMobile
                        jobPosition={job?.jobTitle}
                        logo={profile?.photo}
                        urlToDetails={job?.display == true ? `/employer/jobs/${job.custom_name}` : `/employer/create-job/preview?jN=${job.custom_name}&complete=false`}
                        key={index}
                        status={job?.availability == true && job?.display == true ? "Active" : job?.availability == true && job?.display == false ? "Pending" : "Closed"}
                        createdAt={job?.createdAt}
                        totalApplicationReceived={job?.applications?.length}
                        views={job?.impressions?.length}
                      /> : null}</>
                  )) : <div></div>
                }
              </div>

              <div className="flex justify-end mb-4 w-full">
                <PaginationBtn
                  totalPages={ourtotalpage}
                  currentPage={currentDashboardPage}
                  onNextPage={() => {
                    if (currentDashboardPage < ourtotalpage) {
                      setCurrentDashboardPage((prev) => prev + 1);
                    }
                  }}
                  onPrevPage={() => {
                    if (currentDashboardPage > 1) {
                      setCurrentDashboardPage((prev) => prev - 1);
                    }
                  }}
                  onSelectPage={(selectedPage) => {
                    setCurrentDashboardPage(selectedPage);
                  }}
                />
              </div>
            </div>
          </div>

          {/* Recommended */}

          <div className="flex bg-white p-6 rounded-[1rem] gap-y-4 flex-col">
            <div className="flex sm:items-center flex-col sm:flex-row justify-between py-2">
              <p className="text-[#131313] text-[20px] leading-normal font-medium">
                Recommended Candidates
              </p>
              <div className={`${filterJob ? 'bg-white shadow rounded-tl-[4px] rounded-tr-[4px]' : ''} p-2 relative`}>
                <button className="flex items-center gap-x-1 focus:bg-white focus:border-white focus:outline-white" onClick={filterJobFunc}>
                  <span className="text-sm text-[#454545] font-normal leading-normal">Industry Matches</span>
                  <svg className={`${filterJob ? 'hidden' : 'block'}`} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 5.75L7 9.25L10.5 5.75" stroke="#7D7D7D" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  <svg className={`${filterJob ? 'block' : 'hidden'}`} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 8.75L7 5.25L10.5 8.75" stroke="#7D7D7D" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </button>
                <div className={`${filterJob ? 'block h-max' : 'hidden h-[0px]'} transit-all absolute  bg-white p-2 pt-0 left-0 w-full shadow justify-start rounded-bl-[4px] rounded-br-[4px]`}>
                  <div className="flex flex-col border-t mt-1 pt-1 border-[#898989] gap-y-1">
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeByLastJobTitle}>Job Matches</button>
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeByCategory}>Industry Matches</button>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-black text-opacity-60 text-base font-medium max-w-[6] mb-3">
              These are the candidates whose skills match your company description. You can switch your recommendations by clicking on <span className="text-[#131313]">Industry Matches’</span> dropdown at the top righ-hand corner of this box.
            </p>
            <div className="flex justify-center">
              <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2 desktop:grid-cols-3">
                <>{tabSwitch == "title" || tabColor === "title" || tabSwitch == "titlet" ?
                  <>
                    {recommend !== null && recommend?.length !== 0 ? (
                      <>
                        {recommend?.slice(0, 6)?.map((data) => (
                          <DashboardCandidateItem
                            key={data?.userId?.profile_id}
                            candidate={data?.Fullname}
                            candidateJob={data?.Job_type}
                            candidateLocation={data?.Address}
                            candidateExperienceLevel={data?.Years_of_experience}
                            candidateBio={data?.About}
                            candidateRating={data?.Rating}
                            candidateSalary="35"
                            profileId={data?.userId?.profile_id}
                            candidateImage={data?.photo}
                          />
                        ))}
                      </>
                    ) : (
                      <h2>
                        Sorry, we presently do not have candidate to recommend.
                      </h2>
                    )}
                  </> :
                  <>
                    {recommend_category !== null && recommend_category?.length !== 0 ? (
                      <>
                        {recommend_category?.slice(0, 6)?.map((data) => (
                          <DashboardCandidateItem
                            key={data?.userId?.profile_id}
                            candidate={data?.Fullname}
                            candidateJob={data?.Job_type}
                            candidateLocation={data?.Address}
                            candidateExperienceLevel={data?.Years_of_experience}
                            candidateBio={data?.About}
                            candidateRating={data?.Rating}
                            candidateSalary="35"
                            profileId={data?.userId?.profile_id}
                            candidateImage={data?.photo}
                          />
                        ))}
                      </>
                    ) : (
                      <h2>
                        Sorry, we presently do not have candidate to recommend.
                      </h2>
                    )}
                  </>
                }</>
              </div>
            </div>
            <div>
              <Link className="text-[14px] leading-normal font-medium text-[#5843BD] md:text-[20px]">View more recommendations</Link>
            </div>
          </div>

        </div>
      </div>
      {showModal}
      <ToastContainer />
    </>
  );
}
