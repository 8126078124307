export default function PersonalInfo(props) {
  return (
    <div className="flex-col justify-start items-start gap-2 inline-flex flex-none">
      <p className="text-black text-opacity-60 text-xs font-normal">
        {props.field}
      </p>
      <p className="text-black text-sm font-medium">
        {props.value}
      </p>
    </div>
  );
}