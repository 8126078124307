import React, { useState } from "react"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BackButton from "../../common/back_button"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveIntoServerCache } from "../../../utils/saveIntoCache";

const SelectLanguage = (props) => {
    const [ defaultLang, setDefaultLang ] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNavigate = () => {
        // do sth base on the route. because this component is reuseable.
        navigate(`${props.back}`);
    }
    const handleEnglishClick = async () => {
        setDefaultLang("English");
        await saveIntoServerCache("Language", "English");
        props.handleClick();
    }

    const handleSpanishClick = async () => {
        setDefaultLang("Spanish");
        await saveIntoServerCache("Language", "Spanish");
        props.handleClick();
    }

    return (
        <div className="w-full h-[100vh] bg-transparent flex justify-center ">
            <div className="w-full flex md:items-center  justify-center">
               <div className="bg-white md:rounded-xl md:w-[45vw] md:h-[350px] py-8 px-4 w-full  xsm:h-[420px]">
                    <BackButton onClick={handleNavigate}/>
                    <div className="flex items-center justify-center mb-3 mt-6">
                        <h3 className="text-lg sm:text-2xl font-medium text-[#5843BD]">
                            Thank you for choosing 
                        </h3>
                        <figure>
                            <img
                                width={48}
                                height={48}
                                src='https://res.cloudinary.com/drlcisipo/image/upload/v1706475719/logo_m0jvut.png' // coin.png
                                className="pointer-events-none ml-2"
                                alt="coin"
                            />
                        </figure>
                    </div>
                    <div className="mb-6 py-3">
                        <h3 className="text-center text-base font-medium text-black">
                            What language do you want <br/> your {window.location.pathname == "/employer/create-job" ? "job" : "resume"} to be in?
                        </h3>
                    </div>
                    <div className="md:flex md:items-center md:px-8 md:justify-between w-full mb-6">
                        <button
                            className={` border-2 ${defaultLang == "English" ? " border-[#FF6633]" : " border-[#DCDCDC]"} p-4 w-full  md:w-[250px] rounded-lg border text-gray-900 focus:ring-orange-400 focus:border-orange-400 font-['Poppins'] text-base flex items-center justify-between `} onClick={handleEnglishClick}
                        >
                            <div className="flex items-center">
                                <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704286/Website%20images/English_uj5huz.jpg" alt="English icon" className="object-cover"/>
                                <h1 className="text-base font-medium text-black ml-4">English</h1>
                            </div>
                            <CheckCircleIcon className={` ${defaultLang == "English" ? "text-[#FF6633]" : "text-[#DCDCDC]"}`}/>
                        </button>

                        <button
                            className={`xsm:mb-[6px] xsm:mt-[25px] vsm:mt-[25px] md:mt-0 md:ml-4 border-2 ${defaultLang == "Spanish" ? " border-[#FF6633]" : "border-[#DCDCDC]"} p-4 w-full md:w-[250px] rounded-lg border text-gray-900 focus:ring-orange-400 focus:border-orange-400 font-['Poppins'] text-base flex items-center justify-between `} onClick={handleSpanishClick}
                        >
                            <div className="flex items-center">
                                <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704281/Website%20images/Spain_shstdy.jpg" lt="spanish icon"/>
                                <h1 className="text-base font-medium text-black ml-4">Spanish</h1>
                            </div>
                            <CheckCircleIcon className={` ${defaultLang == "Spanish" ? "text-[#FF6633]" : "text-[#DCDCDC]"}`}/>
                        </button>
                    </div>
               </div>
            </div>
        </div>
    )
}

export default SelectLanguage;