import React, { useState, useEffect, useRef } from 'react';

const TranslateButton = (props) => {
    const [isSpanish, setIsSpanish] = useState(false);
    const [scriptAdded, setScriptAdded] = useState(false);
    const [count, setCount] = useState(0);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                includedLanguages: 'es,en',
                layout: google.translate.TranslateElement.InlineLayout.TEXT,
                autoDisplay: false
            },
            "google_translate_element"
        );
    };

    useEffect(() => {
        if (count % 2 === 0 && count !== 0) {
            location.reload();
        }
    }, [count])



    useEffect(() => {
        if (!scriptAdded) {
            const myTranslateScript = document.querySelectorAll(
                'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
            );

            if (myTranslateScript.length < 1) {
                const addScript = document.createElement("script");
                addScript.setAttribute(
                    "src",
                    "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                );

                document.body.appendChild(addScript);
                window.googleTranslateElementInit = googleTranslateElementInit;

                setScriptAdded(true);
            }
        }
    }, [scriptAdded]);

    const toggleTranslation = () => {
        setIsSpanish(!isSpanish);
        const newLang = isSpanish ? 'en' : 'es';
        translatePage(newLang);
        triggerHtmlEvent(document.querySelector('.goog-te-combo'), 'change');
        setCount(prev => prev + 1);
    };


    const triggerHtmlEvent = (element, eventName) => {
        let event;
        if (document.createEvent) {
            event = document.createEvent('HTMLEvents');
            event.initEvent(eventName, true, true);
            element.dispatchEvent(event);
        } else {
            event = document.createEventObject();
            event.eventType = eventName;
            element.fireEvent('on' + event.eventType, event);
        }
    };

    const translatePage = (newLang) => {
        if (document.querySelector('.goog-te-combo') == undefined) {
            if (window.location.pathname == "/") {
                window.location.reload()
            }
        } else {
            document.querySelector('.goog-te-combo').value = newLang;
        }
    };


    return (
        <div className={`cursor-pointer ${props.parentClass}`} onClick={toggleTranslation}>
            <div id="google_translate_element" className="hidden"></div>
            <button
                className={`${props.className}`}
                // disabled={!scriptAdded}
            >
                <img
                    src={
                        isSpanish
                            ? 'https://res.cloudinary.com/drlcisipo/image/upload/v1714663084/English_1_z3fa77.png'
                            : 'https://res.cloudinary.com/drlcisipo/image/upload/v1713288601/Website%20images/Spanish_2_oaawih.svg'
                    }
                    alt="Spanish Flag"
                    className="w-7 h-7"
                />
                <span className={`${props.textClassName} notranslate`}>
                    {isSpanish ? 'ENG' : 'ESP'}
                </span>
            </button>
        </div>
    );
};

export default TranslateButton;