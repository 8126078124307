import React, { useEffect, useState } from "react"
import {
  Document,
  Page,
  Text,
  View,
  Link,
  StyleSheet,
  Line,
  Svg,
  Path,
  Image,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import ReactPDF from '@react-pdf/renderer';
// require useSelector from react-redux
import { useSelector } from "react-redux";

import font from "./font/poppins-v20-latin-600.ttf";
import font1 from "./font/poppins-v20-latin-300.ttf";
import { Font } from '@react-pdf/renderer'

Font.register({ family: 'Poppins', src: font1});
Font.register({ family: 'Poppins-bold', src: font});


// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    color: "black",
    marginLeft: 5,
    fontFamily: "Poppins",
    paddingBottom: 35,
    paddingTop: 50
  },
  text_h4: {
    fontSize: 30,
    fontFamily: "Poppins-bold",
    left: 40,
  },

  viewer: {
    width: '75vw', height: '100vh',
     '@media maxWidth: 500px' : {
      width: "55vw", height: "60vh"
    }
  },
});

// Create Document Component
function MyDocument(props) {

  const details = [props.details];
  console.log(details);
  
  const [ wrap_stat, setWrap_stat ] = useState(false);
  useEffect(() => {
    if (details[0].Experience.length >= 2) {
      setWrap_stat(true);
    } else {
      setWrap_stat(false);
    }
  }, [])
  /* 
  const skills = details[0]?.Technical_skills.split(", ");
  const personal_skills = details[0]?.Personal_skills.split(", "); */
  let my_interest;
  if (details[0] !== null) {
    if (Array.isArray(details[0]?.Interest)) {
      my_interest = details[0]?.Interest;
    } else if (typeof details[0]?.Interest === "string") {
      my_interest = details[0]?.Interest.split(", ");
    }
  }
  if (Array.isArray(details[0]?.Technical_skills) === false ) {
    if (typeof details[0]?.Technical_skills === "string") {
      details[0].Technical_skills = details[0]?.Technical_skills.split(", ");
    }
   }

   if (Array.isArray(details[0]?.Personal_skills) === false ) {
    if (typeof details[0]?.Personal_skills === "string") {
      details[0].Personal_skills = details[0]?.Personal_skills.split(", ");
    }
   }
  
  console.log("my_interest", my_interest);

  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => { // to avoid breaking of words.
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });


  return (/* 
    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
      { details[0] === null ? <div><h3>No values to map into the cv yet, wait a little bit for chatGPT to complete the resume.</h3></div> : 
      <div className="mr-15">
         */
        <Document pageMode="useAttachments">
              <Page size="A4" style={styles.page} >
        { details?.map((data, key) => {
          return (
            <View key={key}>
                <View  style={{display: "flex", flexDirection: "row"}}>
                  <View style={{width: 400}}>
                    <View style={{position: 'relative', width: "104px", height: "104px", borderRadius: "50%", backgroundColor: "#D6D8F9", left: "20px", top: 0}}>
                    </View>
                    <View style={{ display: "flex", width: 500, flexDirection: "row", flexWrap: "wrap", top: -75}}>
                      <Text style={styles.text_h4}>{data.Fullname} </Text>
                    </View>
                  </View>
                </View>
                <View style={{ display: "flex", flexDirection: "row", top: -40, width: 800}}>
                    {data.CvType === null || data.CvType === undefined ? null : <View style={{position: 'relative', fontFamily: "Poppins", fontSize: 12, top: -44, left: 42, display: "flex", flexDirection: "row", flexWrap: "wrap"}} >
                      <Text>{data.CvType} </Text>
                    </View>}
                    <View style={{top: -34, left: 60}}>
                      <Svg  height="30" width="500">
                        <Line
                          x1="0"
                          y1="0"
                          x2="380"
                          y2="0"
                          strokeWidth={2}
                          stroke="#C5C5C5"
                        />
                      </Svg>
                    </View>
                  </View>
                  <View style={{left: 180, top: -80, display: "flex", flexDirection: "row"}} >
                    
                    {data.Phone_number?.length !== 0 ? 
                    <View style={{display: "flex", flexDirection: "row"}}>
                    <View style={{left: 0, height: 33, width: 32, padding: 0}}>
                      <Svg height="50" width="50"  style={{ marginTop: -10}}>
                        <Path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" stroke="#FF6633" strokeWidth={1}/>
                      </Svg>
                    </View>
                    <View style={{position: "relative", left:0, marginTop: -14}}><Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0}}>Phone</Text><Text style={{fontSize: 8, left:0}}>{data.Phone_number}</Text></View>
                  </View> : null}
                    
                    {data.Email?.length !== 0 ? 
                    <View style={{display: "flex", flexDirection: "row"}}>
                    <View style={{left: -10, height: 33, width: 32, padding: 0}}>
                      <Svg height="50" width="50" style={{left: 20, marginTop: -10 }} >
                        <Path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" stroke="#FF6633"   strokeWidth={1} />
                      </Svg>
                    </View>
                    <View style={{position: "relative", left:-10, marginTop: -12}}>
                      <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 10}}>Mail</Text>
                      <Text style={{fontSize: 8, left:10}}>{data.Email}</Text>
                    </View>
                  </View> : null}
                    {data.Website?.length !== 0 ? 
                    <View style={{display: "flex", flexDirection: "row"}}>
                    <View  style={{left: -10, height: 33, width: 32, padding: 0}}>
                      <Svg height="50" width="50" style={{left: 20, marginTop: -10 }} >
                        <Path d="M6.235 6.453a8 8 0 0 0 8.817 12.944c.115-.75-.137-1.47-.24-1.722-.23-.56-.988-1.517-2.253-2.844-.338-.355-.316-.628-.195-1.437l.013-.091c.082-.554.22-.882 2.085-1.178.948-.15 1.197.228 1.542.753l.116.172c.328.48.571.59.938.756.165.075.37.17.645.325.652.373.652.794.652 1.716v.105c0 .391-.038.735-.098 1.034a8.002 8.002 0 0 0-3.105-12.341c-.553.373-1.312.902-1.577 1.265-.135.185-.327 1.132-.95 1.21-.162.02-.381.006-.613-.009-.622-.04-1.472-.095-1.744.644-.173.468-.203 1.74.356 2.4.09.105.107.3.046.519-.08.287-.241.462-.292.498-.096-.056-.288-.279-.419-.43-.313-.365-.705-.82-1.211-.96-.184-.051-.386-.093-.583-.135-.549-.115-1.17-.246-1.315-.554-.106-.226-.105-.537-.105-.865 0-.417 0-.888-.204-1.345a1.276 1.276 0 0 0-.306-.43zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z" stroke="#FF6633" strokeWidth={1} />
                      </Svg>
                    </View>
                    <View style={{position: "relative", left:0, marginTop: -10}}>
                      <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 10}}>Website</Text>
                      <Text style={{fontSize: 8, left:10, width:100}}><Link src={data.Website} style={{ color: "black", textDecoration: "none"}}>{data.Website}</Link></Text>
                    </View>
                  </View> : null}
                  </View>

                <View style={{ display: "flex", flexDirection: "row", position: "relative", top: -75,}}>
                  <View style={{left: 42}}>
                      {
                        data.Lang[0]?.lang === 'English' ? 
                        <Text style={{fontSize: 10, fontFamily: "Poppins-bold",}}>Address</Text> :  <Text style={{fontSize: 10, fontFamily: "Poppins-bold", color: "#000000"}}>Dirección</Text> 
                      }
                    <View style={{ width: 200,  height: 5, width: 42}}>
                      <Svg  height="2" width="500" style={{left: 5, top: 4}} >
                      <Line
                      x1="0"
                      y1="0"
                      x2="35"
                      y2="0"
                      strokeWidth={2}
                      stroke="#8576CF"
                    />
                    </Svg></View>
                    <Text style={{ width: 84, fontSize: 8, marginTop: 10 }}>{data.Address}</Text>
                  </View>

                  <View  style={{ position: "relative", left: 97,  }}>
                    <View>
                      {
                        data.Lang[0]?.lang === 'English' ? 
                        <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0}} >Profile</Text> :  <Text style={{left: 0, fontSize: 10, fontFamily: "Poppins-bold"}} >Perfil</Text> 
                      }
                      <Svg  height="2" width="500" style={{ top: 4}} >
                      <Line
                      x1="0"
                      y1="0"
                      x2="35"
                      y2="0"
                      strokeWidth={2}
                      stroke="#8576CF"
                    />
                    </Svg>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", left: 0}}>
                      <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", left: 0, top: 10, width: 360 }}> 
                        <Text style={{fontSize: 8, fontFamily: "Poppins", left: 0, top: 20,}}>{data.Professional_summary} </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View  style={{left: 70, top: -50}} >
                  <Svg  height="2" width="500" style={{left: 112, top: 0}} >
                    <Line
                      x1="0"
                      y1="0"
                      x2="380"
                      y2="0"
                      strokeWidth={2}
                      stroke="#C5C5C5"
                    />
                    </Svg>
                    <Svg  height="2" width="400" style={{left: 250, top: -1.8}} >
                    <Line
                      x1="0"
                      y1="0"
                      x2="230"
                      y2="0"
                      strokeWidth={3}
                      stroke="black"
                    />
                    </Svg>
                </View>

                {/* Experience start */}
                { data.Experience?.length === 0 ? null : 
                  <View style={{ display: "block", position: "relative", left: 40, marginTop: -50,}}>
                  <View style={{width: 45}}>
                    <View style={{ width: 45  }}>
                        {
                          data.Lang[0]?.lang === 'English' ?
                          <View>
                            <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67 }}>Work</Text>
                            <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67 }}>Experience</Text>
                          </View> : <View>
                            <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67 }}>Trabajo</Text>
                            <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67 }}>Experiencia</Text>
                          </View>
                        }
                      <Svg  height="2" width="100" style={{left: 5, top: 4}} >
                      <Line
                      x1="0"
                      y1="0"
                      x2="35"
                      y2="0"
                      strokeWidth={2}
                      stroke="#8576CF"
                    />
                    </Svg>
                    </View>
                  </View>

                  <View style={{position: "relative", left: 140, }}>
                    {data.Experience?.map((datae, key) => (
                      <View style={{  display: "flex", flexDirection: "row",  position: "relative",  marginTop: 20, bottom: 30}} key={key}>
                        <View style={{ width: 100 }}>
                          <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width:95}}>{datae.Work_start_date} - {datae.Work_end_date}</Text>
                          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", left: 0, width: 90 }}>
                            <Text style={{ fontFamily: "Poppins", fontSize: 8, left: 0, top: 5,}}>{datae.Where_i_worked}</Text>
                          </View>
                          {/* 
                          <Text style={{ width: 85, fontFamily: "Poppins", fontSize: 8, left: 0, top: 10}}>{datae.Where_i_worked_location}</Text> */}
                        </View>
                        <View style={{position: "relative", left: 0}}> 
                        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 240,}}>
                          <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0,  }}>{datae.My_job_title} </Text>
                        </View>
                          { Array.isArray(datae.Day_to_day_work) === true ? 
                          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: 2, width: 240}}>
                            {datae.Day_to_day_work?.map((ex_data, key) => (
                              <View style={{display: "flex", flexDirection: "row",  }} key={key}>
                                 <View>
                                  <Text style={{ marginRight: 8, marginTop: -5 }}>•</Text>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap"  }}>
                                  {ex_data[0] === "-" ? <Text style={{marginLeft: 0, marginTop: 2, fontFamily: "Poppins", fontSize: 8, color: "#000000"}}>{ex_data.slice(1, ex_data?.length)}</Text> : <Text style={{marginLeft: 0, marginTop: 2, fontFamily: "Poppins", fontSize: 8, color: "#000000"}}>{ex_data.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')}</Text>}
                                </View>
                              </View>
                          ))}
                          </View> : <View>
                            <Text style={{marginLeft: 0, marginTop: 2, fontFamily: "Poppins", width: 240, fontSize: 8, color: "#000000"}}>{datae.Day_to_day_work.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')}</Text>
                          </View>
                          }
                        </View>
                      </View>
                    )
                    )}
                  </View>

                  {data.Education?.length === 0 ? null : 
                  <View style={{position: "relative", marginTop: -20}}>
                    <Svg  height="2" width="500" style={{left: 140, top: 0}} >
                    <Line
                      x1="0"
                      y1="0"
                      x2="350"
                      y2="0"
                      strokeWidth={2}
                      stroke="#C5C5C5"
                    />
                    </Svg>
                    <Svg  height="2" width="500" style={{left: 250, top: -1.8}} >
                    <Line
                      x1="0"
                      y1="0"
                      x2="250"
                      y2="0"
                      strokeWidth={3}
                      stroke="black"
                    />
                    </Svg>
                  </View>}
                </View>
                
                }
                {/* Experience end */}

                {/* Education start */}
                { data.Education[0]?.School === "No school" || data.Education?.length === 0 ? null : 
                  <View style={{  display: "flex", flexDirection: "row",  position: "relative", left: 40, margingTop: 10,}}>
                    <View style={{width: 45}}>
                      <View style={{width: 100,  height: 33, width: 42}}>
                        {
                          data.Lang[0]?.lang === 'English' ?
                          <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67, height: 23}}>Education</Text> : <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67, height: 23}}>Educación</Text>
                        }
                        <Svg  height="2" width="100" style={{left: 5, top: 4}} >
                        <Line
                        x1="0"
                        y1="0"
                        x2="35"
                        y2="0"
                        strokeWidth={2}
                        stroke="#8576CF"
                      />
                      </Svg>
                      </View>
                    </View>

                    <View  style={{ position: "relative", left: 97, marginTop: 10,}}>
                      {data.Education?.map((dataedu, key) => (
                        <View style={{ display: "flex", flexDirection: "row", position: "relative", marginTop: 5, }} key={key}>
                          <View style={{ width: 100 }}>
                            <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 70}}>{dataedu.End_date}</Text>
                            <View style={{position: "relative", left: 0, width: 100,}}>
                              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap"  }}>
                                <Text style={{ fontFamily: "Poppins", fontSize: 8, left: 0, top: 5 }}>{dataedu.School} </Text>
                              </View>
                            </View>
                          </View>
                          <View style={{position: "relative", left: 0, width: 215,}}>
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap"  }}>
                              <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0,  }}>{dataedu.Degree} {dataedu.Course}</Text>
                            </View>
                          </View>
                        </View>
                      ))}
                    </View>
                  </View>
                }
                {/* Education end */}

                { data.Technical_skills?.length === 0 && data.Personal_skills?.length === 0 ? null :
                  <View style={{position: "relative", marginTop: 10}}>
                  <Svg  height="2" width="500" style={{left: 180, top: 0}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="350"
                    y2="0"
                    strokeWidth={2}
                    stroke="#C5C5C5"
                  />
                  </Svg>
                  <Svg  height="2" width="500" style={{left: 270, top: -1.8}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="250"
                    y2="0"
                    strokeWidth={3}
                    stroke="black"
                  />
                  </Svg>
                </View>
                }

                {/* skills start */}
                {data.Technical_skills?.length === 0 && data.Personal_skills?.length === 0 ? null : 
                  <View style={{ display: "flex", flexDirection: "row", position: "relative", left: 40, marginTop: 10,  marginBottom: 40}}>
                  <View>
                    <View style={{width: 100,  height: 33, width: 42}}>
                      {
                        data.Lang[0]?.lang === 'English' ? 
                        <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67 }}>Skills</Text> : <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67 }}>Habilidades</Text> 
                      }
                      <Svg  height="2" width="100" style={{left: 0, top: 2}} >
                      <Line
                      x1="0"
                      y1="0"
                      x2="35"
                      y2="0"
                      strokeWidth={2}
                      stroke="#8576CF"
                    />
                    </Svg>
                    </View>
                  </View>

                    <View style={{ display: "block", position: "relative", left: 97, top: 10,  height: "auto"}}>
                      {data.Technical_skills?.length === 0 ? null :
                        <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                          {
                          data.Lang[0]?.lang === 'English' ? 
                          <Text style={{fontSize: 10, fontFamily: "Poppins-bold", marginBottom: 5, width: 100}}>Technical Skills</Text> : <Text style={{fontSize: 10, fontFamily: "Poppins-bold", marginBottom: 5, width: 100}}>Habilidades Técnicas</Text> 
                          }
                          <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: 245}}>
                              { data.Technical_skills?.map((T_data, key) => (
                                <Text style={{fontSize: 8, fontFamily: "Poppins", marginRight: 2, marginLeft: 2,}} key={key}>{T_data.value === undefined ? <Text>{T_data}</Text> : <Text>{T_data.value}</Text>}</Text>
                              ))}
                          </View>
                        </View>
                        }
                        {data.Personal_skills?.length === 0 ? null : 
                          <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: 10}}>
                          {
                          data.Lang[0]?.lang === 'English' ? 
                          <Text style={{fontSize: 10, fontFamily: "Poppins-bold", marginBottom: 5, width: 100}}>Personal Skills</Text> : <Text style={{fontSize: 10, fontFamily: "Poppins-bold", marginBottom: 5, width: 100}}>Habilidades personales</Text> 
                          }
                          <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: 245}}>
                            { data.Personal_skills?.map(( P_data, key) => (
                              <Text style={{fontSize: 8, fontFamily: "Poppins", marginRight: 2, marginLeft: 2,}} key={key}>{P_data.value !== undefined ? <Text>{P_data.value}</Text> : <Text>{P_data}</Text>} </Text>
                            ))}
                          </View>
                        </View>
                        }
                    </View>
                  </View>}
                {/* skills end */}

                { data.Reference?.length !== 0 && data.Reference[0]?.Reference_person_name.length !== 0 ? 
                  <View style={{position: "relative", marginTop: 0}}>
                  <Svg  height="2" width="500" style={{left: 180, top: 8}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="300"
                    y2="0"
                    strokeWidth={2}
                    stroke="#C5C5C5"
                  />
                  </Svg>
                  <Svg  height="2" width="500" style={{left: 300, top: 6}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="250"
                    y2="0"
                    strokeWidth={3}
                    stroke="black"
                  />
                  </Svg>
                </View> : null}

                {/* Reference start */}
                {/* { data.Reference?.length !== 0 && data.Reference[0]?.Reference_person_name.length !== 0 ? 
                <View style={{ display: "flex", flexDirection: "row", position: "relative", left: 40, marginTop: 10,}}>
                  <View style={{ width: 45}}>
                    <View style={{width: 100,  height: 33, width: 42}}>
                      {
                        data.Lang[0]?.lang === 'English' ?
                        <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67, height: 23}}>Reference</Text> : <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67, height: 23}}>Referencia</Text>
                      }
                      <Svg  height="2" width="100" style={{left: 5, top: 4}} >
                      <Line
                      x1="0"
                      y1="0"
                      x2="35"
                      y2="0"
                      strokeWidth={2}
                      stroke="#8576CF"
                    />
                    </Svg>
                    </View>
                  </View>

                  <View  style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 380, position: "relative", left: 97, top: 20}}>
                    {data.Reference?.map((R_data, key) => (
                      <View style={{position: "relative", marginRight: 10, width: 130}} key={key}>
                        <Text style={{fontSize: 8, fontFamily: "Poppins-bold", left: 0, marginBottom: 2 }}>{R_data.Reference_person_name}</Text>
                        <Text style={{fontSize: 8, fontFamily: "Poppins", left: 0, marginBottom: 2 }}>{R_data.Referencee_job_title}</Text>
                        <Text style={{fontSize: 8, fontFamily: "Poppins", left: 0, marginBottom: 2 }}>{R_data.Reference_email}</Text>
                      </View>
                    ))}
                  </View>
                </View> : null
                } */}

                { data.Award?.length !== 0 && data.Award[0]?.Award_name.length !== 0 ?     
                <View style={{position: "relative", marginTop: 30}}>
                  <Svg  height="2" width="500" style={{left: 180, top: 8}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="300"
                    y2="0"
                    strokeWidth={2}
                    stroke="#C5C5C5"
                  />
                  </Svg>
                  <Svg  height="2" width="500" style={{left: 300, top: 6}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="250"
                    y2="0"
                    strokeWidth={3}
                    stroke="black"
                  />
                  </Svg>
                </View> : null}

                 {/* Award start */}
                 {data.Award !== undefined ? 
                 <>
                 { data.Award?.length !== 0 && data.Award[0]?.Award_name.length !== 0 ? 
                <View style={{ display: "flex", flexDirection: "row", position: "relative", left: 40, marginTop: 0,}}>
                  <View>
                    <View style={{width: 45,  height: 33, width: 42}}>
                      {
                        data.Lang[0]?.lang === 'English' ?
                        <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67, height: 23}}>Award</Text> : <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67, height: 23}}>Premio</Text>
                      }
                      <Svg  height="2" width="100" style={{left: 5, top: 4}} >
                      <Line
                      x1="0"
                      y1="0"
                      x2="35"
                      y2="0"
                      strokeWidth={2}
                      stroke="#8576CF"
                    />
                    </Svg>
                    </View>
                  </View>

                  <View  style={{ display: "flex", flexDirection: "row", position: "relative", left: 97, top: 20}}>
                    {data.Award?.map((A_data, key) => (
                      <View style={{position: "relative", marginRight: 10, width: 130}} key={key}>
                        <Text style={{fontSize: 8, fontFamily: "Poppins-bold", left: 0, marginBottom: 2 }}>{A_data.Award_name}</Text>
                        <Text style={{fontSize: 8, fontFamily: "Poppins", left: 0, marginBottom: 2 }}>{A_data.Award_giver}</Text>
                        <Text style={{fontSize: 8, fontFamily: "Poppins", left: 0, marginBottom: 2 }}>{A_data.Award_year}</Text>
                      </View>
                    ))}
                  </View>
                </View> : null
                }
                </> : null}

                { data.Language?.length !== 0 ?     
                <View style={{position: "relative", marginTop: 30}}>
                  <Svg  height="2" width="500" style={{left: 180, top: 8}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="300"
                    y2="0"
                    strokeWidth={2}
                    stroke="#C5C5C5"
                  />
                  </Svg>
                  <Svg  height="2" width="500" style={{left: 300, top: 6}} >
                  <Line
                    x1="0"
                    y1="0"
                    x2="250"
                    y2="0"
                    strokeWidth={3}
                    stroke="black"
                  />
                  </Svg>
                </View> : null}

              { data.Language?.length !== 0 ? 
                <View style={{ display: "flex", flexDirection: "row", position: "relative", left: 40, marginTop: 0,}}>
                  <View>
                    <View style={{width: 45,  height: 33, width: 42}}>
                      {
                        data.Lang[0]?.lang === 'English' ?
                        <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67, height: 23}}>Language</Text> : <Text style={{fontSize: 10, fontFamily: "Poppins-bold", left: 0, width: 67, height: 23}}>Idiomas</Text>
                      }
                      <Svg  height="2" width="100" style={{left: 5, top: 4}} >
                      <Line
                      x1="0"
                      y1="0"
                      x2="35"
                      y2="0"
                      strokeWidth={2}
                      stroke="#8576CF"
                    />
                    </Svg>
                    </View>
                  </View>

                  <View  style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", position: "relative", left: 97, top: 20, width: 340 }}>
                    {data.Language?.map((L_data, key) => (
                      <View style={{position: "relative", display:"flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", marginRight: 10, }} key={key}>
                        <Text style={{fontSize: 8, fontFamily: "Poppins-bold", left: 0, marginBottom: 2 }}>{L_data.language} - </Text>
                        <Text style={{fontSize: 8, fontFamily: "Poppins", left: 0, marginBottom: 2 }}>{L_data.rate}</Text>
                      </View>
                    ))}
                  </View>
                </View> : null
                }
                </View>
          )
        })}
        </Page>
          
          </Document>
      /* </div>
    </div> */
  );
}

export default MyDocument;
