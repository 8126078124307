import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, applyMiddleware } from "redux";
import {
  Login,
  Sign_up,
  UserInfo,
  cv_id,
  generate_cv,
  get_me_my_cv,
  get_my_profile,
  verify_mail,
  password_reset,
  jobTitle,
  translate_lang,
  lang,
  spanish,
  get_employer_profile,
  employer_about,
  langType,
  last_resume,
  secret,
  get_public_profile,
  save_link,
  JobDescription,
  JobDetails,
  create_job,
  posting_status,
  job_posting_details,
  jobs_posted,
  job_to_update,
  job_applicants,
  recommended_candid,
  saveJob,
  endToEndChatInformation,
  chatInformation,
  sendMessage,
  decisionCard,
  notification,
  OtpVerification,
  OtpSending,
  total_page,
  msg_notification,
  get_a_job,
  Last_page,
  count,
  errors,
  click,
  getpublicResume,
  set_new_job_state,
  createReferral,
  referral_link,
  employer_employee_info,
  socket,
  candidate_category,
  candidate_skill_match,
  candidate_type,
  online,
  individual_job_cat,
  Sidebar_Open_reducer,
  analytics,
  analytics_weekly,
  jobs_doc
} from "./Reducers";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { logger } from "redux-logger";

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ["saved_cv"]
};

const rootReducer = combineReducers({
  lastPage: Last_page,
  login: Login,
  signup: Sign_up,
  userInfo: UserInfo,
  profile: get_my_profile,
  verify_mail: verify_mail,
  password_reset: password_reset,
  saved_cv: get_me_my_cv,
  generateCV: generate_cv,
  cvId: cv_id,
  jobTitle: jobTitle,
  t_lang: translate_lang,
  lang: lang,
  spanish: spanish,
  employer: get_employer_profile,
  about: employer_about,
  type: langType,
  last_resume: last_resume,
  secret: secret,
  public: get_public_profile,
  userLink: save_link,
  jobDetails: JobDetails,
  JobDescription: JobDescription,
  job: create_job,
  posting_stat: posting_status,
  job_posting_details: job_posting_details,
  jobs_posted: jobs_posted,
  job_to_update: job_to_update,
  applicants: job_applicants,
  recommended_candidate: recommended_candid,
  save_job: saveJob,
  chat_info: chatInformation,
  end_to_end: endToEndChatInformation,
  send_message: sendMessage,
  decisionCard: decisionCard,
  notification: notification,
  msg_notification: msg_notification,
  otp: OtpVerification,
  send_otp: OtpSending,
  total_page: total_page,
  get_a_job: get_a_job,
  count: count,
  errors: errors,
  click: click,
  publicResume: getpublicResume,
  newjobpost: set_new_job_state,
  referralcretion: createReferral,
  referral_link: referral_link,
  employer_employee_info: employer_employee_info,
  socket: socket,
  candidate_category: candidate_category,
  candidate_skill_match: candidate_skill_match,
  candidate_type: candidate_type,
  online: online,
  individual_job_cat: individual_job_cat,
  sidebarOpen: Sidebar_Open_reducer,
  analytics: analytics,
  analytics_weekly: analytics_weekly,
  jobs_doc
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
});

export const persistor = persistStore(store);
