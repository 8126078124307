import React, {useState} from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import MyDocument from './cvTemplate1';
import BlackEagle from './BlackEagle';
import BlueShark from './BlueShark';
import BlueTalent from './BlueTalent';
import SamCv from './SamCv';
import Designer from './Designer';
import BlackShark from './BlackShark';
// import CustomMoil from './customMoil';
import NewCustom from './newCustom';
import { useSelector } from 'react-redux';
import reference from '../../common/reference';

import font from "./font/poppins-v20-latin-600.ttf";
import font1 from "./font/poppins-v20-latin-300.ttf";

Font.register({ family: 'Poppins', src: font1});
Font.register({ family: 'Poppins-bold', src: font});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  download: {
    fontFamily: "poppins",
    fontSize: "1em",
    padding: "6px 12px",
    backgroundColor: "#5843BD",
    color: "white",
    borderRadius: 10,

  }
});

const MyDoc = ({details, id}) => {
  console.log("Mydoc id and details", details)
  console.log("Mydoc id ", id)
  if (details.pdfId == "5" || id == "5") {
    return (
      <MyDocument details={details} />
    )
  } else if (details.pdfId == "6" || id == "6") {
    return (
      <BlackEagle details={details} />
    )
  } else if (details.pdfId == "7" || id == "7") {
    return (
      <BlueShark details={details} />
    )
  } else if (details.pdfId == "8" || id == "8") {
    return (
      <BlackShark details={details} />
    )
  } else if (details.pdfId == "9" || id == "9") {
    return (
      <BlueTalent details={details} />
    )
  } else if (details.pdfId == "10" || id == "10") {
    return (
      <SamCv details={details} />
    )
  } else if (details.pdfId == "11" || id == "11") {
    return (
      <Designer details={details} />
    )
  } 
  // else if (details.pdfId == "8" || id == "8") {
  //   return (
  //     <NewCustom details={details} />
  //   )
  // }
}

function App1(props) {
  const cv_id = useSelector(prev => prev.cvId);
  const urlPath = window.location.pathname;
  const [isOptionsVisible, setOptionsVisible] = useState(true);

  if (urlPath.includes("jobs")) {
    return (
      <div className="px-4 py-2 cursor-pointer" onClick={props.onClick}  >
        <PDFDownloadLink document={<MyDoc details={props.details} id={props.id}/>} fileName={`${props.details.Fullname}.pdf`}>
        {({ blob, url, loading, error }) => (loading ? <Text style={{color: "white"}}>'Loading document...'</Text > : 
        <View className="flex gap-[6px] hover:bg-[#5843BD] hover:bg-opacity-30">
          <img
            src={reference?.previewcvicon}
            alt="preview cv icon"
          />
        <View>
          <h2 className=" font-semibold text-sm text-black">
            {props.details?.CvType}
          </h2>
          <Text className=" text-xs text-[#1C1C1C] text-opacity-60 font-normal">
            {/* {option.descript} */}
            Resume created on Moil.
          </Text>
        </View>
      </View>
      )}
      </PDFDownloadLink>
      </div>
    )
  } else {
    return (
      <div className="px-5 py-3 bg-indigo-700 rounded-lg shadow justify-center items-start gap-2.5 flex lg:flex sm:max-w-[200px] w-full" onClick={props?.onClick}>
        <PDFDownloadLink document={<MyDoc details={props?.details} id={props?.id}/>} fileName={`${props?.details?.Fullname}.pdf`} >
        {({ blob, url, loading, error }) => (loading ? <Text style={{color: "white"}}>'Loading document...'</Text > : 
        <Text style={{color: "white", display:"flex"}}>{props?.text}</Text>)}
      </PDFDownloadLink>
      </div>
    );
  }
}

export default App1;
