import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Skillsbtn from "../../common/Skillsbtn";
import EmployerFullHead from "./employer_profileHEaderfull";
import { useSelector, useDispatch } from "react-redux";
import {
  employeePublicView,
  save_link_loading,
  establishChat,
} from "../../../Redux/ActionCreator";
import { useNavigate } from "react-router-dom";
import TopBar from "../../topbar/Top-Bar";
import SideBar from "../../sidebar/Side-Bar";
import BackButton from "../../common/back_button";
import HireCandidateModal from "../../common/hire_candidate_modal";
import BottonBar from "../../bottom_bar";
// Caution: before you start going through, I used icons as pure SVG format thats why the code is a bit bulky, always close SVG bracket to maintain code readability.

function EmployerProfileView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socketClient = useSelector(e => e.socket?.message);
  const employer = useSelector((e) => e.employer);
  const sidebar = useSelector(e => e.sidebarOpen.message);
  const profile = useSelector((prev) => prev.public);
  const applicants = useSelector(e => e.applicants.message);
  const jobs_posted = useSelector(e => e.jobs_posted.message);

  const [matchModal, setMatchModal] = useState(false);
  const userInfo = useSelector(e => e.profile);
  const login_stat = useSelector(e => e.login);
  // const profile_id = userInfo.user;
  const publicInfo = useSelector(e => e.public);
  useEffect(() => {
    dispatch(save_link_loading(true));
    if (login_stat.isAuthenticated === true) {
      const profile_id = window.location.pathname.split("/")[3];
      console.log("slash", profile_id)
      dispatch(employeePublicView(profile_id));
    }

    let d = new URLSearchParams(window.location.search).get("mode"); // for the modal
    if (d !== null && d == "hire") {
      setMatchModal(true);
    }
  }, []);

  const pub = publicInfo.message?.profile;
  console.log("public", pub);

  console.log("status", login_stat.isAuthenticated);

  let d = new URLSearchParams(window.location.search).get("d");
  let jobName = new URLSearchParams(window.location.search).get("jobName");
  let params = new URLSearchParams(window.location.search).get("pr");
  let dataIndex = new URLSearchParams(window.location.search).get("index");
  const handleClick = () => {
    if (d !== null && d == "dlink") {
      navigate("/employer/dashboard")
    } else if (d !== null && d == "vp" && jobName !== null && params !== null && dataIndex !== null) {
      navigate(`/employer/jobs/${jobName}/candidates/${params}/${dataIndex}`)
    } else {
      navigate("/employer/dashboard")
    }
  }

  const handleEstablishMessage = () => {
    console.log("dataIndex", dataIndex);
    // emit an event here first
    let notification = {
      notificationType: "Interest",
      notificationMessage: "An employer is interested in your application.",
    }
    socketClient.emit("interestNotification", { employerId: employer.message, employeeId: profile.message?.profile, notification: notification });
    dispatch(establishChat(employer.message?._id, profile.message?.profile?._id, applicants?.jobInfo?.jobTitle, applicants?.applications[dataIndex]?.resumeId?._id, applicants?.jobInfo?._id)); // function to establish chat;
    navigate("/employer/inbox")
  }

  const handleClickHire = () => {
    let jobName = new URLSearchParams(window.location.search).get("jobName");
    if (jobName !== null) {
      // esatblish a chat with the user
      // check if the job has been closed or not
      // if close then set MatchModal to true
      // else establish chat
      let job = jobs_posted?.filter((job) => job?.custom_name == jobName);
      console.log("job", job);
      if (job !== undefined && job[0]?.availability == true) {
        handleEstablishMessage();
      } else {
        setMatchModal(true);
      }
    } else {
      setMatchModal(true);
    }
  }

  let matchModalContainer = matchModal ? createPortal(
    <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
      <HireCandidateModal userData={pub} setMatchModal={setMatchModal} />
    </div>,
    document.getElementById("modal")) : null

  return (
    <>{pub !== undefined ?
      <div className={`Dashboard bg-[#FAFAFA] grid grid-cols-auto h-full md:grid-cols-smdash grid-rows-sm_dash ${sidebar ? 'lg:grid-cols-dash' : ''} md:grid-rows-dash bg-tet-912 overflow-x-hidden overflow-y-hidden`}>
        <TopBar topBarShowMenu={true} />
        <SideBar />
        <div className="h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex flex-col justify-center">
          {/* Pre text  */}
          <div className="flex mt-4 flex-col tablet:flex-row gap-4 tablet:gap-0 justify-between pt-4">
            {/* left side  */}
            <div className="gap-x-1">
              <BackButton onClick={handleClick} />
              <p className="text-[24px] leading-normal text-black font-semibold">Candidate profile</p>
            </div>
          </div>

          <div className="my-4 w-full h-full gap-y-8 rounded-2xl flex flex-col items-center">
            <EmployerFullHead handleClickHire={handleClickHire} />

            <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
              <p class="text-base font-medium leading-normal text-[#5843BD]">
                Professional summary
              </p>

              <p className=" text-base leading-normal font-normal text-[#000]">
                {pub?.About}
              </p>
            </div>

            {/* Skills */}
            <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
              <p class="text-base font-medium leading-normal text-[#5843BD]">
                Skills
              </p>

              <div className="flex gap-y-2 gap-x-[6px] w-full flex-wrap">
                <>
                  {pub?.Skills?.map((anything) => (
                    <>
                      {anything?.value !== "" && anything?.value !== undefined ? (
                        <Skillsbtn
                          btntxt={anything?.value}
                          key={pub?.Skills?.indexOf(anything)}
                        />
                      ) : (
                        <>{typeof anything !== "string" || (typeof anything == "string" && anything == "") ? null :
                          (<Skillsbtn
                            btntxt={anything}
                            key={pub?.Skills?.indexOf(anything)}
                          />)
                        }</>
                      )}
                    </>
                  ))}
                </>
              </div>
            </div>

            {/* Expereience */}
            <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
              <p class="text-base font-medium leading-normal text-[#5843BD]">
                Experience
              </p>
              <div className="flex gap-y-2 gap-x-[6px] w-full flex-wrap">
                {pub?.Experience?.map((data, key) => (
                  <div
                    className="w-full flex flex-col gap-6 py-4"
                    key={key}
                  >
                    {/* up content  */}
                    <div className="flex flex-col tablet:flex-row gap-2 md:gap-8 w-full justify-between">
                      {/* year and kini  */}
                      <ul className=" w-full max-w-[200px]  text-sm">
                        <li className="ml-[0px] font-semibold text-base mb-2">
                          {data?.Work_start_date} - {data?.Work_end_date}
                        </li>
                        <li className="ml-[0px] notranslate">{data?.Where_i_worked}</li>
                        {/* <li>{data.Where_i_worked_location}</li> */}
                      </ul>

                      {/* details side  */}
                      <ul className="w-full text-sm">
                        <li className="ml-[0px] md:ml-0 font-semibold mb-2 text-base">
                          {data?.My_job_title}
                        </li>
                        {Array.isArray(data.Day_to_day_work) == true ? (
                          <>
                            {data.Day_to_day_work?.map((exp) => (
                              <>
                                {exp[0] === "-" || exp[0] == "•" || exp[0] == "," ? (
                                  <li className="ml-[12px] md:ml-0 list-disc">{exp.slice(1, exp.length)}</li>
                                ) : (
                                  <>
                                    {/^\d\./.test(exp) == true ? (
                                      <li className="ml-[12px] md:ml-0 list-disc">{exp.slice(2, exp.length)}</li>
                                    ) : (
                                      <li className="ml-[12px] md:ml-0 list-disc">{exp}</li>
                                    )
                                    }
                                  </>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          <>
                            <li className="ml-[12px] md:ml-0 list-disc" key={key}>{data?.Day_to_day_work}</li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Education */}
            <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
              <p class="text-base font-medium leading-normal text-[#5843BD]">
                Education
              </p>
              <div className="flex gap-y-2 gap-x-[6px] w-full flex-wrap">
                {pub?.Education?.map((edu, key) => (
                  <div
                    className="w-full flex flex-col gap-6 py-3"
                    key={key}
                  >
                    {/* up content  */}
                    <div className="flex flex-col tablet:flex-row gap-4 md:gap-8 w-full justify-between">
                      {/* year and kini  */}
                      <ul className="w-full max-w-[200px] text-sm">
                        <li className="ml-[0px] font-semibold text-base">
                          {edu?.End_date}
                        </li>
                      </ul>

                      {/* details side  */}
                      <ul className=" w-full list-disc text-sm">
                        {edu?.Degree ? <li className="ml-[12px] md:ml-0 font-semibold text-base mb-2">
                          {edu?.Degree}
                        </li> : null}
                        {edu?.Course !== "N/A" && edu?.Course !== undefined && edu?.Course !== null ?
                          <li className="ml-[12px] md:ml-0">
                            {edu?.Course}
                          </li> : null
                        }
                        {edu?.School ?
                          <li className="ml-[12px] md:ml-0 notranslate">{edu?.School}</li> : null}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* soft Skills */}
            <div class="w-full  p-6 gap-4 rounded-lg bg-[#FFFFFFCC] flex flex-col gap-x-4 shadow-custom">
              <p class="text-base font-medium leading-normal text-[#5843BD]">
                Soft skills
              </p>

              <div className="flex gap-y-2 gap-x-[6px] w-full flex-wrap">
                <>
                  {pub?.Soft_skills?.map((anything) => (
                    <>
                      {anything?.value !== "" && anything?.value !== undefined ? (
                        <Skillsbtn
                          btntxt={anything?.value}
                          key={pub?.Skills?.indexOf(anything)}
                        />
                      ) : (
                        <>{typeof anything !== "string" || (typeof anything == "string" && anything == "") ? null :
                          (<Skillsbtn
                            btntxt={anything}
                            key={pub?.Skills?.indexOf(anything)}
                          />)
                        }</>
                      )}
                    </>
                  ))}
                </>
              </div>
            </div>
            <div className="flex w-full justify-between gap-y-4 flex-col md:flex-row items-center pb-4">
              <button class="text-base w-full md:w-max font-semibold leading-normal text-center text-white px-5 py-3 gap-3 rounded-lg bg-[#FF6633] shadow-lg" onClick={handleClickHire}>
              Interview Candidate
              </button>

              <button class="text-base w-full md:w-max font-semibold leading-normal text-center bg-[#5843BD1A] text-[#5843BD] px-5 py-3 gap-3 rounded-lg"
                onClick={handleClickHire}>
                Send Message
              </button>
            </div>
          </div>
        </div>
        <BottonBar />
      </div> :
      <div className={`bg-[#FAFAFA] grid grid-cols-auto h-full md:grid-cols-smdash grid-rows-sm_dash ${sidebar ? 'lg:grid-cols-dash' : ''} md:grid-rows-dash bg-tet-912 overflow-x-hidden overflow-y-hidden`}>
        <TopBar topBarShowMenu={true} />
        <SideBar />
        <div>{publicInfo?.errMess}</div>
        <BottonBar />
      </div>}

      {matchModalContainer}
    </>
  );
}

export default EmployerProfileView;