import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { baseURL1 } from '../../Redux/baseURL';
import { useDispatch } from 'react-redux';
import {
	googleSuccess,
	login_success,
} from '../../Redux/ActionCreator';

const ProtectedRoute = (props) => {
  // initialize useSelector hook
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(prev => prev.login);
  const userLink = useSelector(e => e.userLink);
  const link = userLink.message;
  const token = localStorage.getItem('token');
  const login_stat = useSelector(e => e.login);
  const profile_stat = login_stat?.profile_status?.profile_status

	// const profile_stat = new URLSearchParams(window.location.search).get('profile_status');
	// const acctType = new URLSearchParams(window.location.search).get('AcctType');
	const open = new URLSearchParams(window.location.search).get('open');

  useEffect(() => {

		if (!token) {
      if (window.location.pathname !== "/logout") {
        dispatch(googleSuccess());
      } else {
        navigate('/');
      }
      // if (params !== null) {
      //   localStorage.setItem('token', params);
      //   dispatch(googleSuccess());
      //   dispatch(login_success({profile_status: profile_stat, AcctType: acctType}));
      // }
    }
	}, []);

  const handleAuth = () => {
		// const token = localStorage.getItem('token');
			// localStorage.setItem('token', token);
			dispatch(googleSuccess());
			// dispatch(login_success({profile_status: profile_stat, AcctType: acctType}));
  }

  const handleRedirect = () => {
    if (profile_stat == true || profile_stat == "true") {
      window.location.assign(link /* + `?token=${token}&AcctType=${acctType}&profile_status=${profile_stat}` */);
    } else {
      return props.children;
    }
  }

  return (
    <>
    { isAuth.isAuthenticated === true ? 
    <>
    {link !== null ? <>{ open !== null ? props.children : handleRedirect() }</> : props.children}
    </> : <>{handleAuth()}</> }
    </>
  )
}

export default ProtectedRoute;

