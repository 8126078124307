export default function SkillTestPrevItem({ question, options, answer, i }) {

  return (
    <div className="bg-white flex flex-col w-full gap-y-4 px-6 py-4">
      <p className="text-base leading-[1.5] font-semibold text-[#4E5161]">{question}</p>
      <div className="flex flex-col gap-y-4">
        {options.map((option, ind) => {
          return (
            <div className="flex gap-x-2 items-center">
              <input checked={answer === option} type='radio' name={`question_${i}`} id="option_1" className='w-6 h-6 accent-red-500 border-2 border-[#5843BD] focus:ring-transparent accent-purple focus:ring-[#5843BD]' />
              <label className="text-base leading-normal text-[#22263ACC]">{option}</label>
            </div>
          )
        })}
      </div>

    </div>
  )
}