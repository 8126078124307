import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

export default function SkillTestItem({ question, options, answer, i, onChangeHandler, onChangeHandlerForOption, onChangeHandlerForOptionChecked }) {
  return (
    <div>
      <div className="py-2">
        <li className="list-disc ml-2 text-[#5843BD] font-medium text-base"><label for="question_1">Question {i}</label></li>
      </div>

      <EditText className='text-[20px] !whitespace-normal text-[#4E5161] leading-normal font-semibold !p-2 sm:!p-6 bg-[#F8F9FA] skill-input-border' id={`question_${i}`}
        inputClassName='text-[20px] !whitespace-normal text-[#4E5161] leading-normal font-semibold !p-2 !p-6 skill-input-border focus:bg-[#F8F9FA] focus:!ring-transparent focus:outline-none' defaultValue={question} onSave={onChangeHandler} name={i} />

      <div className='px-4 md:px-8 py-4 flex-col gap-y-6'>
        {options?.map((option, j) => {
          return (
            <div key={j} className='flex items-center gap-x-2'>
              <input type='radio' id="option_1" className='w-6 h-6 accent-red-500 border-2 border-[#5843BD] focus:ring-transparent accent-purple focus:ring-[#5843BD]' onChange={onChangeHandlerForOptionChecked} value={`${i}, ${j}`} name={`option_${i}`} checked={answer[i - 1] == option ? true : false} />
              <label for=""><EditText defaultValue={option} className='text-[#22263ACC] !whitespace-normal' inputClassName='w-full min-w-[320px] !whitespace-normal text-[#22263ACC] focus:bg-transparent focus:border-none focus:ring-transparent' name={`${i}, ${j}`} onSave={onChangeHandlerForOption} /></label>
            </div>
          )
        })}
      </div>
    </div>
  )
}
