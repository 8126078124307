import { formatTime } from "../../../utils/formatDate";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../Redux/baseURL";
import { notification_success } from "../../../Redux/ActionCreator";
import { useDispatch } from "react-redux";

export default function DashboardNotificationItem({type, createdAt, jobTitle, custom_name, notificationId, notificationLenght }) {

  const dispatch = useDispatch();

  const updateNotification = async () => {
    try {
      let response = await axios.get(baseURL + "/api/job/update-jobNotification/" + notificationId, {
        headers: {
          "Content-Type": "application/json"
        },
        withCredentials: true
      });
      console.log("Response", response);
      if (response.data.statusCode == 200) {
        dispatch(notification_success(response.data.data));
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  return (
    <div className="px-[10px] py-2 md:px-6 bg-white flex items-center justify-between gap-x-6 md:py-4">
      <div className="px-2 w-full max-w-[80%] flex flex-col gap-y-1">
        <p className="text-xs md:text-base leading-normal font-medium truncate">You received a new application on <span className="text-[#5843BD]">Professional {jobTitle}</span></p>
        <div className="flex items-center gap-x-2">
          <p className="text-[8px] md:text-sm leading-normal uppercase font-medium">{formatTime(createdAt)}</p>
          <p className="text-[6px] md:text-sm bg-[#EBECFC] uppercase text-[#5843BD] p-1">{type}</p>
        </div>
      </div>

      <Link to={`/employer/jobs/${custom_name}`} onClick={() => updateNotification()} >
        <button className="bg-white rounded-[6px] p-[6px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.32031 11.9979H18.6839M18.6839 11.9979L14.8658 15.8161M18.6839 11.9979L14.8658 8.17969" stroke="#5843BD" stroke-width="1.63636" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </Link>
    </div>
  )
}