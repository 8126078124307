import React from "react";
import { SendSvg, AnalyticsOptionsSvg, PostJobOptionsSvg } from "../svgs/svgs";

export default function DashboardOptionLink(props) {
  return (
    <div className="Frame427319602 w-full max-w-max ipad:w-[305px] overflow-hidden relative rounded-2xl shadow border border-white border-opacity-50 cursor-pointer" onClick={props.onClick}>
      <div
        className={`Rectangle6439 w-full h-full flex items-center justify-between ${props.bgBack} bg-opacity-90 rounded-2xl`}
      >
        <div className="Frame13 ml-4 justify-start items-center gap-[11.65px] inline-flex">
          <div className="w-[33px] h-[33px]">
            {props.svgType === 'postJob' ? <PostJobOptionsSvg className="h-full" /> :<AnalyticsOptionsSvg className="h-full" />}
          </div>
          <div className="Frame12 max-w-[250px] flex-col justify-start items-start gap-[3.88px] flex py-4">
            <p className="text-center text-white text-base font-bold">
              {props.header}
            </p>
            <div className="WithAnAiPoweredTechnologyGetTheEdgeOverOthers  text-white text-[10px] font-medium leading-inherit">
              {props.description}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`Rectangle6440 w-1.5 h-[83px] left-0 top-0 absolute ${props.leftBordStyle} bg-opacity-80 rounded-tl-2xl rounded-bl-2xl`}
      />
    </div>
  );
}
