import React from "react"
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-router-dom";

const HomeButton = (props) => {
    return (
        <Link to={props.path}>
            <button className={`flex items-center gap-x-2 ${props.className} justify-center p-5 rounded-lg bg-white cursor-pointer`} >
                <HomeIcon style={{color: "#5843BD"}}/>
                <h3 className="text-base text-[#5843BD] font-semibold">Go back to {props.text}</h3>
            </button>
        </Link>
    )
}

export default HomeButton;