import timeResolver from "../timeResolver"

export default function MatchCandidateComp({ isEmployee, ...props }) {
    return (
        <div className="w-full py-4 px-6 job_shadow rounded-xl bg-white">
            <div className="w-full flex flex-col sm:flex-row justify-between sm:items-center gap-3">
                <div className="w-full flex flex-col">
                    <div className="flex gap-3 items-center">
                        <img src={props.logo} className="w-8 h-8" alt={props.jobPosition} />
                        <div className="flex flex-col">
                            <p className="text-black text-base font-medium whitespace-nowrap">
                                {props.jobPosition}
                            </p>
                            <p className="text-black text-opacity-50 text-xs font-normal">
                                {timeResolver(props.postAge)}
                            </p>
                        </div>
                    </div>
                </div>

                <button
                    className={`whitespace-nowrap py-3 px-5 rounded-lg shadow border bg-indigo-700 text-base border-neutral-50\
                    border-opacity-20 text-white `}
                // idk where im to navigate to yet 
                onClick={props.onClick}
                >
                    Match Candidate
                </button>
            </div>
        </div>
    )
}