function parseISO8601Date(dateStr) {
    return new Date(dateStr);
}

/* description: Function take a date format and subtract in from the current date anf try to resolve it to strings like
today, yesterday, 1 hour ago, 2 days ago.
Argument: pastDateStr
*/
export default function timeResolver(pastDateStr, type="default") {
    const pastDate = parseISO8601Date(pastDateStr);
    const currentDate = new Date();
    const timeDifference = currentDate - pastDate;
    
    if (timeDifference < 60000) {
        return "just now";
    } else if (timeDifference < 3600000) {
        const minutes = Math.floor(timeDifference / 60000);
        if (type === "default") {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${minutes} min${minutes > 1 ? 's' : ''}`;
        }
    } else if (timeDifference < 86400000) {
        return "today";
    } else if (timeDifference < 172800000) {
        return "yesterday";
    } else {
        const days = Math.floor(timeDifference / 86400000);
       if (type === "default") {
        return `${days} day${days > 1 ? 's' : ''} ago`;
       } else {
        return `${days} day${days > 1 ? 's' : ''}`;
       }
    }
}


