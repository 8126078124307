import React, { useEffect, useState, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import countryData from 'country-data';
import CustomSelect from "../common/custom_select";
import { createPortal } from "react-dom";
import { baseURL, baseURL1 } from "../../Redux/baseURL";
import $ from "jquery";
import LoaderModal from "../common/LoaderModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import HelmetProvide from "../common/helmet";

import {
  about_loading,
  generate_employer_about,
  getting_employer_profile_successful,
  send_otp_loading,
  returnHome,
  userName_from_db,
  sendOtp,
  userInfo,
  getting_employer_profile_loading,
  login_success,
} from "../../Redux/ActionCreator";
import { ToastContainer, toast } from "react-toastify";
import PhoneVerificationModal from "../common/phone_verification_modal";
import { getOneFromServerCache, saveIntoServerCache } from "../../utils/saveIntoCache";
import Tab from "../common/tab/tab";
import TextEditor from "../common/textarea/NewTextArea"
import { handleKeyDown } from "../../utils/keydown/keydown";

function EmployerForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const abt_stat = useSelector((e) => e.about);
  const [click, setClick] = useState(false);
  const URL = window.location.pathname;
  const employer = useSelector((e) => e.employer);
  const userName = useSelector((prev) => prev.userInfo);
  const [name, setName] = useState("");
  const my_name = userName.user;
  const [about, setAbout] = useState("");
  const [error, setError] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  let [inputVaue, setInputValue] = useState(countryCode);
  const [countryCodeCountry, setCountryCodeCountry] = useState("United States");
  const profile = useSelector((prev) => prev.profile);

  const [verifyModal, setVerifyModal] = useState(false);
  const [resendTimed, setResendTimed] = useState(false);
  const send_otp = useSelector((state) => state.send_otp);
  const verify_otp = useSelector((state) => state.otp);
  let [ show, setShow ] = useState(false);

  const params = new URLSearchParams(window.location.search).get("token");
  const profile_stat = new URLSearchParams(window.location.search).get(
    "profile_status"
  );
  const acctType = new URLSearchParams(window.location.search).get("AcctType");
  const myCvs = useSelector((prev) => prev.saved_cv);
  // initialize useSelector and useDispatch
  console.log("params", params);
  console.log("profile status", profile_stat);
  console.log("Accct Type", acctType);

  const [hourlycb, setHourlycb] = useState("");
  const [salarycb, setSalarycb] = useState("");
  const [otherscb, setOtherscb] = useState("");
  const [otherspecify, setOtherspecify] = useState("");
  const [autoDisable, setAutoDisable] = useState(false);

  console.log("hourly", hourlycb);

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  // get te country codes data
  const countryCodes = countryData.callingCountries.all.map((country) => ({
    code: `${country.countryCallingCodes[0]}`,
    alpha2: country.alpha2,
    name: country.name
  }));

  useEffect(() => {
    AddCountryCode(countryCode);
  }, [countryCode])

  useEffect(() => {

    if (employer.message !== null) {
      $('[name="name"]').val(employer.message?.name);
      setAutoDisable(false); // true
      $('[name="email"]').val(my_name?.email);
      $('[name="type_of_business"]').val(employer.message?.type_of_business);
      // $('[name="phone_number"]').val(employer.message?.phone_number),
      setInputValue(employer.message?.phone_number);
      setAbout(employer.message?.about);
      $('[name="no_of_employees"]').val(employer.message?.no_of_employees);
      $('[name="opening"]').val(employer.message.hours.opening);
      $('[name="closing"]').val(employer.message.hours.closing);
      $('[name="Employer_Identification_Number"]').val(
        employer.message.Employer_Identification_Number
      );
      $('[name="city"]').val(employer.message.location.city);
      $('[name="state"]').val(employer.message.location.state);
      $('[name="country"]').val(employer.message.location.country);
      $('[name="zipcode"]').val(employer.message.location.zipcode);
      $('[name="website"]').val(employer.message?.website);

      if (employer.message?.open_days.length > 0) {
        let open_days = employer.message?.open_days;
        open_days.map((day) => {
          // $(`#${day}`).prop("checked", true);
          setCheckedItems(prevState => ({
            ...prevState,
            [day]: true,
          }));
        });
      }
    }

    if (my_name !== null) {
      $('[name="email"]').val(my_name?.email);
    }
  }, []);

  // called an authenticate function to verify user that logged in with linkedin and google;
  useEffect(() => {
    // dispatch(clearStoredValue());
    // dispatch(clearPS());

    const token = localStorage.getItem("token");
    if (token === null) {
      localStorage.setItem("token", params);
      dispatch(userName_from_db(params));
    }
  }, [my_name]);

  useEffect(() => {
    if (abt_stat.message !== null) {
      $('[name="about"]').val(abt_stat.message?.CompanyDescription);
      setAbout(abt_stat.message?.CompanyDescription);
      $('[name="name"]').val(abt_stat.message?.CompanyName);
      $('[name="type_of_business"]').val(abt_stat.message?.CompanyType);
    }

    if (send_otp.errMess !== null) {
      setTimeout(() => {
        toast.error(send_otp.errMess, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);

      dispatch(send_otp_loading());
    }

  }, [abt_stat.message, send_otp.errMess]);

  const handleAboutGeneration = () => {
    if (about == "" || about.split(" ").length < 10) {
      setTimeout(() => {
        toast.error("Please introduce your company or business in at least 10 words", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      setClick(true);
      console.log("About", about);
      console.log("Name", $('[name="name"]').val());
      dispatch(
        generate_employer_about({
          about: about,
          city: $('[name="city"]').val(),
          // state: $('[name="state"]').val(),
          name: $('[name="name"]').val(),
          type: $('[name="type_of_business"]').val(),
          Language: "Spanish"
        })
      );
    }
  };

  const [modal, setModal] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const handleDaySelectionChange = (event) => {
      const { name, checked } = event.target;
      setCheckedItems(prevState => ({
          ...prevState,
          [name]: checked,
      }));
  };

  function HandleSubmit(e, values) {
    e.preventDefault();
    console.log("values", values);
    if (abt_stat.message !== null) {
      setAbout(abt_stat.message.CompanyDescription);
    } else {
      setAbout($('[name="about"]').val());
    }
    console.log("About:", about);

    let mypaystructure;

    const selectedItems = Object.keys(checkedItems).filter(item => checkedItems[item]);
    console.log('Selected Items:', selectedItems);

    let body = {
      name: $('[name="name"]').val(),
      email: my_name?.email,
      type_of_business: $('[name="type_of_business"]').val(),
      officialId: my_name?._id,
      phone_number: $('[name="phone_number"]').val(),
      about: about,
      no_of_employees: $('[name="no_of_employees"]').val(),
      // pay_structure: mypaystructure,
      hours: {
        opening: $('[name="opening"]').val(),
        closing: $('[name="closing"]').val(),
      },
      Employer_Identification_Number: $(
        '[name="Employer_Identification_Number"]'
      ).val(),
      location: {
        city: $('[name="city"]').val(),
        state: $('[name="state"]').val(),
        country: $('[name="country"]').val(),
        zipcode: $('[name="zipcode"]').val(),
      },
      open_days: selectedItems,
      website: $('[name="website"]').val() || "",
    };

    if (URL === "/employer/form/update") {  
      const headers = {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      };

      if (countryCode && inputVaue.includes("+")) {
        fetch(baseURL + "/api/employer/update_profile", {
          method: "PATCH",
          body: JSON.stringify(body),
          headers,
          credentials: "include"
        })
          .then((resp) => resp.json())
          .then((resp) => {
            console.log(resp);
            if (resp.success === true && resp.statusCode == 200) {
              dispatch(getting_employer_profile_successful(resp.data));
              console.log("body string", resp.data);
              navigate("/employer/profile");
            } else if (resp.success === false && resp.message == "Unauthorized") {
              dispatch(returnHome(resp.status));
            }
          });
      } else {
        // setError("Please choose country code");
        setTimeout(() => {
          toast.error("Please choose country code", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      }
    } else {
      console.log("body", body);  
      console.log("body", body);

      // const headers = {
      //   "Content-Type": "application/json",
      //   'Authorization': `Bearer ${token}`,
      // };
      if (countryCode && inputVaue.includes("+")) {
        // save into redis cache
        // saveIntoServerCache("employerProfileInfo", body);
        // setTimeout(() => {
        //   navigate("/update/logo");
        // }, 50)

        // call the function that send it to the server
        setShow(true);
        fetch(baseURL + "/api/employer/create_employer_profile", { // this create employer profile if it doesn't exit.
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            // Authorization: "Bearer " + localStorage.getItem("token"),
          },
          credentials: "include"
        })
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp);
          if (resp.success === true && resp.statusCode == 200) {
            setShow(false);
            dispatch(userInfo(resp?.data?.user));
            dispatch(getting_employer_profile_successful(resp.data?.profile))
            dispatch(login_success({profile_status: true, AcctType: "Official"}));
            navigate("/employer/profile");
          } else if (resp.success === false && resp.message == "Unauthorized") {
            dispatch(returnHome(resp.status));
          }
          if (resp.title == "Error") {
            setTimeout(() => {
              toast.error("Please try again", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
            setShow(false);
          }
          if (resp.title == "Operation Failed") {
            setTimeout(() => {
              toast.error(resp.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
            setShow(false);
          }
          if (
            resp.title === "Duplicate Field" ||
            resp.title === "Field Error"
          ) {
            let err;
            if (resp.message.includes("_")) {
              err = resp.message.replace("_", " ");
              setShow(false);
              setTimeout(() => {
                toast.error(err, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
              dispatch(getting_employer_profile_loading())
            } else {
              setShow(false);
              setTimeout(() => {
                toast.error(resp.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
              dispatch(getting_employer_profile_loading());
            }
          }
        });
      } else {
        setModal(false);
        setTimeout(() => {
          toast.error("Please choose your country code", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      }
    }
  }

  console.log("employer", employer);

  const handleChange = (e) => {
    const newValue = e.target.value;

    // Check if the input value contains the default value
    if (newValue.includes(countryCode)) {
      // If it does, set the input value to the default value
      setInputValue(newValue);
    } else {
      // If not, update the input value with the new value
      setInputValue(countryCode);
    }
  }
  //   const [phoneNumber, setPhoneNumber] = useState("");

  //   const handlePhoneNumberChange = (value) => {
  //     setPhoneNumber(value);
  //   };

  //   BigSam
  //   onSubmit: useEffect(() => {

  const handleSendVerification = (e) => {
    e.preventDefault();
    const final_num = inputVaue;
    console.log(final_num);
    if (final_num.includes("+")) {
      resendTimed === false && setResendTimed(true);
      verifyModal === false && setVerifyModal(true);
      dispatch(sendOtp(String(final_num)));
      if (send_otp.errMess !== null) {
        setTimeout(() => {
          toast.error(send_otp.errMess, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);

        dispatch(send_otp_loading());
      }
    } else {
      setTimeout(() => {
        toast.error("Please add your country code to your phone number e.g +1, +215", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    }
  };

  const handleSelectCountryCode = (code) => {
    setCountryCode(code);
    setInputValue(code);
    // inputVaue = countryCode
  }

  const hanldeSetCountryCodeCountry = (country) => {
    setCountryCodeCountry(country);
  }

  const AddCountryCode = (code) => {
    setInputValue(code);
    // inputVaue = code
  }

  const TwentyFourTimeFormat = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      let hour = i.toString().padStart(2, '0');
      times.push(`${hour}:00`);
    };
    return times;
  }

  const verificationModal =
    send_otp.errMess === null && verifyModal && verify_otp.message === null
      ? createPortal(
        <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
          <PhoneVerificationModal
            resendCode={() => resendTimed === false && setResendTimed(true)}
            waitingEnded={() => resendTimed && setResendTimed(false)}
            resendTimed={resendTimed}
            phoneNumber={String(inputVaue)}
            removeModal={() => verifyModal === true && setVerifyModal(false)}
          />
        </div>,
        document.getElementById("modal")
      )
      : null;

  return (
    <>
      <HelmetProvide
        title="Moil | Create Business Profile"
        description="Create your business profile on Moil. Showcase your company, manage job listings, and connect with talented candidates to find the best blue-collar talent for your team."
      />
      <div className="col-start-1 md:col-start-2 col-end-3 row-start-2 row-end-3 flex justify-center lg:flex-row h-calc-150 md:h-calc-70 overflow-x-hidden md:overflow-y-scroll bg-[#FAFAFA] md:pt-12  pt-4">

        <div className="w-full max-w-[900px] xtraxl:max-w-[1200px] gap-y-5 flex flex-col items-center px-6 lg:px-0">
          <div className="w-full">
            <p class=" text-[24px] font-semibold leading-9 text-left text-black text-opacity-87">
              Complete your profile
            </p>

            <p class="text-base font-normal leading-6 text-left text-black text-opacity-87">
              Fill in your basic details in the form below to complete your profile.
            </p>
          </div>

          <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0  overflow-hidden">
            <Tab
              // label="Personal Information"
              label="Business Information"
              current={true}
              completed={false}
              num="1"
            />
          </div>

          <div className="w-full">
            <form
              className="w-full h-full"
              onSubmit={(e, value) => HandleSubmit(e, value)}
            >
              {/* Main content of page  */}
              <div className="bg-white bg-opacity-80 rounded-2xl shadow border border-indigo-400 border-opacity-30 flex flex-col justify-center gap-4 px-4 py-6 w-full">
                <p class="text-xl font-semibold leading-8 text-left text-[#5843BD]">
                  Business Information
                </p>
                <ul className="flex flex-col gap-8">
                  <div className="flex flex-col md:flex-row items-center w-full gap-y-8 gap-x-8">
                    <li>
                      <div className="flex flex-col gap-2  w-full">
                        <li className="list-disc ml-6 font-medium text-base text-black">
                          What's your business name?
                        </li>
                        <p className=" font-medium text-xs text-[#FF6633]">
                          For verification purposes, please use only the name by which your business is registered.
                        </p>
                        <input
                          type="text"
                          name="name"
                          className="w-[100%] px-4 py-2 xl:w-[100%] 2xl:w-[100%] rounded-lg border  border-indigo-400 border-opacity-30 ring-transparent focus:outline-none focus:border-2 focus:border-indigo-400 placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                          disabled={autoDisable}
                          required
                          placeholder="Business name"
                          notranslate
                        // value={formikform.values.name}
                        // onChange={formikform.handleChange}
                        // onBlur={formikform.handleBlur}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="flex flex-col gap-2  w-full">
                        <li className="list-disc ml-6 font-medium text-base text-black">
                          What's your business type?
                        </li>
                        <p className=" font-medium text-xs text-[#FF6633]">
                          Please specify the type of business you run, for example, “Construction Company.”
                        </p>
                        <input
                          type="text"
                          name="type_of_business"
                          className="w-[100%] px-4 py-2 xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                          placeholder="Business type here"
                          required
                        // value={formikform.values.type_of_business}
                        // onChange={formikform.handleChange}
                        // onBlur={formikform.handleBlur}
                        />
                      </div>
                    </li>
                  </div>

                  {/* Second section */}
                  <div className="flex flex-col md:flex-row items-center w-full gap-y-8 gap-x-8">
                    <li>
                      <div className="flex flex-col gap-2  w-full">
                        <li className="list-disc ml-6 font-medium text-base text-black">
                          What's your Employer Identification Number (EIN)?
                        </li>
                        <p className=" font-medium text-xs text-[#FF6633]">
                          {/* {language["veripurpose"]} */}
                          For verification purposes, only you will be able to see
                          this number
                        </p>
                        <input
                          // type="text"
                          type="text"
                          maxLength={9}
                          name="Employer_Identification_Number"
                          className="w-[100%] px-4 py-2 xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                          placeholder="Identification number here"
                          disabled={autoDisable}
                        // value={formikform.values.Employer_Identification_Number}
                        // onChange={formikform.handleChange}
                        // onBlur={formikform.handleBlur}
                        />
                      </div>
                    </li>

                    <li>
                      <div className="flex flex-col gap-2  w-full">
                        <li className="list-disc ml-6 font-medium text-base text-black">
                          Enter your business email address below
                        </li>

                        <p className=" font-medium text-xs text-[#FF6633]">
                          Please use a correct email address
                        </p>

                        <input
                          type="email"
                          className="w-[100%] px-4 py-2 xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                          placeholder="Email address here"
                          name="email"
                          disabled={my_name !== null ? true : false}
                          required
                        // value={formikform.values.email}
                        // onChange={formikform.handleChange}
                        // onBlur={formikform.handleBlur}
                        />
                      </div>
                    </li>
                  </div>

                  {/* Company phone number */}
                  <li>
                    <div className="flex flex-col gap-2  w-full">
                      <li className="list-disc ml-6 font-medium text-base text-black">
                        How can customers reach you?
                      </li>
                      {/*               <p className=" font-medium text-xs text-[#FF6633]">
                Enter your business phone number here and click on “Verify
                Phone”.
              </p> */}
                      <p className=" font-medium text-xs text-[#FF6633]">
                        Enter your business phone number here.
                      </p>
                      <div className="w-full flex-col md:flex-row flex gap-3">
                        <div className="w-full flex gap-3">
                          <CustomSelect countryCodeCountry={countryCodeCountry}
                            hanldeSetCountryCodeCountry={hanldeSetCountryCodeCountry}
                            handleSelectCountryCode={handleSelectCountryCode} countryCodes={countryCodes} countryCode={countryCode} />

                          <input
                            type="text"
                            placeholder="What is your phone number?"
                            // value={phoneNumber}
                            // onChange={handlePhoneNumberChange}
                            defaultCountry="US" // Set the default country code (e.g., US)
                            // Specify the allowed countries
                            name="phone_number"
                            className="px-4 py-2 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30 focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                            value={inputVaue}
                            onChange={handleChange}
                            required
                          // value={formikform.values.phone_number}
                          // onChange={formikform.handleChange}
                          // onBlur={formikform.handleBlur}
                          />
                        </div>

                        {my_name?.phone_number_verified === true || countryCode !== "+1" ? null :
                          <div>
                            <button className="text-base whitespace-nowrap w-full md:w-max rounded-[4px] px-4 py-2 bg-[#FF6633] text-white" onClick={handleSendVerification}>
                              Verify Phone
                            </button>
                          </div>
                        }
                      </div>
                      {
                        employer.message === null && countryCode === "+1" ?
                          <>
                            <div className="flex items-center gap-4 border-l-8 border-[#FF6633] bg-[#FBE5C1] py-2 px-4 text-base font-normal ">
                              {my_name?.phone_number_verified !== true ?
                                <svg
                                  width="22"
                                  height="19"
                                  viewBox="0 0 22 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z"
                                    fill="#FF6633"
                                  />
                                </svg>
                                : null}


                              <span>
                                {my_name?.phone_number_verified !== true ? <>You have to verify your phone number to post jobs on
                                  Moil.</> : <>Phone number verified successfully</>}
                              </span>
                            </div>
                          </> : null
                      }
                    </div>
                  </li>

                  <div className="flex flex-col md:flex-row items-center w-full gap-y-8 gap-x-8">
                    {/* website section */}
                    <li className="w-full">
                      <div className="flex flex-col gap-2  w-full">
                        <li className="list-disc ml-6 font-medium text-base text-black">
                          Company website address
                        </li>

                        <p className=" font-medium text-xs text-[#FF6633]">
                          Enter your website address below
                        </p>

                        <input
                          type="text"
                          className="w-[100%] px-4 py-2 xl:w-[100%] 2xl:w-[100%] rounded-lg border  border-indigo-400 border-opacity-30 ring-transparent focus:outline-none focus:border-2 focus:border-indigo-400 placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                          placeholder="Website address here"
                          name="website"
                        // value={formikform.values.email}
                        // onChange={formikform.handleChange}
                        // onBlur={formikform.handleBlur}
                        />
                      </div>
                    </li>

                    <li className="w-full">
                      <div className="flex flex-col gap-2  w-full">
                        <li className="list-disc ml-6 font-medium text-base text-black">
                          How many people work with you?
                        </li>
                        <p className=" font-medium text-xs text-[#FF6633]">
                          For verification purposes.
                        </p>
                        <input
                          type="number"
                          name="no_of_employees"
                          className="w-[100%] px-4 py-2 xl:w-[100%] 2xl:w-[100%] rounded-lg border  border-indigo-400 border-opacity-30 ring-transparent focus:outline-none focus:border-2 focus:border-indigo-400 placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                          required
                          placeholder="Enter a number here"
                          notranslate
                        />
                      </div>
                    </li>

                  </div>

                  {/* describtion area */}

                  <li>
                    <div className="flex flex-col gap-2  w-full">
                      <p className=" font-medium text-base text-black">
                        What does your business do?
                      </p>
                      <p className="max-w-[650px] font-medium text-xs text-[#FF6633]">
                        In your own words describe what your business is about. For example, how long have you
                        been in business? What type of business are you? Click on ‘Generate’ if you want our technology to rephrase your words better.
                      </p>
                      <p className="max-w-[650px] font-medium text-xs text-[#312816]">
                        Use the rich text editor to cleanly format text in your lecture.
                      </p>
                      <TextEditor
                        className="h-56 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-radius border-indigo-400 border-opacity-30   focus:outline-none focus:border-2 focus:border-indigo-400 placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal placeholder:align-top"
                        style={{ resize: "none" }}
                        placeholder="0/300"
                        type="text"
                        name="about"
                        value={about}
                        onChange={setAbout}
                        onCut={(e) => e.preventDefault()}
                        onCopy={(e) => e.preventDefault()}
                        onKeyDown={handleKeyDown}
                        required
                      // onBlur={formikform.handleBlur}
                      />
                      <div className="mt-4">
                        <a
                          type="button"
                          onClick={handleAboutGeneration}
                          className="text-base font-medium bg-[#FF6633] rounded-[8px] px-5 py-3 text-white cursor-pointer no-underline"
                        >
                          Generate
                        </a>
                      </div>
                    </div>
                  </li>


                  <li>
                    <div className="flex flex-col gap-2  w-full">
                      <p className=" font-medium text-base text-black">
                        Where is your business located?
                      </p>
                      <p className=" font-medium text-xs text-[#B1B0B0]">
                        Enter your street address city and state below
                      </p>


                      <div className="flex items-center gap-x-4">
                        <input
                          type="text"
                          className="px-4 py-2 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                          placeholder="City"
                          name="city"
                          required
                        // value={formikform.values.city}
                        // onChange={formikform.handleChange}
                        // onBlur={formikform.handleBlur}
                        />
                        <input
                          type="text"
                          className="px-4 py-2 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                          placeholder="State"
                          name="state"
                          required
                        // value={formikform.values.state}
                        // onChange={formikform.handleChange}
                        // onBlur={formikform.handleBlur}
                        />
                        <input
                          type="text"
                          className="w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                          placeholder="Zipcode"
                          name="zipcode"
                          required
                        // value={formikform.values.zipcode}
                        // onChange={formikform.handleChange}
                        // onBlur={formikform.handleBlur}
                        /></div>

                      {/* <input
                      type="text"
                      className=" h-14 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                      placeholder={`${language["countryhere"]}`}
                      name="country"
                      required
                    // value={formikform.values.country}
                    // onChange={formikform.handleChange}
                    // onBlur={formikform.handleBlur}
                    /> */}
                    </div>
                  </li>
                  {/* <li> */}
                  {/* <div className="flex flex-col gap-2  w-full">
                    <p className=" font-medium text-base text-black">
                      {language["buslocal"]}
                    </p>
                    <p className=" font-medium text-base text-[#B1B0B0]">
                      {language["streetadsbm"]}
                    </p>
                    <input
                      type="text"
                      className=" h-14 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                      placeholder={`${language["cityhere"]}`}
                      name="city"
                      required
                    // value={formikform.values.city}
                    // onChange={formikform.handleChange}
                    // onBlur={formikform.handleBlur}
                    />
                    <input
                      type="text"
                      className=" h-14 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                      placeholder={`${language["statehere"]}`}
                      name="state"
                      required
                    // value={formikform.values.state}
                    // onChange={formikform.handleChange}
                    // onBlur={formikform.handleBlur}
                    />
                    <input
                      type="text"
                      className=" h-14 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                      placeholder={`${language["countryhere"]}`}
                      name="country"
                      required
                    // value={formikform.values.country}
                    // onChange={formikform.handleChange}
                    // onBlur={formikform.handleBlur}
                    />
                    <input
                      type="text"
                      className=" h-14 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                      placeholder={`${language["zipcodehere"]}`}
                      name="zipcode"
                      required
                    // value={formikform.values.zipcode}
                    // onChange={formikform.handleChange}
                    // onBlur={formikform.handleBlur}
                    />
                  </div> */}
                  {/* </li> */}
                  {/* <li>
                    <div className="flex flex-col gap-2  w-full">
                      <p className=" font-medium text-base text-black">
                        How many people work with you?
                      </p>
                      <input
                        type="text"
                        className=" h-14 w-[100%] xl:w-[100%] 2xl:w-[100%] rounded-lg border border-indigo-400 border-opacity-30  focus:outline-none focus:border-2 focus:border-indigo-400  placeholder:text-[#B3B3B3] text-base font-normal placeholder:text-base placeholder:font-normal"
                        placeholder="Enter a number"
                        name="no_of_employees"
                        required
                      // value={formikform.values.no_of_employees}
                      // onChange={formikform.handleChange}
                      // onBlur={formikform.handleBlur}
                      />
                    </div>
                  </li> */}

                  <li>
                    <div className="flex flex-col gap-2  w-full">
                      <li className="list-disc ml-6 font-medium text-base text-black">
                        What days are you open?
                      </li>
                      <p className=" font-medium text-xs text-[#FF6633]">
                        What days of the week does your company work?
                      </p>

                      <div className="flex gap-4 flex-wrap">
                        {days.map(day => {
                          return (
                            <div className=" flex items-center gap-2">
                              <input
                                type="checkbox"
                                name={day}
                                id={day}
                                className="sun border-[2px] w-6 h-6 border-[#0000004D] rounded-[4px] border-indigo-400 border-opacity-30 focus:outline-none accent-purple"
                                value={day}
                                checked={checkedItems[day] || false}
                                onChange={handleDaySelectionChange}
                              />
                              <label
                                htmlFor="checkbox"
                                className=" font-medium text-base text-black"
                              >
                                {day}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </li>


                  {/* Available time */}
                  <li>
                    <div className="flex flex-col gap-2  w-full">
                      <li className="list-disc ml-6 font-medium text-base text-black">
                        What are your business hours?
                      </li>
                      <p className=" font-medium text-xs text-[#FF6633]">
                        Select opening and closing time.
                      </p>

                      <div className="flex gap-4 flex-wrap">
                        <select name="opening" id="openingtime" className="w-full md:max-w-[150px] border-indigo-400 border-opacity-30 focus:outline-none rounded-[4px]">
                          {TwentyFourTimeFormat().map(time => {
                            return (
                              <option className="" key={time} value={time}>{time}</option>
                            )
                          })}
                        </select>
                        <select name="closing" id="closingtime" className="w-full md:max-w-[150px] border-indigo-400 border-opacity-30 focus:outline-none rounded-[4px] ">
                          {TwentyFourTimeFormat().map(time => {
                            return (
                              <option className="" key={time} value={time}>{time}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </li>

                </ul>
                <div className="flex items-center justify-center sm:flex xsm:block">
                  {/* <div> */}
                  {/* </div> */}
                  <div className="flex justify-center tablet:justify-end w-full">
                    <button
                      className="text-white  w-full md:w-max flex items-center gap-x-3 text-base font-medium rounded-lg bg-[#5843BD] py-3 px-5 whitespace-nowrap text-center w-full md:w-max justify-center"
                      type="submit"
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 8L14.57 9.393L20.15 15H8V17H20.15L14.57 22.573L16 24L24 16L16 8Z" fill="white" />
                        <path d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05845 6.10051 6.10051C8.05845 4.14258 10.553 2.80921 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.06569C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.9958 19.7117 28.5194 23.2702 25.8948 25.8948C23.2702 28.5194 19.7117 29.9958 16 30ZM16 4.00001C13.6266 4.00001 11.3066 4.70379 9.33316 6.02237C7.35977 7.34095 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.69361 20.6689 5.83649 22.8071 7.51472 24.4853C9.19296 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2325 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9963 12.8185 26.7308 9.76845 24.4812 7.51882C22.2316 5.26918 19.1815 4.00371 16 4.00001Z" fill="white" />
                      </svg>
                      Save and Continue
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {click == true ? (
              <>
                {abt_stat.message !== null ? (
                  setClick(false)
                ) : (
                  <LoaderModal text="Generating standard introduction for your company" />
                )}
              </>
            ) : null}
            <ToastContainer className="mt-[20px]" />
            {show ? <LoaderModal text={employer.message == null ? "Processing your information" : "Uploading logo..."} /> : null}
          </div>
        </div>
      </div>
      {verificationModal}
    </>
  );
}

export default EmployerForm;
