import React from "react";

export default function JobPostSuccessModal(props) {
  return (
    <div className="w-full max-w-[360px] sm:max-w-[500px] md:max-w-[600px] flex  items-center justify-center px-2 ">
      <div className="flex w-full flex-col">
        <div className="bg-[#5843BD]  py-4 header-border rounded-tl-[24px] rounded-tr-[24px]">
          <p className="text-white text-[20px] leading-normal font-semibold text-center">Job posted!</p>
        </div>
        <div className="w-full flex flex-col rounded-bl-[24px] rounded-br-[24px] items-center gap-3 justify-between bg-white pt-6 pb-8 px-6">
          <div className="py-6 flex justify-center flex-col items-center">
          <svg
            width="43"
            height="43"
            viewBox="0 0 43 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="21.5" cy="21.5" r="21" fill="#5843BD" />
            <path
              d="M11.125 21.3372L18.2239 28.0493L32.421 14.625"
              stroke="white"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p className="text-center text-[#0D0D0D] text-[24px]  font-normal font-['Poppins']">
          Well done! Your job has been<br className="hidden md:block"/> suceessfully published.
          </p>
          </div>
          <div className="flex py-4 justify-center">
            <button
              className="px-6 py-3 bg-[#5843BD] rounded-3xl shadow justify-center items-center gap-2.5 inline-flex"
              onClick={props.closeModal}
            >
              <span className="text-center text-white  text-[12px] sm:text-base font-semibold font-['Poppins']">
                Preview Job Posting
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
