import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DecisionCard from "../decison_card_message_component";
import { useParams } from "react-router-dom";

export default function NewMessageItem(props) {
  const params = useParams().receiverId;
  const employer = useSelector((e) => e.employer);
  const employee = useSelector((e) => e.profile);
  const login_stat = useSelector((e) => e.login);
  const userInfo = useSelector((state) => state.userInfo);
  let AcctType = login_stat.profile_status?.AcctType;
  const index = new URLSearchParams(window.location.search).get("i");
  let cardText = "";
  let offerText = "";

  if (AcctType === "Official" && employer.message !== null) {
    if (props.isUser === employer.message?._id) {
          cardText = "You just sent an offer card to this candidate.";
          offerText = "Offer Card."
    } else {
      cardText = `${props.neededName} just accepted your offer`;
      offerText = "Offer Accepted."
    }
  }

  const chatImage =
    employee.message !== null
      ? employee.message?.photo
      : employer.message !== null
      ? employer.message?.photo
      : userInfo.user?.profilr_pics;
  console.log("new message compnent", props);
  console.log("new msg CardId", props.message?.split(/[:&]/)?.[1])
  
  return (
    <div
      className={`px-4 sm:px-6 flex ${offerText === "Offer Accepted." && props.isDecisionCard && "justify-center"} ${
        props.isDecisionCard & (AcctType === "Official") && "justify-end"
      } justify-end`}
      ref={props.lastMessageRef}
    >
      <div className="flex items-end gap-x-4 ">
        <div className="">
          {props.isDecisionCard ? (
            <DecisionCard
              acctType={AcctType}
              params={params}
              index={index}
              cardId={props.message?.split(/[:&]/)?.[1]}
              cardText={
                cardText
              }
              offerText={offerText}
            />
          ) : (
            <div
              className={`xsm:w-max w-full xsm:max-w-[300px] sm:max-w-[350px] px-4 py-3 ${
                props.isUser === employee.message?._id ||
                props.isUser === employer.message?._id
                  ? "bg-indigo-700 rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl"
                  : "bg-pry-980 rounded-tr-3xl rounded-tl-3xl rounded-br-3xl"
              }  justify-start items-start gap-2.5 inline-flex`}
            >
              <p
                className={`${
                  props.isUser === employee.message?._id ||
                  props.isUser === employer.message?._id
                    ? "text-white"
                    : "text-black"
                } text-xs font-normal font-['Poppins'] whitespace-pre-wrap`}
              >
                {props.message}
              </p>
            </div>
          )}
        </div>
        {/* <img
          src={chatImage}
          className={`h-5 w-5 rounded-full  ${
            props.isUser === employee.message?._id ||
            props.isUser === employer.message?._id
              ? "self-end"
              : "self-start"
          }`}
        /> */}
      </div>
    </div>
  );
}
