import { Link, useNavigate } from "react-router-dom";
import timeResolver from "../timeResolver";


export default function JobDetailItemMobile(props) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col rounded-[8px] gap-y-4 border border-[#D9D9D9] gap-y-2 bg-white px-2 py-2">
      <div className="flex gap-x-2 h-full items-center">
        <div>
          <img className="w-[45px] h-[45px] rounded-[4px]" src={props.logo} />
        </div>
        <div>
          <p class="text-base font-medium truncate leading-normal text-left text-[#5843BD]">
            {props.jobPosition}
          </p>
          <p class="text-[10px] text-[#4E5161] uppercase font-normal leading-[15px] text-left">
            Posted {`${timeResolver(props.createdAt)}`}
          </p>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex items-center gap-x-2 justify-between">
          {
            props.status === "Active" ? <div className="bg-[#5843BD26] w-max rounded-[4px] py-2 px-3 text-base font-medium leading-normal text-left text-[#111111]">
              Active
            </div> : props.status === "Pending" ? <div className="bg-[#FF663326] w-max rounded-[4px] py-2 px-3 text-base font-medium leading-normal text-left text-[#111111]">
              Pending
            </div> 
            /* : props.status === "Hired" ? <div className="bg-[#5843BD80] w-max rounded-[4px] text-white py-2 px-3 text-base font-medium leading-normal text-left">
              Hired
            </div> */ 
            : <div className="bg-[#FF6633] py-2 px-3 rounded-[4px] w-max text-base font-medium leading-normal text-left text-white">
              Closed
            </div>
          }

          <p className="text-base p-2 bg-[#FF663308] flex items-center gap-x-1 rounded-[4px] leading-normal font-normal text-[#000000CC]">
            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.544 6.99031C20.848 7.41631 21 7.63031 21 7.94531C21 8.26131 20.848 8.47431 20.544 8.90031C19.178 10.8163 15.689 14.9453 11 14.9453C6.31 14.9453 2.822 10.8153 1.456 8.90031C1.152 8.47431 1 8.26031 1 7.94531C1 7.62931 1.152 7.41631 1.456 6.99031C2.822 5.07431 6.311 0.945312 11 0.945312C15.69 0.945312 19.178 5.07531 20.544 6.99031Z" stroke="#F24E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14 7.94531C14 7.14966 13.6839 6.3866 13.1213 5.82399C12.5587 5.26138 11.7956 4.94531 11 4.94531C10.2044 4.94531 9.44129 5.26138 8.87868 5.82399C8.31607 6.3866 8 7.14966 8 7.94531C8 8.74096 8.31607 9.50402 8.87868 10.0666C9.44129 10.6292 10.2044 10.9453 11 10.9453C11.7956 10.9453 12.5587 10.6292 13.1213 10.0666C13.6839 9.50402 14 8.74096 14 7.94531Z" stroke="#F24E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            {props.views ? props.views : 'Nil'}</p>
        </div>

        <Link to={props.urlToDetails} className="py-2 whitespace-nowrap text-sm lg:text-base font-medium leading-normal text-left text-[#5843BD]">
          View Details
        </Link>
      </div>
    </div>
  )
}