import React, { createContext, useContext } from "react";

const AnalyticsProvider = createContext();
export const AnalyticsProviderContext = ({children, analytics }) => {
  return (
    <AnalyticsProvider.Provider value={analytics}>
      {children}
    </AnalyticsProvider.Provider>
  )
}

export const useAnalytics = () => useContext(AnalyticsProvider);