import React from "react";

export default function EducationSection(props) {
  return (
    <div className="flex-col justify-start items-start gap-2 inline-flex flex-none">
      <p className="text-indigo-700 text-sm font-medium">
        {props.course}
      </p>
      <p className="ObafemiAwolowoUniversity text-black text-opacity-90 text-xs font-normal">
        {props.institution}
      </p>
      <p className="2017 text-zinc-400 text-xs font-medium">{`${props.endYear}`}</p>
    </div>
  );
}