import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearLogin, login_loading, logOut } from "../../Redux/ActionCreator";
import { baseURL1, baseURL } from "../../Redux/baseURL";
import { LogoutModal } from "./logout_modal";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);

  if (show === true) {
    const handleLogout = () => {
      // localStorage.clear();
      localStorage.removeItem("token");
      dispatch(login_loading(true));
      dispatch(clearLogin());
      dispatch(logOut());
      document.cookie = "session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
      window.location.assign(baseURL1);
    };
    return createPortal(
      <div className="h-screen flex justify-center backdrop-blur-[2px] items-center">
        <LogoutModal
          // setNo={() => setShow(false)}
          setConfirm={handleLogout}
        />
      </div>,
      document.getElementById("logout")
    );
  } else {
    return <></>;
  }
};

export default Logout;

