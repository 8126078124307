import React from "react";
import { ForwardSvg, IndigoTick } from "../svgs/svg";

export default function Tab(props) {
  return (
    <>
      {props.current === false && props.completed === false ? (
        <div className="flex items-center gap-[10px]">
          <div className="flex items-center gap-1">
            {/* Number circle */}
            <div className="flex-none w-[30px] h-[30px] bg-[#00000040] border border-solid border-[#BFBFBF] text-[#BFBFBF] flex justify-center items-center rounded-full">
              <span className="text-white">{props.num}</span>
            </div>

            {/* Text */}
            <p class="font-sans text-xs font-normal whitespace-nowrap leading-4.5 text-left text-[#BFBFBF]">
              {props.label}
            </p>
          </div>

          {/* Forward svg */}
          <ForwardSvg />
        </div>
      ) : props.current === false && props.completed === true ? (
        <div className="">
          <div className="flex items-center gap-1">
            {/* Number circle */}
            <IndigoTick />

            <div className="flex gap-[10px] bg-[#5843BD1A] items-center bg-opacity-10 px-4 py-1 rounded-xl">
              {/* Text */}
              <p class="font-sans text-xs font-normal whitespace-nowrap leading-4.5 text-left text-[#5843BD]">
                {props.label}
              </p>
              {/* Forward svg */}
              <ForwardSvg />
            </div>

          </div>
        </div>


      ) : (
        <div className="">
          <div className="flex items-center gap-1">
            {/* Number circle */}
            <div className="flex-none w-[30px] h-[30px] bg-[#5843BD] text-white flex justify-center items-center rounded-full">
              <span>{props.num}</span>
            </div>

            <div className="flex gap-[10px]  bg-[#5843BD1A] items-center bg-opacity-10 px-4 py-1 rounded-xl">
              {/* Text */}
              <p class="font-sans text-xs whitespace-nowrap font-normal leading-4.5 text-left text-[#5843BD]">
                {props.label}
              </p>
              {/* Forward svg */}
              <ForwardSvg />
            </div>

          </div>
        </div>
      )}
    </>
  );
}
