export const formatTime = (createdAt) => {
    if (!createdAt) return null;
    let day = ['Mon', 'Tue', 'Wed', 'Thrs', 'Fri', 'Sat', 'Sun'][new Date(createdAt).getDay() - 1];
    const date = new Date(createdAt);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
  
    const formattedTime = `${day} ${hours}:${minutes}${ampm}`;
    return formattedTime;
}