import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function LogoutModal(props) {
  const navigate = useNavigate();
  const stat = useSelector(e => e.login);
  const handleClick = () => {
    navigate("/employer/dashboard");
  }
  return (
    <div className="w-screen aspect-[1/1] sm:aspect-[3/2] lg:aspect-[7/5] xl:aspect-[3/2] flex items-center justify-center">
      <div className="flex flex-col items-center gap-y-6 justify-between rounded-3xl shadow border border-indigo-400 border-opacity-30 bg-white py-10 px-4 sm:px-8">
        <div className="flex items-center flex-col gap-y-4">
          <p className="text-center text-stone-950 text-[18px] sm:text-xl lg:text-3xl font-semibold font-['Poppins']">
            You are about to log out
            <br />
            of Moil.
          </p>
        </div>
        <p className="AreYouSureAboutThis text-center text-stone-950 text-opacity-80 text-[14px] lg:text-[20px] font-medium font-['Poppins']">
            Are you sure about this?
        </p>
        <div className="flex gap-3">
          <button className="px-3 sm:px-6 py-3 rounded-3xl shadow border-2 border-indigo-700 justify-center items-center gap-2.5 inline-flex" onClick={handleClick}>
            <span className="text-center text-indigo-700 text-[12px] sm:text-base font-semibold font-['Poppins']">
              No, keep me logged in
            </span>
          </button>
          <button className="px-3 sm:px-6 py-3 bg-indigo-700 rounded-3xl shadow justify-center items-center gap-2.5 inline-flex" onClick={props.setConfirm}>
            <span className="text-center text-white  text-[12px] sm:text-base font-semibold font-['Poppins']">
              Yes, log me out
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
