import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
    UploadJobDocument,
    job_doc_loading
} from "../../Redux/ActionCreator";
import { createPortal } from 'react-dom';
import LoaderModal from '../common/LoaderModal';
import BackButton from '../common/back_button';

const UploadJobDoc = ({ status }) => {
  const [stat, setStat] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(true);
  const dispatch = useDispatch();
  const [userFile, setUserFile] = useState('');
  const upload_status = useSelector((state) => state.jobs_doc);
  const navigate = useNavigate();
  const uploadInputRef = useRef();
  let labelClasses =
    'flex items-center text-base font-semibold text-slate-700 ml-[24px]';

  const user = useSelector(e => e.userInfo);

  let [select, setSelect] = useState(false);
  let [uploadPc, setUploadPc] = useState(false);

  const handleUploadPc = () => {
    if (select == true) {
      setSelect(false);
    }
    setUploadPc(true);
  }

  const handleSelect = () => {
    if (uploadPc == true) {
      setUploadPc(false);
    }
    setSelect(true);
  }

  // improvise job
  let job = {
    indeed: false
  };

  useEffect(() => {
    if (upload_status.message !== null) {
      status(false);
    }

    if (upload_status.errMess !== null) {
      setTimeout(() => {
        toast.error(upload_status.errMess, {
          position: toast.POSITION.TOP_CENTER,
        });
      }, 50);
      dispatch(job_doc_loading(true));
      setStat(false);
    }
  }, [upload_status]);


  const formSubmissionHandle = (e) => {
    e.preventDefault();
    //Sending the file to the backend here
    const file = uploadInputRef.current?.files[0];
    console.log("file", file);
    if (file === undefined) {
      console.log("file", file)
      setTimeout(() => {
        toast.error("Select a file to upload", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
    } else {
      const formData = new FormData();
      formData.append('uploadDoc', file);
      setStat(true);
      dispatch(UploadJobDocument(formData));
    }
  };

  const triggerUploadFile = (e) => {
    e.preventDefault();
    uploadInputRef.current.click();
  };
  const fileChangeHandle = (e) => {
    e.preventDefault();
    console.log(1);
		console.log("target", e.target.value)
		console.log("uploadInputRef.current?.files[0]", uploadInputRef.current?.files[0])
    let enteredFileName = uploadInputRef.current?.files[0]?.name;
    if (enteredFileName) {
      setUserFile(enteredFileName);
    }
  };

  let text = "Extracting Job document text, this can take a couple of minutes..."

  if (upload_status.message == null) {
    return (
      <div>
        {showWarningModal &&
          createPortal(
            <div className="fixed top-0 left-0 bottom-0 right-0 z-10 bg-tet-600 overflow-y-scroll min-h-screen py-7 backdrop-blur-[2px] flex justify-center items-center">
              <section onClick={(e) => e.stopPropagation()} className="bg-white sm:max-w-[500px] min-w-[350px] w-[80%] m-auto rounded-lg p-10">
                <BackButton onClick={() => status(false)} />
                <form
                  onSubmit={formSubmissionHandle}
                  className="bg-white rounded-xl">
                  <div className="flex flex-col gap-y-5 justify-between mb-4">
                    <div>
                      <p className={labelClasses}>Upload Job Document</p>
                      <span className="hr-span"></span>
                    </div>

                    <p className="font-sans text-xl font-semibold leading-6 text-left text-black">
                      Upload your existing job Document?
                    </p>

                    <p className="font-sans text-base font-normal leading-6 text-left text-[#898989]">
                      In order for us to create a job for you, please upload an existing job document.
                    </p>

                    <div>
                      {userFile && (
                        <p className="p-2 rounded-[4px] w-max min-w-[200px] max-w-full text-sm font-medium italic leading-5 text-left text-[#393939] bg-[#DCDCDC80]">
                          {userFile}
                        </p>
                      )}
                    </div>

                  </div>
                  {upload_status.errMess !== null ? (
                    <h6
                      style={{
                        color: 'red',
                        marginTop: '5px',
                        textAlign: 'center',
                        fontSize: "10px"
                      }}>
                      {upload_status.errMess}
                    </h6>
                  ) : null}
                  <div className="flex flex-col sm:flex-row gap-y-4 items-center justify-between">
                    <label className="w-full sm:w-max block flex-2 relative">
                      <div className="">
                        <input
                          ref={uploadInputRef}
                          onInput={fileChangeHandle}
                          type="file"
                          accept=".doc, .docx, .pdf"
                          className="opacity-0 absolute -z-[1] w-full h-full"
                          name="uploadPDF"
                        />
                        {(
                          <button
                            type="button"
                            onClick={triggerUploadFile}
                            className="px-5 w-full sm:w-max py-3 gap-2.5 rounded-lg justify-center border border-[#5843BD] bg-[#5843BD1A] flex items-center">
                            <span className='rounded-[2px] bg-[#5843BD]'>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 19V5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19 12L12 5L5 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </span>
                            <span className="text-base font-medium leading-6 text-center text-[#5843BD]">
                              Upload Document
                            </span>
                          </button>
                        )}

                      </div>
                    </label>
                    <button
                      type="submit"
                      // onClick={() => setStat(true)}
                      className="w-full sm:w-max px-5 py-2.5 gap-2.5 rounded-lg bg-[#5843BD] animate-none transition-all duration-300 text-white ease-out"
                    >
                      Continue
                    </button>
                  </div>


                  {/* Error component */}

                  <div>
                    <ToastContainer />
                    {stat === true ? (
                      upload_status.errMess === null ? <LoaderModal text={text} /> : null
                    ) : null}
                  </div>
                </form>
              </section>
            </div>,
            document.getElementById('modal')
          )}
      </div>
    );
  }
};

export default UploadJobDoc;
