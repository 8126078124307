export default function PhoneNumberCopyModal({calledNumber, phoneNumber, employeeName}) {
  return (
    <div className="w-[90%] lg:w-full py-6 sm:py-10 bg-white rounded-3xl shadow border border-indigo-400 border-opacity-30 max-w-[500px] flex justify-center items-center">
      <div className="w-[80%] flex flex-col justify-center gap-y-4 sm:gap-y-6">
        <div className="flex justify-center">
          <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16.5" cy="16.5" r="16" fill="#DEDEDE" />
            <path
              d="M21.8 22.5C20.4111 22.5 19.0389 22.1971 17.6833 21.5913C16.3278 20.9856 15.0944 20.1273 13.9833 19.0167C12.8722 17.9056 12.014 16.6722 11.4087 15.3167C10.8033 13.9611 10.5004 12.5889 10.5 11.2C10.5 11 10.5667 10.8333 10.7 10.7C10.8333 10.5667 11 10.5 11.2 10.5H13.9C14.0556 10.5 14.1944 10.5529 14.3167 10.6587C14.4389 10.7644 14.5111 10.8893 14.5333 11.0333L14.9667 13.3667C14.9889 13.5444 14.9833 13.6944 14.95 13.8167C14.9167 13.9389 14.8556 14.0444 14.7667 14.1333L13.15 15.7667C13.3722 16.1778 13.636 16.5749 13.9413 16.958C14.2467 17.3411 14.5829 17.7107 14.95 18.0667C15.2944 18.4111 15.6556 18.7307 16.0333 19.0253C16.4111 19.32 16.8111 19.5893 17.2333 19.8333L18.8 18.2667C18.9 18.1667 19.0307 18.0916 19.192 18.0413C19.3533 17.9911 19.5116 17.9773 19.6667 18L21.9667 18.4667C22.1222 18.5111 22.25 18.5918 22.35 18.7087C22.45 18.8256 22.5 18.956 22.5 19.1V21.8C22.5 22 22.4333 22.1667 22.3 22.3C22.1667 22.4333 22 22.5 21.8 22.5Z"
              fill="white"
            />
          </svg>
        </div>
        <p className="text-indigo-700 text-xl font-semibold font-['Poppins'] text-center">
          Contact Candidate
        </p>
        <hr />
        <p className="text-center">
          <span className="text-stone-950 text-base font-normal font-['Poppins']">
            You can contact{" "}
          </span>
          <span className="text-indigo-700 capitalize text-base font-semibold font-['Poppins']">
            {employeeName}
            <br />
          </span>
          <span className="text-stone-950 text-base font-normal font-['Poppins']">
            using their phone number:
          </span>
        </p>
        <p className="text-indigo-700 text-xl font-semibold font-['Poppins'] text-center ">
          +{phoneNumber}
        </p>
        <button className="px-6 py-2 bg-rose-100 rounded-[23px] justify-center items-center gap-2.5 inline-flex focus:bg-rose-100" onClick={calledNumber}>
          <p className="text-center">
            <span className="text-indigo-700 text-xs font-semibold font-['Poppins']">
              Click here
            </span>
            <span className="text-stone-950 text-xs font-normal font-['Poppins']">
              {" "}
              once you have called this candidate.
            </span>
          </p>
        </button>
      </div>
    </div>
  );
}
