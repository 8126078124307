import React from "react";
import { Avatar } from "@mui/material";

export default function UserAvatar(props) {
  return (
    <Avatar
      alt={props.user}
      src={props.image}
      sx={
        props.size === "sm"
          ? { width: 24, height: 24, bgcolor: props.bgColor }
          : props.size === "lg"
          ? { width: 36, height: 36, bgcolor: props.bgColor }
          : props.size === "xl"
          ? { width: 56, height: 56, bgcolor: props.bgColor }
          : { width: 72, height: 72, bgcolor: props.bgColor }
      }
    />
  );
}
