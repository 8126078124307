export default function JobType(props) {
  return (
    <div className="flex-col justify-start items-start gap-2 inline-flex">
      <p className="text-indigo-700 text-sm font-medium">
        {props.jobType}
      </p>
      <p className="text-indigo-700 text-sm font-medium">
        {props.experience} years of experience
      </p>
    </div>
  );
}