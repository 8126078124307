import BackButton from "../back_button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { new_job_match_successful } from "../../../Redux/ActionCreator";

export default function HireCandidateModal({userData, setMatchModal}) {
    const dispatch = useDispatch();
    function goBack() {
        // window.history.back();
        setMatchModal(false);
    }

    let params = new URLSearchParams(window.location.search);
    let d = params.get('d');
    let jobName = params.get('jobName');
    let pr = params.get('pr');
    let index = params.get('index');

    const navigate = useNavigate();

    const createJobForUser = () => {
        navigate('/employer/create-job');
        dispatch(new_job_match_successful(true));
    }
    

    return (
        <div class="max-w-[320px] sm:max-w-[550px] p-8 border-2 border-indigo-400 border-opacity-20 rounded-lg gap-6 bg-white">

            <div className="flex justify-start mb-3">
                <BackButton onClick={goBack}/>
            </div>

            <div className="flex flex-col gap-y-6">
                <div>
                    <div className="mac-w-[270px]">
                        <p class="font-poppins text-[24px] font-medium leading-29 tracking-normal text-center">
                            Hire this candidate
                        </p>
                        <p class="font-poppins text-base font-medium leading-19 tracking-normal text-center">
                            Hire <span className="text-[#5843BD]">{userData?.Fullname}</span>
                        </p>

                        <p class="font-poppins text-base font-medium tracking-normal text-center">
                            {userData?.Job_type} in <span className="text-[#5843BD]">{userData?.Address}</span>
                        </p>
                    </div>

                    <div className="flex justify-center mt-4">
                        <p class="max-w-[320px] font-poppins text-xs sm:text-sm font-normal leading-17 tracking-normal text-center">
                            To hire this candidate, send them one of your
                            existing jobs or create a new job for them.
                        </p>
                    </div>
                </div>

                <hr className="bg-indigo-400 bg-opacity-20 h-[1px] " />

                <div className="flex flex-col md:flex-row justify-between gap-x-3 px-2 gap-y-4">
                    <button class="px-4 py-3 border border-gray-300 rounded gap-2 hire-cand-purple flex justify-center items-center" onClick={() => navigate(`/employer/jobs/match?d=${d}&jobName=${jobName}&pr=${pr}&index=${index}&mode=hire`)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.75 12.75V21H2.25V12.75M12 16.5V13.5M15 6C15 6 15 3 12 3C9 3 9 6 9 6M1.5 6H22.5V12C22.5 12 18 15 12 15C6 15 1.5 12 1.5 12V6Z" stroke="#5843BD" stroke-width="1.31193" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        <span class="text-base font-normal leading-6 tracking-normal text-center">Select an existing job</span>
                    </button>

                    <button class="px-4 py-3 border border-gray-300 rounded gap-2 hire-cand-orange flex justify-center items-center" onClick={createJobForUser}>

                        {/* Svg icon for the create job button */}

                        <svg className="bg-[#ff6633]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.926 2.0501L12.0741 21.9496" stroke="white" stroke-width="2.12637" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.05029 11.9258L21.9498 12.0739" stroke="white" stroke-width="2.12637" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span class="text-base font-normal leading-6 tracking-normal text-center">Create a new job</span>
                    </button>
                </div>
            </div>
        </div>
    );
}