import React, { useEffect, useState } from "react";
import EmployerProfileHeaderOfficial from "./employer_profile_header";
import { useSelector, useDispatch } from "react-redux";
// Caution: before you start going through, I used icons as pure SVG format thats why the code is a bit bulky, always close SVG bracket to maintain code readability.
import LoaderModal from "../common/LoaderModal";
import { useNavigate } from "react-router-dom";
import {
  getEmployerProfile,
  posting_loading,
  job_loading,
} from "../../Redux/ActionCreator";
import HelmetProvide from "../common/helmet";
import { baseURL } from "../../Redux/baseURL";

// import analytics from "analytics"
import { useAnalytics } from "../../context/AnalyticsContext";
import EditButton from "../common/edit_btn";
import ReactHtmlParser from 'react-html-parser';

function EmployerProfile() {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const userPics = useSelector((prev) => prev.userInfo);
  const employer = useSelector((e) => e.employer);
  const [picLink, setPicsLink] = useState("");
  const profile = useSelector((prev) => prev.profile);
  const params = new URLSearchParams(window.location.search).get("token");
  const profile_stat = new URLSearchParams(window.location.search).get(
    "profile_status"
  );
  const acctType = new URLSearchParams(window.location.search).get("AcctType");
  const myCvs = useSelector((prev) => prev.saved_cv);
  // initialize useSelector and useDispatch
  console.log("params", params);
  console.log("profile status", profile_stat);
  console.log("Accct Type", acctType);

  // called an authenticate function to verify user that logged in with linkedin and google;
  useEffect(() => {
    dispatch(job_loading(true));
    dispatch(posting_loading(true));
  }, []);

  const EmployerProfile = useSelector((prev) => prev.employer);
  const body = EmployerProfile.message;
  console.log("Employer body", body);
  const dispatch = useDispatch();

  // analytics useEffect
  useEffect(() => {
    if (body !== null) {
      analytics.identify("userId", {
        "city": body?.location?.city,
        "country": body?.location?.country,
        "postal_code": body?.location?.zipcode,
        "state": body?.location?.state,
        "avatar": body?.photo,
        "company_name": body?.name,
        "company_id": body?._id, // profile schema ID
        "created_at": body?.createdAt,
        "description": body?.about,
        "email": body?.email,
        "id": userPics.user?._id, // user schema Id
        "name": body?.name,
        "phone": body?.phone_number,
        "username": body?.name,
        "website": baseURL
      });
    }
  }, [])


  //   BigSam

  useEffect(() => {
    console.log("Called!!");
    if (body === null) {
      dispatch(getEmployerProfile());
    }
  }, []);

  function HandleUpdate() {
    navigate("/employer/form/update");
  }

  return (
    <>
      <HelmetProvide
        title="Moil | Profile"
        description="Explore your employer Profile on Moil. Create job listings, connect with talented candidates, and find the best blue-collar talent for your team."
      />
      {EmployerProfile.isloading === true ? (
        <LoaderModal />
      ) : (
        <>
          {EmployerProfile.message !== null ? (
            <div className="col-start-1 md:col-start-2 col-end-3 row-start-2 row-end-3 flex justify-center lg:flex-row h-calc-150 md:h-calc-70 overflow-x-hidden md:overflow-y-scroll bg-[#FAFAFA] pt-8 px-6 xl:px-0">
              <div className="h-max w-full max-w-[900px] xtraxl:max-w-calculate md:px-0">

                {/* Header Section */}
                <div className="flex flex-col gap-y-2 p-4 pr-8 bg-white rounded-lg">
                  <p className="text-[#000000DE] text-[24px] leading-normal font-medium">Business Profile</p>
                  <div className="flex gap-6 flex-col md:flex-row md:items-center">
                    <p className="text-[#000000DE]">This is your business profile, you can edit and make changes to some of the fields.
                      To see your profile as your intending employee would, click the <span className="font-semibold">‘Public View’</span> button.</p>
                    <button className="flex items-center gap-x-3 px-5 py-3 bg-[#FFE8E0] rounded-[8px] w-max">
                      <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.544 7.045C20.848 7.471 21 7.685 21 8C21 8.316 20.848 8.529 20.544 8.955C19.178 10.871 15.689 15 11 15C6.31 15 2.822 10.87 1.456 8.955C1.152 8.529 1 8.315 1 8C1 7.684 1.152 7.471 1.456 7.045C2.822 5.129 6.311 1 11 1C15.69 1 19.178 5.13 20.544 7.045Z" stroke="#F24E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7956 5 11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11C11.7956 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8Z" stroke="#F24E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <span className="text-base text-[#212121] text-center font-medium whitespace-nowrap">Public View</span>
                    </button>
                  </div>
                </div>

                <div className="my-8 w-full h-full bg-[#FDFCFD]  rounded-2xl flex flex-col items-center gap-y-4">
                  <EmployerProfileHeaderOfficial HandleUpdate={HandleUpdate} />

                  {/* bottom white content of page  */}

                  {/* Edit modal  */}
                  {/* <Edit_modal
                    // open={openModal}
                    // onClose={() => setOpenModal(false)}
                    // aboutData={aboutData}
                    // onSave={saveAboutData}
                    /> */}

                  <div className="flex flex-col gap-y-4 w-full">
                    {/* About page  */}
                    <div className="bg-white shadow rounded-lg p-6 w-full">
                      {/* content  */}
                      <div className="flex flex-col gap-y-2">
                        <div className="flex items-center py-[5px] justify-between">
                          <h1 className="text-[#5843BD] font-semibold text-base">
                            About
                          </h1>

                          <EditButton handleClick={HandleUpdate} />
                        </div>
                        <p className=" text-base leading-normal font-normal text[#22263A]">
                        {ReactHtmlParser(body?.about)}
                        </p>
                      </div>
                    </div>

                    <p className="text-[20px] leading-normal text-[#22263A]">Contact Information</p>

                    {/* location  */}
                    <div className="bg-white rounded-lg shadow p-6 flex w-full">
                      <div className="w-full">
                        <div className="flex justify-between py-[5px] items-center">
                          <h1 className="text-[#5843BD] font-semibold text-base">
                            Location
                          </h1>
                          <EditButton handleClick={HandleUpdate} />
                        </div>
                        <p className=" text-base leading-normal font-semibold text-[#22263A]">
                          {/* {`${body.location?.city}, ${body.location?.state}, ${body.location?.country}.`} */}
                          {`${body?.location?.city}, ${body?.location?.state}, ${body?.location?.zipcode}.`}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center w-full flex-col md:flex-row gap-y-4 gap-x-6 justify-between">
                      {/* EIN */}
                      <div className="bg-white shadow rounded-lg p-6 flex w-full md:max-w-[500px] ">
                        {/* content 1 */}
                        <div className="w-full flex flex-col gap-y-2">
                          <div className="flex justify-between items-center">
                            <h1 className="text-[#5843BD] font-semibold text-base">
                              Employer’s identification number (EIN)
                            </h1>
                            <EditButton handleClick={HandleUpdate} />
                          </div>
                          <p className="text-base leading-normal font-semibold text-[#22263A]">
                            {body?.Employer_Identification_Number}
                          </p>
                        </div>
                      </div>

                      {/* PHONE NUMBER */}
                      <div className="bg-white shadow rounded-lg w-full md:max-w-[350px] p-6 flex ">
                        {/* content 1 */}
                        <div className="w-full flex flex-col gap-y-2">
                          <div className="flex justify-between items-center">
                            <h1 className="text-[#5843BD] font-semibold text-base">
                              Phone number
                            </h1>
                            <EditButton handleClick={HandleUpdate} />
                          </div>
                          <p className="text-base leading-normal font-semibold text-[#22263A]">
                            {body?.phone_number}
                          </p>
                        </div>
                      </div>
                    </div>


                    {/* New section of profile */}
                    <div className="flex items-center w-full flex-col md:flex-row gap-y-4 gap-x-6 justify-between">
                      {/* EIN */}
                      <div className="bg-white shadow rounded-lg p-6 flex w-full md:max-w-[500px] ">
                        {/* content 1 */}
                        <div className="w-full flex flex-col gap-y-2">
                          <div className="flex justify-between py-[5px] items-center">
                            <h1 className="text-[#5843BD] font-semibold text-base">
                              Email address
                            </h1>
                            <EditButton handleClick={HandleUpdate} />
                          </div>
                          <p className="text-base leading-normal font-semibold text-[#22263A]">
                            {body?.email}
                          </p>
                        </div>
                      </div>

                      {/* Website */}
                      <div className="bg-white shadow rounded-lg w-full md:max-w-[350px] p-6 flex ">
                        {/* content 1 */}
                        <div className="w-full flex flex-col gap-y-2">
                          <div className="flex justify-between items-center">
                            <h1 className="text-[#5843BD] font-semibold text-base">
                              Website Address
                            </h1>
                            <EditButton handleClick={HandleUpdate} />
                          </div>
                          <p className="text-base leading-normal font-semibold text-[#22263A]">
                            {!body?.website ? null : body?.website}
                          </p>
                        </div>
                      </div>
                    </div>

                    <p className="text-[20px] leading-normal text-[#22263A]">Employment Information</p>

                    {/* EMployees  */}
                    <div className="bg-white shadow rounded-lg p-6 flex w-full">
                      <div className="w-full flex flex-col gap-y-2">
                        <div className="flex justify-between items-center">
                          <h1 className="text-[#5843BD] font-semibold text-base">
                            Employees
                          </h1>
                          <EditButton handleClick={HandleUpdate} />
                        </div>
                        <p className=" text-base leading-normal font-semibold text-[#22263A]">
                          {body?.no_of_employees}
                        </p>
                      </div>
                    </div>


                    {/* EMployees  */}
                    <div className="bg-white shadow rounded-lg p-6 flex w-full">
                      <div className="w-full">
                        <div className="flex justify-between py-[5px] items-center">
                          <h1 className="text-[#5843BD] font-semibold text-base">
                            Business days and hours
                          </h1>
                          <EditButton handleClick={HandleUpdate} />
                        </div>
                        <div className="flex items-center gap-6 pt-4">
                          <p className=" font-normal text-base text-black">
                            Opens:{" "}
                            <span className="font-semibold text-base text-[#FF6633]">
                              {body?.hours.opening}
                            </span>
                          </p>
                          <p className=" font-normal text-base text-black">
                            Closes:{" "}
                            <span className="font-semibold text-base text-[#FF6633]">
                              {body?.hours.closing}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/* Pay Structure  */}
                  {/* <div className=" w-full flex justify-between ">
                      <div className="w-[80%]">
                        <h1 className="text-[#5843BD] font-semibold text-base">
                          {language["paystructm"]}
                        </h1>
                        <div className="flex items-center gap-6 pt-4">
                          <div className=" flex gap-3 items-center">
                            <img src={reference.checkedbox} alt="" />
                            <label
                              htmlFor="checkbox"
                              className=" font-medium text-base"
                            >
                              {body.pay_structure}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div onClick={HandleUpdate} >
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.4971 10.0058C19.2319 10.0058 18.9775 10.1112 18.79 10.2987C18.6024 10.4862 18.4971 10.7406 18.4971 11.0058V17.0058C18.4971 17.271 18.3917 17.5254 18.2042 17.7129C18.0166 17.9004 17.7623 18.0058 17.4971 18.0058H3.49707C3.23185 18.0058 2.9775 17.9004 2.78996 17.7129C2.60243 17.5254 2.49707 17.271 2.49707 17.0058V3.0058C2.49707 2.74058 2.60243 2.48623 2.78996 2.29869C2.9775 2.11116 3.23185 2.0058 3.49707 2.0058H9.49707C9.76229 2.0058 10.0166 1.90044 10.2042 1.71291C10.3917 1.52537 10.4971 1.27101 10.4971 1.0058C10.4971 0.740582 10.3917 0.486228 10.2042 0.298692C10.0166 0.111155 9.76229 0.00579834 9.49707 0.00579834H3.49707C2.70142 0.00579834 1.93836 0.321869 1.37575 0.884478C0.813141 1.44709 0.49707 2.21015 0.49707 3.0058V17.0058C0.49707 17.8014 0.813141 18.5645 1.37575 19.1271C1.93836 19.6897 2.70142 20.0058 3.49707 20.0058H17.4971C18.2927 20.0058 19.0558 19.6897 19.6184 19.1271C20.181 18.5645 20.4971 17.8014 20.4971 17.0058V11.0058C20.4971 10.7406 20.3917 10.4862 20.2042 10.2987C20.0166 10.1112 19.7623 10.0058 19.4971 10.0058ZM4.49707 10.7658V15.0058C4.49707 15.271 4.60243 15.5254 4.78996 15.7129C4.9775 15.9004 5.23185 16.0058 5.49707 16.0058H9.73707C9.86868 16.0066 9.99914 15.9813 10.121 15.9316C10.2428 15.8818 10.3536 15.8085 10.4471 15.7158L17.3671 8.7858L20.2071 6.0058C20.3008 5.91284 20.3752 5.80223 20.426 5.68038C20.4767 5.55852 20.5029 5.42781 20.5029 5.2958C20.5029 5.16379 20.4767 5.03308 20.426 4.91122C20.3752 4.78936 20.3008 4.67876 20.2071 4.5858L15.9671 0.295798C15.8741 0.20207 15.7635 0.127676 15.6416 0.0769069C15.5198 0.0261382 15.3891 0 15.2571 0C15.1251 0 14.9944 0.0261382 14.8725 0.0769069C14.7506 0.127676 14.64 0.20207 14.5471 0.295798L11.7271 3.1258L4.78707 10.0558C4.69439 10.1492 4.62106 10.2601 4.5713 10.3819C4.52153 10.5037 4.49631 10.6342 4.49707 10.7658ZM15.2571 2.4158L18.0871 5.2458L16.6671 6.6658L13.8371 3.8358L15.2571 2.4158ZM6.49707 11.1758L12.4271 5.2458L15.2571 8.0758L9.32707 14.0058H6.49707V11.1758Z"
                            fill="#FF6633"
                          />
                        </svg>
                      </div>
                    </div> */}

                </div>
              </div>
            </div>
          ) : (
            <div>
              <LoaderModal text="Fetching profile" />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default EmployerProfile;
