import React, { useEffect } from "react";
import { formatTime } from "../../utils/formatDate";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../Redux/baseURL";
import { 
  notification_success,
  getNotification
} from "../../Redux/ActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

const NotificationPage = ({type = "Application", createdAt, jobTitle, custom_name, notificationId}) => {

  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification.message);

  useEffect(() => {
    if (notification == null) {
      dispatch(getNotification());
    }
  }, []);

  const updateNotification = async () => {
    try {
      let response = await axios.get(baseURL + "/api/job/update-jobNotification/" + notificationId, {
        headers: {
          "Content-Type": "application/json"
        },
        withCredentials: true
      });
      console.log("Response", response);
      if (response.data.statusCode == 200) {
        dispatch(notification_success(response.data.data));
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  return (
    <>
    <div className=" h-calc-150 md:h-calc-70 px-4 py-8 overflow-y-scroll flex justify-center">
      <div className="px-6 lg:px-0 relative w-full lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]">
        <div className="bg-[#FF6633] px-6 flex items-center gap-x-[10px] py-[10px] rounded-tl-[16px] rounded-tr-[16px]">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11 19C11 19.2652 10.8946 19.5196 10.7071 19.7071C10.5196 19.8946 10.2652 20 10 20C9.73478 20 9.48043 19.8946 9.29289 19.7071C9.10536 19.5196 9 19.2652 9 19V8C9 7.73478 9.10536 7.48043 9.29289 7.29289C9.48043 7.10536 9.73478 7 10 7C10.2652 7 10.5196 7.10536 10.7071 7.29289C10.8946 7.48043 11 7.73478 11 8V19ZM7 19C7 19.2652 6.89464 19.5196 6.70711 19.7071C6.51957 19.8946 6.26522 20 6 20C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11C6.26522 11 6.51957 11.1054 6.70711 11.2929C6.89464 11.4804 7 11.7348 7 12L7 19ZM13 19V10C13 9.73478 13.1054 9.48043 13.2929 9.29289C13.4804 9.10536 13.7348 9 14 9C14.2652 9 14.5196 9.10536 14.7071 9.29289C14.8946 9.48043 15 9.73478 15 10L15 19C15 19.2652 14.8946 19.5196 14.7071 19.7071C14.5196 19.8946 14.2652 20 14 20C13.7348 20 13.4804 19.8946 13.2929 19.7071C13.1054 19.5196 13 19.2652 13 19ZM17 5C17 4.73478 17.1054 4.48043 17.2929 4.29289C17.4804 4.10536 17.7348 4 18 4C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20C17.7348 20 17.4804 19.8946 17.2929 19.7071C17.1054 19.5196 17 19.2652 17 19V5Z" fill="#F24E1E" />
          </svg>
          <span className="text-xs md:text-base text-white leading-normal font-medium">Notifications and activities</span>
        </div>

        {notification && notification.length > 0 && notification?.map((item, index) => (
          <div className={`px-[10px] py-2 mt-2 md:px-6  bg-white flex items-center justify-between gap-x-6 md:py-4 rounded-lg ${item?.type == "Application" ? "bg-white shadow-md" : item?.type == "New job post" || item?.type == "Job Closed" ? "bg-[#FDFAFB]" : "bg-white shadow-sm"}` }
          key={index}
          >
          <div className="px-2 w-full max-w-[80%] flex flex-col gap-y-1">
            <p className="text-xs md:text-base leading-normal font-medium truncate">You received a new application on <span className="text-[#5843BD]">Professional {item?.JobId.jobTitle}</span></p>
            <div className="flex items-center gap-x-2">
              <p className="text-[8px] md:text-sm leading-normal uppercase font-medium">{formatTime(item?.createdAt)}</p>
              <p className="text-[6px] md:text-sm bg-[#EBECFC] uppercase text-[#5843BD] p-1">{item?.type}</p>
            </div>
          </div>

          <Link to={`/employer/jobs/${item?.JobId.custom_name}`} onClick={() => updateNotification()} >
            <button className="bg-white rounded-[6px] p-[6px]">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.32031 11.9979H18.6839M18.6839 11.9979L14.8658 15.8161M18.6839 11.9979L14.8658 8.17969" stroke="#5843BD" stroke-width="1.63636" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </Link>
        </div>
        ))}
      </div>
    </div>
    <ToastContainer />
    </>
    
  )
}

export default NotificationPage;
