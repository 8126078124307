export default function WorkExperience(props) {
  return (
    <div className="flex-col justify-start items-start gap-2 inline-flex">
      <div className="text-black text-opacity-60 text-xs font-normal">
        {`${props.startDate} - ${props.endDate}`}
      </div>
      <div className="text-indigo-700 text-sm font-medium">
        {props.jobTitle}
      </div>
      <div className="text-black text-opacity-90 text-xs font-medium">
        {props.institution}
      </div>
      <ul className="flex flex-col gap-3 max-w-[280px]">
        {props.roles.map((role) => {
          return (
            <li className="text-black text-opacity-60 text-xs font-normal">
              {role}
            </li>
          );
        })}
      </ul>
    </div>
  );
}