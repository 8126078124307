import React, { useEffect, useState } from "react";
import SearchBarsOnTop from "./SearchBarsOnTop";
import HorizontalNFilterIcon from "../HorizontalNFilterIcon/HorizontalNFilterIcon";
import DashboardQueryItem from "../dashboard_query_item";
import { baseURL } from "../../../Redux/baseURL";
import PaginationBtn from "./paginationBtn";
import BackButton from "../back_button";
import { useNavigate } from "react-router-dom";

function SearchJobField() {
  const navigate = useNavigate();
  const title = new URLSearchParams(window.location.search).get("title");
  const location = new URLSearchParams(window.location.search).get("location");

  const [OurSearchLoading, setOurSearchLoading] = useState(false);

  const [JobListData, setJobListData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialSearchDone, setInitialSearchDone] = useState(false);
  const [ourError, setOurError] = useState("");
  const [ totalJobs, setTotalJobs ] = useState(0);

  // Job Search
  const [searchJob, setSearchJob] = useState("");


  function HandleCancelJobSearch() {
    setSearchJob("");
    // HandleOverAllSearch();
  }

  //    Location Search
  const [searchLocation, setSearchLocation] = useState("");

  useEffect(() => {
    if (title !== null || location !== null) {
      setSearchJob(title);
      setSearchLocation(location);
    }
    HandleOverAllSearch();
  }, [title, location])

  // useEffect(() => {
  //     HandleOverAllSearch();
  // }, [searchJob, searchLocation]);

  function HandleCancelLocationSearch() {
    setSearchLocation("");
    // HandleOverAllSearch();
  }

  const HandleOverAllSearch = () => {
    setOurSearchLoading(true);
    setInitialSearchDone(true);
    setOurError("");

    const queryParams = [];
    if (searchJob) {
      queryParams.push(`title=${encodeURIComponent(searchJob.toLowerCase().trim())}`);
    }
    if (searchLocation) {
      queryParams.push(
        `location=${encodeURIComponent(searchLocation.toLowerCase().trim())}`
      );
    }

    if (currentPage) {
      queryParams.push(`limit=15&page=${currentPage}`);
    }

    const queryString = queryParams.join("&");

    fetch(baseURL + `/api/job/search_job_posts?${queryString}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setOurSearchLoading(false);
        if (data.statusCode == 200) {
          if (data.success) {
            const respJson = data;
            console.log("respJson?.data['Job posts']", respJson?.data["Job posts"])
            setOurSearchLoading(false);
            setJobListData(respJson?.data["Job posts"]);
            setTotalPages(respJson?.data?.number_of_pages);
            setTotalJobs(respJson?.data?.number_of_matches)
          } else {
            setTotalJobs(0)
            setOurError("No records match this search criteria");
            setOurSearchLoading(false);
          }
        } else if (data.title == "Not found") {
          console.log("No result found", data);
          setTotalJobs(0);
          setOurSearchLoading(false);
          setJobListData([]);
          setTotalPages(1)
        }
      }).catch(err => {
        setTotalJobs(0);
        setOurSearchLoading(false);
        setJobListData([]);
        setTotalPages(1);
      });
  };

  useEffect(() => {
    if (initialSearchDone) {
      HandleOverAllSearch();
        // debouncedSearch();
    }
  }, [currentPage, initialSearchDone]);

  return (
    <div className=" px-6 py-9 flex flex-col gap-6 max-w-[993px] w-full mx-auto">
      <div className="flex items-center gap-4">
        <BackButton className="" onClick={() => navigate("/")} />
        <h1 className="tablet:font-medium text-xl font-normal tablet:text-[28px] text-black text-opacity-60">
          Filter your job preferences
        </h1>
      </div>

      <SearchBarsOnTop
        JobListData={JobListData}
        searchJob={searchJob}
        setSearchJob={setSearchJob}
        searchLocation={searchLocation}
        setSearchLocation={setSearchLocation}
        HandleCancelJobSearch={HandleCancelJobSearch}
        HandleCancelLocationSearch={HandleCancelLocationSearch}
        HandleOverAllSearch={HandleOverAllSearch}
        OurSearchLoading={OurSearchLoading}
      />

      <div>
        <HorizontalNFilterIcon />
        <p className=" mt-[8px] pl-[26px] text-base font-normal text-black text-opacity-60">
          Filter result
        </p>
      </div>

      {OurSearchLoading ? 
        // <p className=" text-xl font-normal tablet:text-[28px] tablet:font-medium text-black text-opacity-60">
        //   <span>{JobListData?.length} {totalJobs !== 0 ? `out of ${totalJobs}`: null}</span> results found
        // </p> : null
        null : (
        <p className=" mt-4 text-xl font-normal tablet:text-[28px] tablet:font-medium text-black text-opacity-60">
          <span>{JobListData?.length} {totalJobs !== 0 ? `out of ${totalJobs}`: null}</span> results found
        </p>)
      }
      <p className=" flex items-center text-xs font-small tablet:text-[18px] tablet:font-normal text-black text-opacity-60">
        <img src='https://res.cloudinary.com/drlcisipo/image/upload/v1705704152/Website%20images/small_logo_mqnwoo.png' alt="moil logo" className="w-[30px] h-[20px] mr-2" />
        <span> One click apply job</span>
      </p>

      <div className=" grid grid-cols-1 tablet:grid-cols-2 tablet:gap-x-4 ipad:grid-cols-3 gap-y-6">
        {JobListData?.map((items, index) => (
          <DashboardQueryItem
            experienceLevel=""
            jobPosition={items?.jobTitle}
            profilePics={items?.logo}
            employer={items.companyName}
            jobType={items.jobType}
            recruitmentDuration={items.applicationDeadline}
            JobLocation={`${items.location.city}, ${items.location.state}`}
            key={index}
            indeed={items?.indeed}
            jobUrl={`/jobs/${items._id}`}
          />
        ))}
        
      </div>
      <PaginationBtn
        totalPages={totalPages}
        currentPage={currentPage}
        onNextPage={() => {
          if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
          }
        }}
        onPrevPage={() => {
          if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
          }
        }}
        onSelectPage={(selectedPage) => {
          setCurrentPage(selectedPage);
        }}
      />
    </div>
  );
}

export default SearchJobField;

// jobUrl={`/searchjobfielddetails/${items._id}?title=${title}&location=${location}`}
