import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { baseURL1, baseURL } from '../../Redux/baseURL';
import {
	googleSuccess,
  referralLink,
  login_success
} from '../../Redux/ActionCreator';
import axios from "axios";

const ProfileProtected = (props) => {
  // initialize useSelector hook
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(prev => prev.login);
  const token = localStorage.getItem("token");
  console.log("Parameter", token);

  const memoization = useMemo(() => {
    let fetchToken = async () => {
      if (token === null) {
        let url = baseURL + `/api/auth/success`;
        await axios(url, { withCredentials: true })
        .then(resp => {
          console.log("data data", resp);
          if (resp.status == 200 && resp.data.success == true) {
            localStorage.removeItem("ref");
            dispatch(login_success({ profile_status: resp.data.profile_status, AcctType: resp.data.AcctType }));
            localStorage.setItem("token", resp.data.token);
          } else if (resp.status === 400) {
            handleRedirect();
          }
        }).catch(err => {
          console.log("err", err);
          if (err.response?.data.success == false) {
            handleRedirect();
          }
        });
      } else {
        // dispatch(googleSuccess());
      }
    }
    return fetchToken;
  }, []);

  useEffect(() => {
		// const token = localStorage.getItem('token');
		memoization()
	}, []);

  const handleRedirect = () => {
    if (token !== null) {
      return props.children;
		} else {
      console.log("I called this component")
      if (window.location.pathname.includes("jobs")) {
        window.open(baseURL1 + `/login?link=${window.location.pathname}&open=true`, "_self");
      } else if (window.location.pathname.includes("profile")) {
        window.open(baseURL1 + `/login?link=${window.location.pathname}&open=true`, "_self");
      } else {
        window.open(baseURL1 + `/Home?link=${window.location.pathname}&open=true`, "_self");
      }
    }
  }

  return (
    <>
      {token !== null ? <>
      { isAuth.isAuthenticated === true ? props.children : handleRedirect() }
      </> : null} 
    </>
  )
}

export default ProfileProtected;
