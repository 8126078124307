import React, { useState, useEffect, useContext, useCallback } from "react";
import reference from "../common/reference";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditButton from "../common/edit_btn";
import { useDropzone } from "react-dropzone";
import { baseURL, baseURL1 } from "../../Redux/baseURL";
import LoaderModal from "../common/LoaderModal/index"
import { getting_employer_profile_successful } from "../../Redux/ActionCreator";
import { useDispatch } from "react-redux";
import { LinearProgress } from "@mui/material";

function EmployerProfileHeaderOfficial({ HandleUpdate }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Tolu
  const employer = useSelector((e) => e.employer);
  const profile = employer.message;
  const userPics = useSelector((prev) => prev.userInfo);
  const [picLink, setPicsLink] = useState("");

  useEffect(() => {
    if (userPics.user !== null) {
      setPicsLink(userPics.user?.profile_pics);
    }
  }, [userPics]);

  const handleClick = () => {
    navigate("/employer/create-job")
  };

  const handleAppView = () => {
    navigate("/employer/jobs");
  };

  function handleChangePP() {
    navigate("/update/logo");
  }

  function HandleUpdate() {
    navigate("/employer/form/update");
  }

  
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [upload, setUpload] = useState(false);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    acceptedFiles.forEach((file) => {
      // console.log("dropped!!!")
      setSelectedImages(() => [file]);
      setUploadStatus("loading");
    });
  }, []);

  // console.log(uploadStatus);

  useEffect(() => {
    let download = new URLSearchParams(window.location.search).get("download");
    if (download) {
      setSelect(true);
    }
  }, [])

  useEffect(() => {
    // console.log("called");
    handleFileChange();
    // sendBase64UrlToBackend();
  }, [selectedImages])

  const handleFileChange = () => {
    const file = selectedImages[0];
  
    if (file) {
      // Check the file type to ensure it's an image
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
  
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          // e.target.result contains the base64-encoded image data URL
          setPreviewUrl(e.target.result);
          // console.log("We did it baby"); // yes bro!!
        };
  
        // Read the file as a data URL (base64)
        reader.readAsDataURL(file);
      } else {
        // Handle the case where the file is not a valid image type.
        setTimeout(() => {
          toast.error("Invalid file type. Please upload an image file (JPEG, PNG, GIF, WEBP).", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      }
    } else {
      // Handle the case where no file is selected.
      console.error("No file selected.");
    }
  };
  

  useEffect(() => {
    if (previewUrl !== "") {
      sendBase64UrlToBackend();
    }
  }, [previewUrl]);

  const sendBase64UrlToBackend = () => {
    if (previewUrl) {
      setUpload(true);
      let token = localStorage.getItem("token");
      const body = {
        image_uri: previewUrl, // Send the base64 image data URL
      };

      const headers = {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      fetch(baseURL + "/api/employer/upload_photo", {
        method: "PATCH",
        body: JSON.stringify(body), // Send the JSON object containing the base64 URL
        headers,
        credentials: "include"
      })
        .then((resp) => resp.json())
        .then((resp) => {
          // console.log(resp);
          if (resp.success === true) {
            setUpload(false);
            dispatch(getting_employer_profile_successful(resp.data));
            setUploadStatus("done");
          }
        }).catch(err => {
          // console.log("Error", err);
          console.log("Profile upload error", err);
          if (err.response.status == 401) {
            window.location.assign(baseURL1);
          }
        });

    } else {
      // console.error("No file selected.");
    }
  };

  // console.log(selectedImages)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop });


  return (
    <div className="w-full py-6 bg-[#5843BD] rounded-lg flex flex-col md:flex-row justify-between px-6 xl:px-12">
      {/* div for profile LHS */}
      <div className="flex flex-col tablet:flex-row items-center w-full min-h-[10rem] gap-4 tablet:gap-9">
        {/* profile photo  */}
        {profile?.photo !== undefined ? (
          <>
           {/* <div className="relative h-35 w-35 rounded-[16px]">
            <img
              src={profile?.photo}
              alt=""
              className="rounded-[16px] border-[5px] border-white w-60 h-60 md:h-20 md:w-20 object-cover tablet:min-h-[158px] tablet:min-w-[158px]"
            />
            <div
              onClick={handleChangePP}
              className="absolute bottom-[10px] right-[10%]"
            >
              <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.0195" cy="12.3711" r="11.5" stroke="white" />
                <path d="M9.68555 9.03711H9.01888C8.66526 9.03711 8.32612 9.17759 8.07607 9.42763C7.82602 9.67768 7.68555 10.0168 7.68555 10.3704V16.3704C7.68555 16.7241 7.82602 17.0632 8.07607 17.3133C8.32612 17.5633 8.66526 17.7038 9.01888 17.7038H15.0189C15.3725 17.7038 15.7116 17.5633 15.9617 17.3133C16.2117 17.0632 16.3522 16.7241 16.3522 16.3704V15.7038" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.6862 7.7045L17.6862 9.7045M18.6095 8.76117C18.8721 8.4986 19.0196 8.14249 19.0196 7.77117C19.0196 7.39984 18.8721 7.04373 18.6095 6.78117C18.347 6.5186 17.9909 6.37109 17.6195 6.37109C17.2482 6.37109 16.8921 6.5186 16.6295 6.78117L11.0195 12.3712V14.3712H13.0195L18.6095 8.76117Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div> */}
          <div {...getRootProps()} className="w-60 md:w-[60%] md:max-w-[160px] relative rounded-[16px]  cursor-pointer">
              <input {...getInputProps()} />
              {uploadStatus === "loading" ? <div className="aspect-[1/1] object-cover border-4 border-solid border-white w-full rounded-[16px] flex justify-center items-center"><LinearProgress /></div> :
                <>
                  <img
                    src={profile?.photo}
                    alt="employee profile picture"
                    className="aspect-[1/1] max-w-full object-cover border-4 border-solid border-white w-full rounded-[16px]"
                  />

                  <svg width="28" height="28" viewBox="0 0 28 28" className="absolute top-[75%] left-[75%]" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13.2734" cy="13.002" r="11.5" stroke="white" />
                    <path d="M9.93945 9.66846H9.27279C8.91916 9.66846 8.58003 9.80893 8.32998 10.059C8.07993 10.309 7.93945 10.6482 7.93945 11.0018V17.0018C7.93945 17.3554 8.07993 17.6946 8.32998 17.9446C8.58003 18.1946 8.91916 18.3351 9.27279 18.3351H15.2728C15.6264 18.3351 15.9655 18.1946 16.2156 17.9446C16.4656 17.6946 16.6061 17.3554 16.6061 17.0018V16.3351" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.9401 8.33536L17.9401 10.3354M18.8634 9.39202C19.126 9.12946 19.2735 8.77335 19.2735 8.40202C19.2735 8.0307 19.126 7.67459 18.8634 7.41202C18.6009 7.14946 18.2448 7.00195 17.8734 7.00195C17.5021 7.00195 17.146 7.14946 16.8834 7.41202L11.2734 13.002V15.002H13.2734L18.8634 9.39202Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </>}

            </div>
          </>
        ) : (
          <>
          {/* <div className="relative h-40 w-40 rounded-full">
            <img
              src={picLink === "" ? reference.profilephoto1 : picLink}
              alt=""
              className="rounded-[16px] border-[5px] border-white object-cover min-h-[158px] min-w-[158px]"
            />
            <div
              onClick={handleChangePP}
              className="absolute bottom-0 right-[0%] translate-x-[25%] translate-y-[25%]"
            >
              <img src={reference.ppediticon} alt="" className="w-10 h-10" />
            </div>
          </div> */}
          <form class=" p-4 gap-4 rounded-lg bg-white shadow-md h-60 w-80">
          {/* Have to make the entire of this compoenet a <label> */}
          {/* <input /> {...getInputProps()} */}
          <div {...getRootProps()} class="p-2 gap-4 profile-pics-upload border-[#FF6633] flex gap-y-4 flex-col itmes-center cursor-pointer">
            <input {...getInputProps()} />
            {/* <div>
              <p class=" text-md font-bold leading-normal text-center text-[#777777]">
                Upload Profile Photo
              </p>
              <p class=" text-sm font-normal leading-normal text-center text-[#777777]">
                File can be .jpeg,  .jpg or .png
              </p>
            </div> */}

            <div className="flex flex-col items-center gap-y-2">
              <svg width="32" height="38" viewBox="0 0 42 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.7501 10.1786C40.2501 10.6786 40.6787 11.3571 41.0359 12.2143C41.393 13.0714 41.5716 13.8571 41.5716 14.5714V45.4286C41.5716 46.1429 41.3216 46.75 40.8216 47.25C40.3216 47.75 39.7144 48 39.0001 48H3.00014C2.28585 48 1.67871 47.75 1.17871 47.25C0.678711 46.75 0.428711 46.1429 0.428711 45.4286V2.57143C0.428711 1.85714 0.678711 1.25 1.17871 0.75C1.67871 0.25 2.28585 0 3.00014 0H27.0001C27.7144 0 28.5001 0.178571 29.3573 0.535714C30.2144 0.892857 30.893 1.32143 31.393 1.82143L39.7501 10.1786ZM27.8573 3.64286V13.7143H37.9287C37.7501 13.1964 37.5537 12.8304 37.3394 12.6161L28.9555 4.23214C28.7412 4.01786 28.3751 3.82143 27.8573 3.64286ZM38.143 44.5714V17.1429H27.0001C26.2859 17.1429 25.6787 16.8929 25.1787 16.3929C24.6787 15.8929 24.4287 15.2857 24.4287 14.5714V3.42857H3.85728V44.5714H38.143ZM34.7144 32.5714V41.1429H7.28585V36L12.4287 30.8571L15.8573 34.2857L26.143 24L34.7144 32.5714ZM12.4287 27.4286C11.0001 27.4286 9.78585 26.9286 8.78585 25.9286C7.78585 24.9286 7.28585 23.7143 7.28585 22.2857C7.28585 20.8571 7.78585 19.6429 8.78585 18.6429C9.78585 17.6429 11.0001 17.1429 12.4287 17.1429C13.8573 17.1429 15.0716 17.6429 16.0716 18.6429C17.0716 19.6429 17.5716 20.8571 17.5716 22.2857C17.5716 23.7143 17.0716 24.9286 16.0716 25.9286C15.0716 26.9286 13.8573 27.4286 12.4287 27.4286Z" fill="#777777" />
              </svg>
              <p class=" text-sm font-semibold leading-[14px] text-center text-[#777777]">
                Drag a profile photo here
              </p>
            </div>

            <div>
              <p class=" text-sm font-semibold leading-[14px] text-center text-[#77777780]">
                - or -
              </p>
            </div>

            <div class="w-full flex items-center justify-center py-3 px-2 gap-2 rounded-md border border-[#FF6633] shadow-md text-xs font-semibold leading-[18px] text-center text-[#FF6633] cursor-pointer">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 21C2.45 21 1.979 20.804 1.587 20.412C1.195 20.02 0.999335 19.5493 1 19V7C1 6.45 1.196 5.979 1.588 5.587C1.98 5.195 2.45067 4.99933 3 5H6.15L8 3H14V5H8.875L7.05 7H3V19H19V10H21V19C21 19.55 20.804 20.021 20.412 20.413C20.02 20.805 19.5493 21.0007 19 21H3ZM19 7V5H17V3H19V1H21V3H23V5H21V7H19ZM11 17.5C12.25 17.5 13.3127 17.0623 14.188 16.187C15.0633 15.3117 15.5007 14.2493 15.5 13C15.5 11.75 15.0623 10.6873 14.187 9.812C13.3117 8.93667 12.2493 8.49933 11 8.5C9.75 8.5 8.68734 8.93767 7.812 9.813C6.93667 10.6883 6.49934 11.7507 6.5 13C6.5 14.25 6.93767 15.3127 7.813 16.188C8.68834 17.0633 9.75067 17.5007 11 17.5ZM11 15.5C10.3 15.5 9.70834 15.2583 9.225 14.775C8.74167 14.2917 8.5 13.7 8.5 13C8.5 12.3 8.74167 11.7083 9.225 11.225C9.70834 10.7417 10.3 10.5 11 10.5C11.7 10.5 12.2917 10.7417 12.775 11.225C13.2583 11.7083 13.5 12.3 13.5 13C13.5 13.7 13.2583 14.2917 12.775 14.775C12.2917 15.2583 11.7 15.5 11 15.5Z" fill="#F24E1E" />
              </svg>

              Select a photo from your gallery
            </div>
          </div>
        </form>
        </>
        )}
        <div className="h-full flex flex-col justify-between w-full ">
          {/* text content here  */}
          <div className="flex flex-col gap-y-4 md:flex-row justify-between w-full">
            <div className="">
              <h2 className=" font-semibold text-2xl text-white notranslate" notranslate>
                {profile?.name}
              </h2>
              <p className="font-semibold text-base text-white">
                {profile?.type_of_business}
              </p>
              {userPics.user?.referred?.length > 0 &&
                (<p className="font-semibold text-base text-[#fff]">
                  {userPics.user?.referred?.length} referred
                </p>)}
              {/* container for stars and the rest  */}
              {/* <div className=" tablet:flex gap-2">
              <img src={refrence.fivestars} alt="" />
              <p className="font-medium text-xs">
                5.0{" "}
                <span className=" font-normal text-[#5843BD]">
                  (See all 25 reviews)
                </span>
              </p>
            </div> */}
            </div>

            <EditButton handleClick={HandleUpdate} />
          </div>
          {/* buttons  */}
          <div className=" mt-4 tablet:mt-0 font-semibold text-base tablet:justify-between flex-col tablet:flex-row hidden md:flex gap-2 tablet:gap-6">
            <button
              className="bg-[#FF6633] border border-[#FF6633] hover:text-[#ff] hover:bg-[#FF6633] px-5 py-3 rounded-lg px-5 py-3 rounded-full text-[#ffffff] whitespace-nowrap"
              onClick={handleClick}
            >
              Create Job
            </button>
            <button
              className="border border-[#fff] hover:text-[#ffffff] hover:border-[#ffffff] px-5 py-3 rounded-lg text-[#fff] whitespace-nowrap"
              onClick={handleAppView}
            >
              View Applications
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 tablet:mt-0 font-semibold text-base tablet:justify-between flex-col tablet:flex-row flex md:hidden gap-4">
        <button
          className="bg-[#FF6633] border border-[#FF6633] hover:text-[#FFF] hover:bg-[#FF6633] px-5 py-3 rounded-lg text-[#ffffff] whitespace-nowrap"
          onClick={handleClick}
        >
          Create Job
        </button>
        <button
          className=" border border-[#fff] hover:text-[#ffffff] hover:border-[#ffffff] px-5 py-3 rounded-lg text-[#fff] px-5 py-3 rounded-full text-[#5843BD] whitespace-nowrap"
          onClick={handleAppView}
        >
          View Applications
        </button>
      </div>

      {/* edit icon RHS */}
      {/* <div onClick={HandleUpdate}>
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.666 10.9999C19.4008 10.9999 19.1464 11.1053 18.9589 11.2928C18.7714 11.4804 18.666 11.7347 18.666 11.9999V17.9999C18.666 18.2652 18.5607 18.5195 18.3731 18.707C18.1856 18.8946 17.9312 18.9999 17.666 18.9999H3.66602C3.4008 18.9999 3.14645 18.8946 2.95891 18.707C2.77137 18.5195 2.66602 18.2652 2.66602 17.9999V3.99994C2.66602 3.73472 2.77137 3.48037 2.95891 3.29283C3.14645 3.1053 3.4008 2.99994 3.66602 2.99994H9.66602C9.93123 2.99994 10.1856 2.89458 10.3731 2.70705C10.5607 2.51951 10.666 2.26516 10.666 1.99994C10.666 1.73472 10.5607 1.48037 10.3731 1.29283C10.1856 1.1053 9.93123 0.999939 9.66602 0.999939H3.66602C2.87037 0.999939 2.1073 1.31601 1.5447 1.87862C0.982086 2.44123 0.666016 3.20429 0.666016 3.99994V17.9999C0.666016 18.7956 0.982086 19.5587 1.5447 20.1213C2.1073 20.6839 2.87037 20.9999 3.66602 20.9999H17.666C18.4617 20.9999 19.2247 20.6839 19.7873 20.1213C20.3499 19.5587 20.666 18.7956 20.666 17.9999V11.9999C20.666 11.7347 20.5607 11.4804 20.3731 11.2928C20.1856 11.1053 19.9312 10.9999 19.666 10.9999ZM4.66602 11.7599V15.9999C4.66602 16.2652 4.77137 16.5195 4.95891 16.707C5.14645 16.8946 5.4008 16.9999 5.66602 16.9999H9.90602C10.0376 17.0007 10.1681 16.9755 10.2899 16.9257C10.4118 16.8759 10.5226 16.8026 10.616 16.7099L17.536 9.77994L20.376 6.99994C20.4697 6.90698 20.5441 6.79637 20.5949 6.67452C20.6457 6.55266 20.6718 6.42195 20.6718 6.28994C20.6718 6.15793 20.6457 6.02722 20.5949 5.90536C20.5441 5.7835 20.4697 5.6729 20.376 5.57994L16.136 1.28994C16.0431 1.19621 15.9325 1.12182 15.8106 1.07105C15.6887 1.02028 15.558 0.994141 15.426 0.994141C15.294 0.994141 15.1633 1.02028 15.0414 1.07105C14.9196 1.12182 14.809 1.19621 14.716 1.28994L11.896 4.11994L4.95602 11.0499C4.86333 11.1434 4.79001 11.2542 4.74024 11.376C4.69048 11.4979 4.66525 11.6283 4.66602 11.7599ZM15.426 3.40994L18.256 6.23994L16.836 7.65994L14.006 4.82994L15.426 3.40994ZM6.66602 12.1699L12.596 6.23994L15.426 9.06994L9.49602 14.9999H6.66602V12.1699Z"
            fill="#FF6633"
          />
        </svg>
      </div> */}
      <ToastContainer />
      {upload ? <LoaderModal text="Uploading profile picture..."/> : null}
    </div>
  );
}

export default EmployerProfileHeaderOfficial;





