import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MessageBox from "./components/message_box";
import MessageVerticalItem from "./components/message_vertical_item";
import { getEndToEndChatInformation, end_to_end_chat_loading, getting_successful, message_notification } from "../../Redux/ActionCreator";
const { io } = require("socket.io-client");
import { baseURL } from "../../Redux/baseURL";
import timeResolver from "../common/timeResolver";
import BackButton from "../common/back_button";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";


export default function EmployerInboxUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login_stat = useSelector(e => e.login);
  const AcctType = login_stat?.profile_status?.AcctType;
  const chat_info = useSelector(e => e.chat_info.message);
  const index = new URLSearchParams(window.location.search).get("i");
  const newMessage = useSelector(e => e.end_to_end.message);
  // const employer = useSelector(e => e.employer);
  // const [ socket, setSocket ] = useState(null);
  // const [ onlineUser, setOnlineUser ] = useState([]);
  // const [ newMsg, setNewMsg ] = useState(newMessage?.message);
  const [notification, setNotification] = useState([]);

  console.log("newMessage", newMessage);
  // return the notification back to 0 since it has been read
  // useEffect(() => {
  //   chat_info[index].Notification = 0;
  // }, [])

  useEffect(() => {
    // dispatch(end_to_end_chat_loading(true));
    if (AcctType === "Official") {
      console.log("chat_info calllled!!!")
      dispatch(getEndToEndChatInformation(chat_info[index]?.chatIds[0]?.employerId, chat_info[index]?.chatIds[0]?.employeeId?._id));

      dispatch(getting_successful([chat_info[index]?.chatIds[0]?.resumeId]));
    }
  }, [index]);

  console.log("notification", notification);

  return (
    <>
      <div className="Inbox px-3 sm:px-6 pb-2 h-calc-150 md:h-calc-70">
        <div className="flex-col max-w-[520px] gap-y-1 mb-4 hidden xl:flex">
          <p className="text-black text-[24px] font-semibold  leading-normal">Messages and Interview</p>
          <p className="text-black text-base font-normal  leading-normal">Chat with candidates and interview them. Remember to send an offer card when you find a candidate who fits your needs.</p>
        </div>
        <div className="xl:hidden">
          <BackButton onClick={() => navigate('/employer/inbox')} />
        </div>
        <div className="flex h-calc-chatarea-mobile md:h-calc-chatarea">
          <div
            className="py-4 w-full max-w bg-white bg-opacity-80 rounded-tl-xl rounded-b-xl shadow border border-indigo-300 border-opacity-30 hidden xl:block xl:max-w-[320px] xtraxl:max-w-[340px]">
            <div className="ChatMessage h-full overflow-y-scroll px-4 pb-4">
              {chat_info !== null && (
                <>{chat_info?.map((data, key) => (
                  <MessageVerticalItem
                    name={data?.chatIds[0]?.employeeId?.Fullname}
                    position={data?.chatIds[0]?.jobTitle}
                    team="Design Team"
                    messageAge={timeResolver(data?.createdAt, "message")}
                    messageNo={data?.Notification}
                    avatar={data?.chatIds[0]?.employeeId?.photo}
                    receiver={data?.chatIds[0]?.employeeId?.Fullname}
                    userType="employer"
                    index={chat_info?.indexOf(data)}
                    key={key}
                  />
                ))}</>
              )}
            </div>
          </div>
          {chat_info && (
            <MessageBox userType="employer" index={index} avatar={chat_info[index]?.chatIds[0]?.employeeId?.photo} newMessage={newMessage} receiverId={chat_info[index]?.chatIds[0]?.employeeId?._id} position={chat_info[index]?.chatIds[0]?.jobTitle} />
          )}
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
