import JobDetails from "./job_details";
import EmploymentDetails from "./employment_details";
import { useState } from "react";
import JobPostPreviewDetails from "./preview";
import SkillsTest from "./skill_test";

export default function JobPosting() {
  const [step, setStep] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  // This function is used to go forward in out multi page forms. When Implemeted in children components.
  // we will see that our application re renders to the next form inputs page
  const handleStepChangeForward = () => {
    setStep((prev) => {
      // if (step !== 1) {
      //   return prev + 1;
      // }
      return prev + 1;
    });
  };

  console.log(step);

  // This function is used to go back in out multi page forms. When Implemeted in children components.
  // we will see that our application re renders to the previous form inputs page
  const handleStepChangeBackward = () => {
    setStep((prev) => {
      return prev - 1;
    });
  };

  return (
    <>
      {step === 0 ? (
        <JobDetails handleStepChangeForward={handleStepChangeForward} />
      ) : step === 1 ? (
        <EmploymentDetails
          handleStepChangeForward={handleStepChangeForward}
          handleStepChangeBackward={handleStepChangeBackward}
        />
      ) : step === 2 ? (
        <SkillsTest
          handleStepChangeForward={handleStepChangeForward}
          handleStepChangeBackward={handleStepChangeBackward}
        />
      ) : step === 3 ? (
        <JobPostPreviewDetails
          handleStepChangeBackward={handleStepChangeBackward}
        />
      ) : (
        <div></div>
      )}
    </>
  );
}
