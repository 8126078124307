export default function Volunteer(props) {
  return (
    <div className="flex-col justify-start items-start gap-2 inline-flex">
      <div className="text-black text-opacity-60 text-xs font-normal">
        {props.year}
      </div>
      <div className="text-indigo-700 text-sm font-medium">
        {props.jobTitle}
      </div>
      <div className="text-black text-opacity-90 text-xs font-medium">
        {props.institution}
      </div>
    </div>
  );
}