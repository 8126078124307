import React from "react";
import {
  ZoomSvg,
  ExperienceIcon,
  LocationIcon,
  VectorSvg,
  SmallSendSvg,
} from "../svgs/svgs";
import { useNavigate } from "react-router-dom";
import TextTruncate from "react-text-truncate";

export default function DashboardCandidateItem(props) {
  const navigate = useNavigate();

  return (
    <div className="w-full rounded-lg bg-[#DDDFFA4D] border border-[#777DEA80] bg-[#DDDFFA4D]">
      <div className="h-full w-full px-4 py-3 flex flex-col justify-between">
        <div className="items-center w-full flex">
          <div className="flex w-[65%] items-center gap-x-2">
            {props.candidateImage ? (
              <img src={props?.candidateImage} className="w-10 h-10 rounded-lg" />
            ) : (
              <ZoomSvg className="tablet:h-10 w-10" />
            )}
            <div className="flex-col justify-start items-start dash-name flex">
              <p className="text-slate-800 text-lg font-bold w-full truncate">
                {props.candidate}{" "}
              </p>
              <p className="text-slate-800 text-xs font-medium w-full truncate">
                {props.candidateJob}
              </p>
            </div>
          </div>

          <div className="w-[35%] flex justify-end">
            <div
              className="px-4 max-w-max py-2 bg-indigo-700 rounded-[25px] border border-indigo-400 border-opacity-30 flex gap-2 justify-center items-center cursor-pointer"
              onClick={() => navigate(`/profile/beta/${props?.profileId}?d=dlink`)}
            >
              <p className="View text-center text-white text-sm font-bold">View</p>
              <SmallSendSvg />
            </div>
          </div>
        </div>
        <div className="justify-start gap-2 my-2 flex flex-col">
          <div className="w-max max-w-[200px] px-[11.65px] py-[5.82px] bg-[#FFFFFF59] rounded-3xl border border-white justify-start items-center gap-[6px] flex">
            <LocationIcon className="w-max" />
            <p className=" w-max Bengaluru text-center text-[#22263A] text-xs font-medium leading-3 truncate">
              {props.candidateLocation}
            </p>
          </div>
          <div className="flex gap-x-2">
            <div className="w-max max-w-[130px] px-[11.65px] py-[5.82px] bg-[#FFFFFF59] rounded-3xl border border-white justify-start items-center gap-[6px] flex">
              <ExperienceIcon className="w-max" />
              <p className="w-max text-center text-[#22263A] text-xs font-medium leading-3 truncate">{`${props?.candidateExperienceLevel} years exp.`}</p>
            </div>
            {/* <div className="w-max max-w-[130px] px-[11.65px] py-[5.82px] bg-[#FFFFFF59] rounded-3xl border border-white justify-start items-center gap-[6px] flex">
              <ExperienceIcon className="w-max" />
              <p className="w-max text-center text-[#22263A] text-xs font-medium leading-3 truncate">{`${props?.candidateJob}`}</p>
            </div> */}
          </div>
        </div>
        <TextTruncate
          className="text-[10px] w-[90%] text-[#22263A] overflow-y-hidden leading-[1.1]"
          line={3}
          element="p"
          truncateText="…"
          text={props.candidateBio}
          textTruncateChild={
            <a className="inline " href={`/profile/beta/${props?.profileId}?d=dlink`}>
              Read More
            </a>
          }
        />
      </div>
    </div>
  );
}
