import React from "react";
import timeResolver from "../timeResolver";
import { ToastContainer, toast } from "react-toastify";
import { ShareIcon } from '../../common/svgs/svgs';
import { baseURL1 } from "../../../Redux/baseURL";

export default function JobPostTableItem(props) {

  const copyToClipboard = async () => {
    try {
      console.log("analytics", analytics);
      await navigator.clipboard.writeText(`${baseURL1}/jobs/` + props.jobName);
      toast.success('Link copied to clipboard!', { position: toast.POSITION.TOP_CENTER });
      analytics.track("Web job share before login", {
        Type: "Pre-login Job share web",
        time: new Date(),
      });
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
      toast.error('Failed to copy link to clipboard.', { position: toast.POSITION.TOP_CENTER });
    }
  };

  const shareMobileLink = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Share Link',
          text: 'Check out this link!',
          url: "https://employee.moilapp.com/jobs/" + props.jobName,
        });
        analytics.track("Mobile job share before login", {
          Type: "Pre-login Job share mobile",
          time: new Date(),
        });
      } catch (error) {
        console.error('Error sharing link:', error);
      }
    } else {
      // Fallback for browsers that don't support navigator.share
      console.warn('navigator.share is not supported.');
      // You can provide a fallback UI or inform the user about alternative ways to share.
    }
  };

  const shareLink = async () => {
    const screenwidth = window.innerWidth;
    console.log(screenwidth);

    screenwidth < 1024 ? await shareMobileLink() : await copyToClipboard();
  }

  return (
    <div className="w-full py-4 job_shadow rounded-xl bg-white ">
      <div className="w-full flex flex-col sm:flex-row gap-y-4 sm:gap-0 px-4 justify-between items-center">
        <div className="w-full flex flex-col">
          <div className="w-full flex gap-3 items-center">
            <div className="rounded-[4px]">
              <img
                src={props?.logo}
                className="min-w-10 rounded-[4px] h-10"
                alt={props?.jobPosition}
              />
            </div>
            <div className="w-full flex flex-col">
              <p className="w-full text-black text-base font-medium">
                <span>{props?.jobPosition}</span>
              </p>
              <p className="text-[#000000DE] leading-normal text-xs font-normal">
                Posted <span>{timeResolver(props?.postAge)}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row items-center md:gap-3 md:justify-end justify-between">
          <span>
            {props.closed && (
                <button
                  className={`w-full py-3 px-5 sm:max-w-[180px] rounded-lg md:whitespace-nowrap shadow border bg-[#5843BD] text-base xsm:text-sm sm:text-base border-neutral-50 border-opacity-20 text-white `}
                  onClick={props.onClick}
                >
                  <span>Review Details</span>
                </button>
            )}
          </span>
          <span>
            {props.closed && (
              <button
                className="w-full px-5 py-3 rounded-lg sm:max-w-[180px] border border-[#FF663380] justify-center items-center gap-2.5 flex focus:border-2 focus:bg-[#fff] focus:border-[#FF7E1F]"
                onClick={shareLink}
              >
                <ShareIcon className="fill-[#FF7E1F]"/>
                <span className="text-center text-[#FF7E1F] text-base font-semibold">
                  Share
                </span>
              </button>
            )}
          </span>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
