import React, { useState, useRef } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { PaymentElement } from '@stripe/react-stripe-js';
import "../../styles/payment.css";
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from '../../context/AnalyticsContext';
import { useNavigate } from 'react-router-dom';
import { baseURL, baseURL1 } from '../../Redux/baseURL';
import { ToastContainer, toast } from "react-toastify";
import { updateStripeCredentials, returnHome } from '../../Redux/ActionCreator';
import TextInput from '../common/text_input/text_input';
import BackButton from '../common/back_button';

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: '500',
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87BBFD',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  },
}

const PaymentForm = (props) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const employer = useSelector(e => e.employer.message);
  const [errorMessage, setErrorMessage] = useState(null);
  const [processing, setProcessing] = useState(false);
  // initialize useStripe and useEffect hook
  const stripe = useStripe();
  const elements = useElements();
  const [cardElement, setCardElement] = useState(null);
  const [coupon, setCoupon] = useState('');

  const handlePerpay = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const response = async () => {
      fetch(baseURL + "/api/stripe/payPerjob", {
        method: "POST",
        body: JSON.stringify({
          name: employer?.name,
          email: employer?.email,
          // priceId: 'price_1OY3fxJbg8KVoBIgb2cYofUJ',
          coupon: coupon
        }),
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + localStorage.getItem("token"),
        },
        credentials: "include"
      }).then(resp => resp.json())
        .then(resp => {
          if (resp.statusCode == 200 && resp.success === true) {
            window.open(resp.data.url, "_self")
          } else if (resp.success === false && resp?.message == "Unauthorized") {
            dispatch(returnHome(resp?.status));
          } else if (resp.statusCode == undefined) {
            if (resp?.title == "Error") {
              setTimeout(() => {
                toast.error(resp.error?.raw?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
            }
          }
          return resp;
        }).catch(err => console.log("my err",err));
    }
    let res = await response();

    setProcessing(true);
    analytics.track("Payment btn from stripe", {
      Type: "Employer",
      time: new Date(),
      Email: employer?.email,
      Company_name: employer?.name,
      profileId: employer?._id,
      userId: employer?.userId
    });

  }

  const handleTenPay = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const response = async () => {
      fetch(baseURL + "/api/stripe/payTenJob", {
        method: "POST",
        body: JSON.stringify({
          name: employer?.name,
          email: employer?.email,
          // priceId: 'price_1OY3fxJbg8KVoBIgb2cYofUJ',
          coupon: coupon
        }),
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + localStorage.getItem("token"),
        },
        credentials: "include"
      }).then(resp => resp.json())
        .then(resp => {
          if (resp.statusCode == 200 && resp.success == true) {
            window.open(resp.data.url, "_self")
          } else if (resp.success === false && resp?.message == "Unauthorized") {
            dispatch(returnHome(resp?.status));
          } else if (resp.statusCode == undefined) {
            if (resp?.title == "Error") {
              setTimeout(() => {
                toast.error(resp.error?.raw?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
            }
          }
          return resp;
        }).catch(err => console.log(err));
    }
    let res = await response();

    setProcessing(true);
    analytics.track("Payment btn from stripe", {
      Type: "Employer",
      time: new Date(),
      Email: employer?.email,
      Company_name: employer?.name,
      profileId: employer?._id,
      userId: employer?.userId
    });

  }

  // handle submit function
  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const response = async () => {
      fetch(baseURL + "/api/stripe/create-subscription", { // /test
        method: "POST",
        body: JSON.stringify({
          name: employer?.name,
          email: employer?.email,
          // priceId: 'price_1OY3fxJbg8KVoBIgb2cYofUJ',
          coupon: coupon
        }),
        headers: {
          "Content-Type": "application/json",
          // Authorization: "Bearer " + localStorage.getItem("token"),
        },
        credentials: "include"
      }).then(resp => resp.json())
        .then(resp => {
          if (resp.statusCode == 200) {
            window.open(resp.data.url, "_self")
          } else if (resp.statusCode == undefined) {
            if (resp?.title == "Error") {
              setTimeout(() => {
                toast.error(resp.error?.raw?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
            }
          }
          return resp;
        }).catch(err => console.log(err));
    }
    let res = await response();

    setProcessing(true);
    analytics.track("Payment btn from stripe", {
      Type: "Employer",
      time: new Date(),
      Email: employer?.email,
      Company_name: employer?.name,
      profileId: employer?._id,
      userId: employer?.userId
    });
  };

  const handleSubmitFree = () => {
    navigate("/employer/create-job/preview?payment=true");
  }

  return (
    <div className='w-[90%] h-[95%]'>
      <BackButton onClick={() => navigate("/employer/create-job/preview?payment=hd")}/>
      <div className='payment py-8 px-8 xl:p-10 w-full overflow-y-scroll h-full md:flex md:justify-center'>
        <div className='flex items-center gap-x-3 flex-col lg:flex-row py-8'>
          {/* par pay */}
          <div className='border-white border-2 flex flex-col px-4 py-4 vsm:mb-4 md:mb-0 rounded-lg'>
            <div className='flex flex-col gap-y-2'>
              <img src='https://res.cloudinary.com/drlcisipo/image/upload/v1705704265/Website%20images/Moil_Company_yctorj.png' width={103} height={48} className='self-center' />
              <div className="text-indigo-700 text-xl self-center font-semibold font-['Poppins']">Enter your payment details</div>
              <div className="max-w-[373px] text-center self-center text-stone-950 text-base font-normal font-['Poppins'] py-4">Pay $30.00 dollar to post a job. Securely complete your payment through our trusted payment gateway.<br/> <span className="text-red-700">Please wait to be redirected back to this page after making your payment</span></div>
            </div>
            <div className='flex items-center justify-center mb-6'>
              <div className='max-w-[200px] '>
                <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1716468417/Website%20images/30bucks_yvddha.png" alt='basic' className='object-cover' />
              </div>
            </div>
            <div className=''>
              <form onSubmit={handlePerpay}>
                {/* <PaymentElement /> */}

                <TextInput
                  placeholder="Enter coupon or promotion code..."
                  type="text"
                  value={coupon}
                  onChangeHandler={(e) => setCoupon(e.target.value)}
                />
                {/* <input type="text" name="priceId" value="price_1OY3fxJbg8KVoBIgb2cYofUJ" /> */}
                <button className="px-6 py-3 mt-4 bg-indigo-700 rounded-lg shadow justify-center items-center w-full gap-2.5 inline-flex text-center text-white text-base font-semibold font-['Poppins']" onClick={handlePerpay}>
                  <h6>Pay Now $30.00</h6>
                </button>
                {/* Show error message to your customers */}
                {errorMessage && <div className="text-red-700">{errorMessage}</div>}
              </form>
            </div>
          </div>
          {/* 10 job pay */}
          <div className='border-white border-2 rounded-lg flex flex-col px-4 py-4 vsm:mb-4 md:mb-0'>
            <div className='flex flex-col gap-y-2'>
              <img src='https://res.cloudinary.com/drlcisipo/image/upload/v1705704265/Website%20images/Moil_Company_yctorj.png' width={103} height={48} className='self-center' />
              <div className="text-indigo-700 text-xl self-center font-semibold font-['Poppins']">Enter your payment details</div>
              <div className="max-w-[373px] text-center self-center text-stone-950 text-base font-normal font-['Poppins'] py-4">Pay $150.00 to post 10 jobs. Securely complete your payment through our trusted payment gateway. Enjoy 10 job postings.<br/> <span className="text-red-700">Please wait to be redirected back to this page after making your payment.</span></div>
            </div>
            <div className='flex items-center justify-center mb-6'>
              <div className='max-w-[200px] '>
                <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1716468417/Website%20images/150bucks_nyeboc.png" alt='basic' className='object-cover' />
              </div>
            </div>
            <div className='mt-4'>
              <form onSubmit={handleTenPay}>
                {/* <PaymentElement /> */}

                <TextInput
                  placeholder="Enter coupon or promotion code..."
                  type="text"
                  value={coupon}
                  onChangeHandler={(e) => setCoupon(e.target.value)}
                />
                {/* <input type="text" name="priceId" value="price_1OY3fxJbg8KVoBIgb2cYofUJ" /> */}
                <button className="px-6 py-3 mt-4 bg-indigo-700 rounded-lg shadow justify-center items-center w-full gap-2.5 inline-flex text-center text-white text-base font-semibold font-['Poppins']" onClick={handleTenPay}>
                  <h6>Pay Now $150.00</h6>
                </button>
                {/* Show error message to your customers */}
                {errorMessage && <div className="text-red-700">{errorMessage}</div>}
              </form>
            </div>
          </div>

          {/* subscription */}
          <div className='border-white border-2 rounded-lg  flex flex-col px-4 py-4 vsm:mb-4 md:mb-0'>
            <div className='flex flex-col gap-y-2'>
              <img src='https://res.cloudinary.com/drlcisipo/image/upload/v1705704265/Website%20images/Moil_Company_yctorj.png' width={103} height={48} className='self-center' />
              <div className="text-indigo-700 text-xl self-center font-semibold font-['Poppins']">Enter your payment details</div>
              <div className="max-w-[373px] text-center self-center text-stone-950 text-base font-normal font-['Poppins'] py-4">Subscribe to our annual job posting membership for $699.00. Securely complete your payment through our trusted payment gateway. Enjoy unlimited job postings for one year.<br/> <span className="text-red-700">Please wait to be redirected back to this page after making your payment</span></div>
            </div>
            <div className='flex items-center justify-center mb-6'>
              <div className='max-w-[200px] '>
                <img src="https://res.cloudinary.com/drlcisipo/image/upload/v1716468417/Website%20images/699bucks_tvotmh.png" alt='basic' className='object-cover' />
              </div>
            </div>
            <div className=''>
              <form onSubmit={handleSubmit} className=''>
                {/* <PaymentElement /> */}

                <TextInput
                  placeholder="Enter coupon or promotion code..."
                  type="text"
                  value={coupon}
                  onChangeHandler={(e) => setCoupon(e.target.value)}
                />
                {/* <input type="text" name="priceId" value="price_1OY3fxJbg8KVoBIgb2cYofUJ" /> */}
                <button className="px-6 py-3 mt-4 bg-indigo-700 rounded-lg shadow justify-center items-center w-full gap-2.5 inline-flex text-center text-white text-base font-semibold font-['Poppins']" onClick={handleSubmit}>
                  <h6>Pay Now $699.00</h6>
                </button>
                {/* Show error message to your customers */}
                {errorMessage && <div className="text-red-700">{errorMessage}</div>}
              </form>
            </div>

          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default PaymentForm;