import { useSelector } from "react-redux";


export default function MessageBoxHeader(props) {
  const AcctType = useSelector(e => e.login.profile_status.AcctType)
  const chat_info = useSelector((e) => e.chat_info.message);
  const publicInfo = useSelector(e => e.public);

  const publicProfile = publicInfo.message?.profile;

  const matched = publicProfile?._id == props.receiverId;

  return (
    <div className="w-full">
      <div className="w-full px-4 py-2 bg-[#FFFFFF99] flex-col sm:flex-row rounded-lg justify-start items-center gap-4 inline-flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
          <p className="text-indigo-700 text-base font-semibold leading-snug">
            Application Status
          </p>
          <div className="w-full text-sm text-black leading-[1.4] max-h-[64px] overflow-y-hidden">
          {/* 
            If matched is true and AcctType is "Official", 
            render the text "You have invited this candidate for your job".
            If !matched is true (meaning matched is false) and AcctType is "Official", 
            render the text "This candidate is applying for the role of".
            Otherwise, render the text "You have been invited to the job"
           */}
            {matched && AcctType === "Official" ?
              <span className="text-black">
                You have invited this candidate for your job
              </span>
              :
              !matched && AcctType === "Official" ?
                <span className="text-black">
                  This candidate is applying for the role of {props.position}
                </span>
                :
                <span className="text-black text-xs font-normal">
                  You have been invited to the job
                </span>
            }

            <span className="text-indigo-700">
              {" "}
            </span>
            <span className="text-indigo-700 font-semibold">
              {chat_info[props.index]?.chatIds[0].jobTitle}{" "}
            </span>
            {
              AcctType === "Official" ? <span className="text-black">
                and their application status has been updated to{" "}
              </span> : <span className="text-black">
                and your application status has been updated to{" "}
              </span>
            }
            <span className="text-indigo-700  font-semibold">
              Interview.
            </span>
            {matched ? <span>{AcctType === "Official" ? <>Click on ‘Hire’ to send them an offer.</> : null}</span> : <span className="font-semibold">{AcctType === "Official" ? <> The hire button will be active after a total of 10 messages.</> : null}</span>}
          </div>
        </div>
        {props.isAllowedToHire && (
          <button onClick={() => props.sendDecisonCard(/* props.cardId */)} className="sm:max-w-[79px] w-full px-3 py-2.5 bg-gradient-to-l from-indigo-700 to-orange-500 rounded-lg shadow justify-center items-center gap-4 inline-flex">
            <span className="text-white text-base font-medium">
              Hire
            </span>
          </button>
        )}
      </div>
    </div>
  );
}
