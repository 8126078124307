import React, { useEffect, useState } from "react"
import {
  Document,
  Page,
  Text,
  View,
  Link,
  StyleSheet,
  Line,
  Svg,
  Path,
  Image,
  PDFViewer
} from "@react-pdf/renderer";

// require useSelector from react-redux
import { useSelector } from "react-redux";

import font from "./font/poppins-v20-latin-600.ttf";
import font1 from "./font/poppins-v20-latin-300.ttf";
import { Font } from '@react-pdf/renderer'

Font.register({ family: 'Poppins', src: font1});
Font.register({ family: 'Poppins-bold', src: font});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    color: "#2F2F2F",
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "row",
    paddingTop: 30,
    paddingBottom: 30
  },
  text_h4: {
    fontSize: 30,
    fontFamily: "Poppins-bold",
    width: "auto",
    left: 40,
    top: -82,
  },
  viewer: {
    width: '55vw', height: '100vh',
    '@media maxWidth: 500' : {
      width: "60vw", height: "80vh"
    }
  },
  left_side : {
    width: "35vw",
    color: "#2F2F2F",
    backgroundColor: "#FFFFFF",
    height: "auto",
    paddingBottom: 20,
    paddingTop: 20,
    marginLeft: 20
  },
  right_side: {
    width: "65vw",
    color: "#2F2F2F",
    paddingBottom: 20,
    paddingTop: 20,
    marginLeft: 10,
  }
});



function BlueShark(props) { 
  
  let details;
  details = [props.details]

  const [ wrap_stat, setWrap_stat ] = useState(false);
  useEffect(() => {
    if (details[0].Experience.length >= 2) {
      setWrap_stat(true);
    } else {
      setWrap_stat(false);
    }
  }, [])


  let my_interest;
  if (details[0] !== null) {
    if (Array.isArray(details[0]?.Interest)) {
      my_interest = details[0]?.Interest;
    } else if (typeof details[0]?.Interest === "string") {
      my_interest = details[0]?.Interest.split(", ");
    }
  }
  if (Array.isArray(details[0]?.Technical_skills) === false ) {
    if (typeof details[0]?.Technical_skills === "string") {
      details[0].Technical_skills = details[0]?.Technical_skills.split(", ");
    }
   }

   if (Array.isArray(details[0]?.Personal_skills) === false ) {
    if (typeof details[0]?.Personal_skills === "string") {
      details[0].Personal_skills = details[0]?.Personal_skills.split(", ");
    }
   }

   
  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => { // to avoid breaking of words.
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });
  return (

    <Document style={styles.page}>
    <Page size="A4" style={styles.page} wrap={wrap_stat}>
            { details?.map((data, key) => (
                  <View style={{ display: "flex", flexDirection: "row" }} key={key}>
                    {/* left side start */}
                  <View style={styles.left_side}>
                    <View>
                      <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: 200, marginLeft: 15 }}>
                        <Text style={{fontSize: 20, fontFamily: "Poppins-bold", color: "#FF6633" }} >{data.Fullname} </Text>
                      </View>
                      {data.CvType === null || data.CvType === undefined ? null : <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: 200, marginLeft: 15 }}>
                        <Text style={{ fontSize: 12, fontFamily: "Poppins"}} >{data.CvType} </Text>
                      </View>
                      }
                      
                    </View>

                    <View style={{width: 200, marginLeft: 15, marginTop: 10}}>
                      <Text style={{  fontSize: 8, fontFamily: "Poppins"}}><Link src={data.Website} style={{textDecoration: "none", color: "#000000" }}>{data.Website}</Link></Text>
                      <Text style={{ fontSize: 8, fontFamily: "Poppins"}}>{data.Email}</Text>
                      <Text style={{  fontSize: 12, fontFamily: "Poppins"}}></Text>
                    </View>

                    {/* skills start */}
                    { data.Technical_skills?.length === 0 && data.Personal_skills?.length === 0 ? null : 
                        <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: "170px", marginLeft: 13, marginTop: 10}}>
                          {data.Technical_skills?.length === 0 ? null : 
                          <View  style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                            {data.Technical_skills?.map((T_data, key) => (
                              <View style={{  display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "2px solid #000000", padding: "2px 10px", borderRadius: "5px", margin: 2}} key={key}>
                                {T_data.value !== undefined ? <Text style={{ color: "#000000",fontSize: 8, fontFamily: "Poppins-bold",}}>{T_data.value}</Text> : <Text style={{ color: "#000000",fontSize: 8, fontFamily: "Poppins-bold",}}>{T_data}</Text>}
                              </View>
                            ))}
                          </View>
                          }
                          {
                            data.Personal_skills?.length === 0 ? null : 
                            <View  style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                              {data.Personal_skills?.map((P_data, key) => (
                                <View style={{  display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", border: "2px solid #000000", padding: "2px 10px", borderRadius: "5px", margin: 2}} key={key}>
                                  <Text style={{ color: "#000000",fontSize: 8, fontFamily: "Poppins-bold",}}>{P_data.value !== undefined ? <Text>{P_data.value}</Text> : <Text>{P_data}</Text>}</Text>
                                </View>
                              ))}
                            </View>
                          }
                      </View>
                    }
                    {/* skills end */}
                    
                    {/* Award start */}
                    {
                      data.Award?.length !== 0 && data.Award[0]?.Award_name.length !== 0 ?  
                      <View style={{marginTop: 10}}>
                        {data.Award[0]?.Award_name === null ? null : 
                        <View style={{marginLeft: 13}}>
                            {
                            data.Lang[0]?.lang === 'English' ? 
                            <Text style={{ fontFamily: "Poppins-bold", fontSize: "12px", color: "#FF6633"}}>Award</Text> : <Text style={{marginBottom: 10, fontFamily: "Poppins-bold", fontSize: "12px", color: "#FF6633"}}>Premio</Text> 
                            }
                          {/* map honor and award */}
                          { data.Award?.map((H_data, key) => (
                            <View style={{marginTop: 0}} key={key}>
                              <View style={{display: "flex", flexDirection: "row", width: 185}}>
                                <View><Text style={{ fontFamily: "Poppins-bold", fontSize: "8px"}}>{H_data.Award_name}</Text></View>
                                <View style={{marginLeft: "auto", fontFamily: "Poppins", fontSize: "8px"}}><Text>{H_data.Award_year}</Text></View>
                              </View>
                              <View style={{marginTop: 0}}>
                                <Text style={{ fontFamily: "Poppins", fontSize: "8px", width: 180}}>{H_data.Award_giver}</Text>
                              </View>
                            </View>
                          ))}</View>}
                      </View>  : null
                    }
                    {/* Award end */}

                    {/* language */}
                    { data.Language?.length === 0 ? null : 
                      <View style={{ marginTop: 5}}>
                      {
                      data.Lang[0]?.lang === 'English' ? 
                      <Text style={{fontSize: 12, marginLeft: 15, marginTop: 5, marginBottom: 0, fontFamily: "Poppins-bold", color: "#FF6633"}}>Language</Text> : <Text style={{fontSize: 12, marginLeft: 15, marginTop: 5, marginBottom: 5, fontFamily: "Poppins-bold", color: "#FF6633"}}>Idiomas </Text> 
                      }
                        <View style={{marginLeft: 5}}>
                          {data.Language?.map((L_data, key) => (
                            <View style={{display: 'flex', flexDirection: "row", alignItems: "center", marginLeft: 8}}>
                              <Text style={{ fontFamily: "Poppins", fontSize: 8, color: "#000000", margin: "0px 3px"}} key={key}>{L_data.language} -</Text>
                              <Text style={{ fontFamily: "Poppins", fontSize: 8, color: "#000000", margin: "0px 2px"}} key={key}>{L_data.rate}</Text>
                            </View>
                          ))}
                        </View>
                      </View>
                    }
                  </View>
                  {/* left side end */}

                  {/* right side start */}
                  <View style={styles.right_side}>
                    {
                    data.Lang[0]?.lang === 'English' ?
                    <Text style={{fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 15, color: "#FF6633"}}>About</Text> : <Text style={{fontFamily: "Poppins-bold", fontSize: 12, marginLeft: 15, color: "#FF6633"}}> Sobre </Text> 
                    }
                    <View style={{width: 330, marginTop: 2}}>
                    <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: 320, marginLeft: 15}}>
                      <Text style={{fontFamily: "Poppins", fontSize: 8, }} >{data.Professional_summary} </Text>
                      </View>
                    </View>

                    {/* Experience start */}
                    {/* test experience out */}
                    { data.Experience?.length === 0 ? null : 
                      <View style={{marginLeft: 15, width: 320, marginTop: 5}}>
                        {
                          data.Lang[0]?.lang === 'English' ?
                          <Text style={{fontFamily: "Poppins-bold", fontSize: 12, color: "#FF6633"}}>Experience</Text> : <Text style={{fontFamily: "Poppins-bold", fontSize: 12, color: "#FF6633"}}>Experiencia</Text>
                        }
                      { data.Experience?.map((E_data, key) => (
                        <View style={{marginTop: 5}} key={key}>
                          <View style={{ marginTop: 0}}>
                          <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", width: 320, marginLeft: 0, marginRight: 10,}}>
                            <Text style={{fontFamily: "Poppins-bold", fontSize: 10,  }} >{E_data.My_job_title} </Text>
                          </View>
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginRight: 10, width: 320}}>
                              <Text style={{fontFamily: "Poppins", fontSize: 8, }} key={key}>{E_data.Where_i_worked} </Text>
                            </View>
                          </View>
                          <Text style={{fontFamily: "Poppins-bold", fontSize: 8, marginTop: 5}}>{E_data.Work_start_date} - {E_data.Work_end_date}</Text>
                          { Array.isArray(E_data.Day_to_day_work) === true ? 
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", maxWidth: 300, paddingTop: 5 }}>
                              {E_data.Day_to_day_work?.map((ex_data, key) => (
                                <View style={{display: "flex", flexDirection: "row", padding: 0, marginTop: -2 }} key={key}>
                                      <Text style={{ marginRight: 8, marginTop: -5 }}>•</Text>
                                      {ex_data[0] === "-" ? <Text style={{ fontFamily: "Poppins", fontSize: 8, marginTop:2 }} >{ex_data.slice(1, ex_data.length)}</Text> : <Text style={{ fontFamily: "Poppins", fontSize: 8, marginTop:2 }} >{ex_data.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')}</Text>}
                                </View>
                            ))}
                            </View> : <View>
                              <Text style={{fontFamily: "Poppins", fontSize: 8, width: 320}}>{E_data.Day_to_day_work.replace(/^(\d[^\w\s]*|[^\w\s])+/g, '')}</Text>
                            </View>
                          }{/* 
                          <Text style={{fontFamily: "Poppins", fontSize: 10,}}>{E_data.Day_to_day_work}</Text> */}
                        </View>
                      ))}
                    </View>
                    }
                    {/* Experience end */}

                    {/* Education start */}
                    { Array.isArray(data.Education) === false ? null : 
                      <View style={{marginLeft: 15, marginTop: 5}}>
                        {
                          data.Lang[0]?.lang === 'English' ?
                          <Text style={{fontFamily: "Poppins-bold", fontSize: 12, color: "#FF6633"}}>Education</Text> : <Text style={{fontFamily: "Poppins-bold", fontSize: 12, color: "#FF6633"}}>Educación</Text>
                        }
                        {data.Education?.map((Ed_data, key) => (
                          <View style={{marginTop: 2, width: 320}} key={key}>
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginRight: 10,}}>
                              <Text style={{fontFamily: "Poppins-bold", fontSize: 10}} >{Ed_data.School} </Text>
                            </View>
                            <View style={{display: "flex", flexDirection: "row", width: 320, justifyContent: "space-between", marginTop: 0}}>
                            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginRight: 10, width: 200 }}>
                              <Text style={{fontFamily: "Poppins", fontSize: 8, }}>{Ed_data.Course} </Text>
                            </View>
                              
                              <Text style={{fontFamily: "Poppins", fontSize: 8, width: 100}}>{Ed_data.End_date}</Text>
                            </View>
                          </View>
                        ))}
                      </View>
                    }
                    {/* Education end */}

                  </View>
                  {/* right side end */}
                  </View>
            ))}
            </Page>
          </Document>
  )
}

export default BlueShark