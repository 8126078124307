import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  StyleSheet,
  View,
  Text,
  Svg,
  Line,
  Image,
  Link,
  Circle,
  PDFViewer
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
// require fonts

import font from "./font/SourceSansPro-Light.ttf";
import font1 from "./font/SourceSansPro-Regular.ttf";
import font2 from "./font/SourceSansPro-Semibold.ttf";
import { Font } from "@react-pdf/renderer";

Font.register({ family: "SourceSansPro-medium", src: font });
Font.register({ family: "SourceSansPro-regular", src: font1 });
Font.register({ family: "SourceSansPro-semiBold", src: font2 });

const styles = StyleSheet.create({
  center: {
    display: "flex",
    flexDirection: "row",
    right: 20,
    fontSize: 20,
    paddingTop: 40,

    // paddingBottom: 30,
  },
  
});

// require useSelector from react-redux
function NewCustom(props) {
  // const d = useSelector(e => e.generateCV.message);
  let details;
  details = [props.details]
  
  const [ wrap_stat, setWrap_stat ] = useState(false);
  useEffect(() => {
    if (details[0]?.Experience.length >= 4) {
      setWrap_stat(true);
    } else {
      setWrap_stat(false);
    }

  }, [])

  let my_interest;
  if (details[0] !== null) {
    if (Array.isArray(details[0]?.Interest)) {
      my_interest = details[0]?.Interest;
    } else if (typeof details[0]?.Interest === "string") {
      my_interest = details[0]?.Interest.split(", ");
    }
  }
  if (Array.isArray(details[0]?.Technical_skills) === false ) {
    if (typeof details[0]?.Technical_skills === "string") {
      details[0].Technical_skills = details[0]?.Technical_skills.split(", ");
    }
   }

   if (Array.isArray(details[0]?.Personal_skills) === false ) {
    if (typeof details[0]?.Personal_skills === "string") {
      details[0].Personal_skills = details[0]?.Personal_skills.split(", ");
    }
  }

  const chunkSubstr = (str, size) => {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  };

  Font.registerHyphenationCallback((word) => { // to avoid breaking of words.
    if (word.length > 16) {
      return chunkSubstr(word, 14);
    } else {
      return [word];
    }
  });

  return (
    // <PDFViewer style={{width: '65vw', height: '100vh',}}>
      <Document>
        <Page size="A4" style={styles.center} wrap={true}>
        {details?.map((data, key) => (
          <View style={{backgroundColor: "#FF6633", borderRadius: "50px", left: "60px", paddingBottom: "5px", position: "absolute", top: 20, width: "94vw",  }}>
                <View style={{backgroundColor: "#F4F4F4", borderRadius: "50px", left: "-30px", top: "-10px",   width: "97vw", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "20px" }}>
                {/* Head */}
                <View>
                  <Text style={{ fontFamily: "SourceSansPro-semiBold", fontSize: 25, color: "#000000", }}>{data?.Fullname}</Text>
                </View>
                <View style={{top: 5}}>
                {data?.CvType !== null || data?.CvType !== undefined ? 
                  <Text style={{ fontFamily: "SourceSansPro-regular", fontSize: 15, color: "#000000", }}>{data?.CvType}</Text> : null
                }
                </View>
                <View style={{top: 5, display: "flex", flexDirection: "row", flexWrap: true}}>
                  <Text style={{marginRight: 5, fontFamily: "SourceSansPro-regular", fontSize: 15, color: "#000000", }}>{data?.Phone_number}</Text>
                  <Text style={{marginRight: 2, fontFamily: "SourceSansPro-regular", fontSize: 15, color: "#000000", }}>{data?.Email}</Text>
                </View>
                {/* Head end */}
  
                {/* main body start */}
                <View style={{display: "flex", flexDirection: "row", gap: 2, backgroundColor: "#FDEEE8", marginTop: "20px", borderBottomRightRadius: "50px"}}>
                  {/* left side start */}
                  <View style={{width: "50vw", backgroundColor: "#F4F4F4", paddingRight: 10 }}>
                    {/* Professional Summary starts */}
                    <View style={{ display: "flex", flexDirection: "row", flexWrap: true, marginBottom: 10 }}>
                      <View>
                      {
                        data?.Lang[0]?.lang === 'English' ?
                        <Text style={{marginBottom: "10px", fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>About</Text> :
                        <Text style={{marginBottom: "10px", fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>Sobre</Text> 
                      }
                        <Text style={{fontFamily: "SourceSansPro-regular", fontSize: 15,  }}>
                        {data?.Professional_summary}
                        </Text>
                      </View>
                    </View>
                    {/* Professional Summary ends */}
                    {/* Work Experience start */}
                    {data?.Experience?.length === 0 ? null:
                    <View>
                      {
                        data?.Lang[0]?.lang === 'English' ?
                          <Text style={{marginBottom: "10px", fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>Work Experience</Text> :

                          <Text style={{marginBottom: "10px", fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>Experiencia Laboral</Text>
                      }
                      {/* Expeirence body starts */}
                      {data?.Experience?.map((Ex_data, key) => (
                      <View key={key}>
                        <View style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", fontSize: 15, fontFamily: "SourceSansPro-regular", marginTop: 10}}>
                          <View>
                            <Text>{Ex_data?.My_job_title}</Text>
                          </View>
                          <View style={{display: "flex", flexDirection: "row",  width: 100}}>
                            <Text style={{fontFamily: "SourceSansPro-regular", fontSize: 15, }}>{Ex_data?.Work_start_date} -  </Text>
                                {Ex_data?.Work_end_date !== null ? <Text style={{fontFamily: "SourceSansPro-regular", fontSize: 15,}}>  {Ex_data?.Work_end_date}</Text> : <Text>{data.Lang[0].lang === "English" ? <Text style={{fontFamily: "SourceSansPro-regular", fontSize: 15,}}> Present</Text> : <Text style={{fontFamily: "SourceSansPro-regular", fontSize: 15,}}>Presente</Text>}</Text>}
                            </View>
                        </View>
  
                        <View style={{marginTop: 10}}>
                          <Text style={{marginBottom: 10, fontFamily: "SourceSansPro-semiBold", fontSize: 15, color: "#000000" }}>{Ex_data?.Where_i_worked}</Text>

                          {Array.isArray(Ex_data?.Day_to_day_work) === false ? 
                            <Text>{Ex_data?.Day_to_day_work}</Text> :
                            <View>
                              {Ex_data?.Day_to_day_work?.map((e_data, key) => (
                                <View style={{display: "flex", flexDirection: "row", gap: 3, fontFamily: "SourceSansPro-regular", fontSize: 15}}>
                                  <Text>•</Text>
                                  {e_data[0] === "-" ? 
                                    <Text style={{fontFamily: "SourceSansPro-regular", fontSize: 15,}}>{e_data.slice(1, e_data.length)}</Text> : <Text style={{fontFamily: "SourceSansPro-regular", fontSize: 15,}}>{e_data}</Text>
                                  }
                                </View>
                              ))}
                            </View>
                          }
                        </View>
                      </View>
                      ))}
  
                      {/* Experience body ends */}
                    </View>
                    }
                    {/* Work Experience ends */}
  
                    {/* Language start */}
                    { data?.Language?.length === 0 || data?.Language == null ? null : 
                    <View style={{marginTop: 10}}>
                      {
                        data?.Lang[0]?.lang === 'English' ?
                        <Text style={{marginBottom: 10, fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>Language</Text> :
                        <Text style={{marginBottom: 10, fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>Idiomas</Text>
                      }
                      {/* Language body start */}
                      {data?.Language?.map((L_data, key) => (
                        <View style={{display: "flex", flexDirection: "row", gap: 5, fontFamily: "SourceSansPro-regular", fontSize: 15}}>
                          <Text style={styles.bullet}>•</Text>
                            <View style={{display: 'flex', flexDirection: "row", alignItems: "center"}}>
                              <Text>{L_data.language} - </Text>
                              <Text>{L_data.rate}</Text>
                            </View>
                        </View>
                      ))}
                      {/* Language body ends */}
                    </View>
                    }
                    {/* Language ends */}
                  </View>
                  {/* left side end */}
  
                  {/* right side start */}
                  <View style={{width: "50vw", backgroundColor: "#F4F4F4", display: "block", borderBottomRightRadius: "50px" }}>
                    {/* Education start */}
                    {Array.isArray(data?.Education) === false? null :
                    <View style={{ paddingLeft: 10, paddingRight: 5,}}>
                      {
                          data?.Lang[0]?.lang === 'English' ?
                            <Text style={{marginBottom: 10, fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>Education</Text> : 
                            <Text style={{marginBottom: 10, fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>Educación</Text>
                      }
  
                    {/* Education body starts */}
                    {data?.Education?.map((Ed_data, key) => (
                      <View style={{marginTop: 5, marginBottom: 5}}>
                        <View style={{display: "flex", flexDirection: "row", gap: 5, fontFamily: "SourceSansPro-regular", fontSize: 15}}>
                          <Text>{Ed_data.Start_date} -  </Text>
                          {Ed_data.End_date !== null ? 
                            <Text>  
                              {Ed_data.End_date}
                            </Text> : <Text>{data?.Lang[0]?.lang === "English" ? <Text> 
                              Present
                            </Text> : <Text>
                              Presente
                            </Text>}
                            </Text>
                          }
                        </View>
                        <View style={{display: "flex", flexDirection: "row", flexWrap: true, gap: 5, fontFamily: "SourceSansPro-regular", fontSize: 15}}>
                        <Text>{Ed_data?.Course}</Text>
                        </View>
                        <View style={{display: "flex", flexDirection: "row", flexWrap: true, gap: 5, fontFamily: "SourceSansPro-regular", fontSize: 15}}>
                        <Text>{Ed_data?.School}</Text>
                        </View>
                      </View>
                    ))}
  
                    {/* Education body ends */}
                    </View>
                    }
                    {/* Education ends */}
                    <View style={{backgroundColor: "#FDEEE8", height: 2, marginTop: 5, marginBottom: 5}}></View>
                    {/* Skills start */}
                    {
                    data?.Technical_skills?.length === 0 && data?.Personal_skills?.length === 0 ? null :
                    <View style={{paddingTop: 10, paddingLeft: 10, paddingRight: 5, }}>
                      {
                        data?.Lang[0]?.lang === 'English' ?
                          <Text style={{marginBottom: 10, fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>Skills</Text> : 
                          <Text style={{marginBottom: 10, fontFamily: "SourceSansPro-semiBold", color:"#FF6633", fontSize: 20}}>Habilidades</Text>
                      }
  
                      {/* Technical skills starts */}
                      <View>
                      {
                      data?.Lang[0]?.lang === 'English' ?
                        <Text style={{marginBottom: 10, fontFamily: "SourceSansPro-semiBold", fontSize: 15, color: "#000000" }}>Technical Skills</Text> : 
                        <Text style={{marginBottom: 10, fontFamily: "SourceSansPro-semiBold", fontSize: 15, color: "#000000" }}>Habilidades Técnicas</Text>
                      }
                      { data?.Technical_skills?.map((T_data, key) => (
                        <View style={{display: "flex", flexDirection: "row", flexWrap: true, gap: 5, fontFamily: "SourceSansPro-regular", fontSize: 15}}>
                          {T_data.value !== undefined ? 
                          <Text>{T_data.value}{data?.Technical_skills.indexOf(T_data) !== data?.Technical_skills?.length ? <Text>, </Text> : null}</Text> : <Text>{T_data}{data?.Technical_skills.indexOf(T_data) !== data?.Technical_skills?.length ? <Text>, </Text> : null}</Text> 
                          }
                        </View>
                      ))}
                      </View>
                      {/* Technical skills ends */}
                    </View>
                    }
                    {/* Skills ends */}
                  </View>
                  {/* right side ends */}
                </View>
                {/* main body ends */}
              </View>
            </View>
          ))}
        </Page>
      </Document>
    // </PDFViewer>
  );
}

export default NewCustom;