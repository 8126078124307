import EmployerWorkerViewProfile from "../../common/edit_details/employer_workerview_profile";
import { useSelector } from "react-redux";

function EmployerFullHead({ handleClickHire }) {
  const publicInfo = useSelector((e) => e.public);
  const pub = publicInfo.message?.profile;

  return (
    <>
      <div class="w-full p-4 md:p-8 lg:p-12 gap-9 rounded-xl bg-[#5843BD]">
        <div className="flex gap-x-8 flex-col lg:flex-row  items-center lg:items-center gap-y-4 justify-between">
          <div className="w-60 md:w-[60%] md:max-w-[280px] lg:max-w-[305px] relative rounded-[16px]  cursor-pointer">
            <img
              src={
                pub?.photo !== undefined
                  ? pub?.photo
                  : userInfor?.profile_pics !== "" &&
                    userInfor?.profile_pics !== undefined
                  ? userInfor?.profile_pics
                  : refrence.profilephoto1
              }
              alt="employee profile picture"
              className="aspect-[1/1] object-cover border-4 border-solid border-white w-full rounded-[16px]"
            />
          </div>
          {/* section */}
          <div className="w-full flex justify-between flex-col gap-y-6 lg:gap-y-8">
            <div className="flex flex-col gap-y-4 md:flex-row justify-between">
              <div className="flex flex-col gap-y-4">
                <p class=" text-base font-normal leading-normal text-left text-[#FEFEFE80]">
                  Personal Details
                </p>
                <div>
                  <p class=" text-xl font-semibold capitalize leading-normal text-left text-[#FEFEFE]">
                    {pub?.Fullname.toLowerCase()}
                  </p>
                  <p class=" text-base font-medium capitalize leading-normal text-left text-[#FEFEFE]">
                    {pub?.Job_type.toLowerCase()}
                  </p>
                  {/* {showMoreJobInterest && (
                    <div >
                      <ul className="list-none">
                        {profile.message?.JobInterests.map((e) => (
                          <>{
                            e.value !== profile.message?.JobInterests[0].value ? (
                              <li className="text-base font-medium leading-normal text-left text-[#FEFEFE] py-2 cursor-pointer">{e.value}</li>
                            ) : null
                          }</>
                        ))}
                      </ul>
                    </div>
                  )}
                  {
                    profile.message?.JobInterests.length > 1 ? (
                      <p class="text-sm mt-1 italic font-normal leading-normal text-left text-[#FFFFFFB2] cursor-pointer" onClick={() => setShowMoreJobInterest(!showMoreJobInterest)}>
                        {showMoreJobInterest ? "(See less)" : `(See ${profile.message?.JobInterests.length - 1} other jobs)`}
                      </p>
                    ) : null
                  } */}
                </div>

                {/* <div className="flex gap-x-2 items-center">
                <div>
                  <RatingSvg />
                  <RatingSvg />
                  <RatingSvg />
                  <RatingSvg />
                  <RatingSvg />
                </div>
                <p class=" text-sm font-medium leading-normal text-[#FEFEFE]">
                  5.0
                </p>

                <p class="text-sm font-normal text-left text-white">
                  (See all 25 reviews)
                </p>
              </div> */}
              </div>
            </div>

            {/* location and experience */}

            <div className="flex justify-between xl:w-[60%] md:items-center flex-col md:flex-row ">
              <div className="flex flex-col">
                <p class=" text-base font-normal leading-normal text-left capitalize text-[#FEFEFE80]">Location</p>
                <p class=" text-base font-medium leading-6 text-left capitalize text-[#FEFEFE]">{pub?.Address.toLowerCase()}</p>
              </div>

              <div className="flex flex-col">
                <p class=" text-base font-normal leading-normal text-left text-[#FEFEFE80]">Work Experience</p>
                {pub?.Years_of_experience !== undefined ? (<p class=" text-base font-medium leading-6 text-left text-[#FEFEFE]">{pub?.Years_of_experience}+ years Experience</p>) : null}

              </div>
            </div>

            {/* create resume and share profile button */}
            <div className="flex justify-between gap-y-4 flex-col md:flex-row items-center">
              <button class="text-base w-full md:w-max font-semibold leading-normal text-center text-white px-5 py-3 gap-3 rounded-lg bg-[#FF6633] shadow-lg" onClick={handleClickHire}>
                Hire me
              </button>

              <button class="text-base w-full md:w-max font-semibold leading-normal text-center text-white px-5 py-3 gap-3 rounded-lg border border-white shadow-lg"
                onClick={handleClickHire}>
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployerFullHead;
