import { Helmet } from "react-helmet-async";

export default function HelmetProvide(props) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta
        name="description"
        content={props.description}
      />
      <meta property="og:title" content={props.title} notranslate />
      <meta
        property="og:description"
        content={props.description}
      />
      <meta
        property="og:image"
        content={props.image | "https://res.cloudinary.com/daudj5isi/image/upload/v1696694006/images/Moil%20Company.png"}
      />
    </Helmet>
  );
}
