import React, { useState, useEffect, useContext } from "react";
import TopBar from "../topbar/Top-Bar";
import SideBar from "../sidebar/Side-Bar";
import PreviewSection from "../common/preview_components/preview_section";
import PersonalInfo from "../common/preview_components/personal";
import EducationSection from "../common/preview_components/education";
import WorkExperience from "../common/preview_components/work_experience";
import PreviewButton from "../common/preview_components/preview_button";
import Volunteer from "../common/preview_components/volunteer";
import Award from "../common/preview_components/award";
import Reference from "../common/preview_components/reference";
import JobType from "../common/preview_components/job_type";
import { useSelector, useDispatch } from "react-redux";
import App1 from "./pdf/DownloadableRresume";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import BackButton from "../common/back_button";
import HelmetProvide from "../common/helmet";
import {
  AdjustGPTResponse,
  AdjustExperienceDate,
  arrangeEducationDate,
} from "./pdf/Adjust_gptRes";
import { StyleSheet } from "@react-pdf/renderer";
import {
  saveCvDetails,
  getProfile,
  spanish_loading,
  lang_type,
  lastResme,
  generate_my_cv,
  dispatch_cv_id,
  SiteErrorLoading,
  ClickLoading,
  returnHome,
  resume_step_jump_function
} from "../../Redux/ActionCreator";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  convert,
} from "../../Redux/convertResume";
import LoaderModal from "../common/LoaderModal";
import { getAllResumeDetailsInCache, saveIntoServerCache } from "../../utils/saveIntoCache";
import { useAnalytics } from "../../context/AnalyticsContext";
import { baseURL1 } from "../../Redux/baseURL";

export default function UserPreviewDetails() {
  const analytics = useAnalytics();
  const [showDoneModal, setShowDoneModal] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getMyCv = useSelector((prev) => prev.saved_cv);
  const type = useSelector((prev) => prev.type);
  const details = getMyCv.message;
  const [stat, setStat] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [saveBtn, setSaveBtn] = useState("");
  const [showEditBtn, setShowEditBtn] = useState(false);

  const postError = useSelector(e => e.errors.errMess);
  
  let [ profileCreation, setProfileCreation ] = useState(false);
  // paths
  let jobName = new URLSearchParams(window.location.search).get("jobName");
  let secondArg = new URLSearchParams(window.location.search).get("pr");
  let thirdArg = new URLSearchParams(window.location.search).get("index");
  let msgReceiver = new URLSearchParams(window.location.search).get("p");
  let msgIndex = new URLSearchParams(window.location.search).get("i");

  // get the id parameter if any
  const idParams = new URLSearchParams(window.location.search).get("id");
  const param = useParams();
  const update_status = new URLSearchParams(window.location.search).get("stat");
  // state to determine the if the link button should be displayed or not
  const [display, setDisplay] = useState(false);
  // initialize useSelector hook
  const saved_cv_details = useSelector((prev) => prev.saved_cv);
  const current_details = useSelector((details) => details.generateCV);
  const ex_cv = useSelector((details) => details.existingCV);
  const sp = useSelector((s) => s.spanish);
  const profile = useSelector((e) => e.login);
  const userInfo = useSelector((e) => e.userInfo);
  let log_stat = profile.profile_status.profile_status; // login status
  let AcctType = profile.profile_status.AcctType;
  const cv_id = useSelector((prev) => prev.cvId);
  let langType = useSelector(e => e.type);
  const [text, setText] = useState(langType.type);
  const [a, setA] = useState(false);
  const employee = useSelector(e => e.profile);
  let id;
  if (idParams === null) {
    id = cv_id.id;
  } else {
    id = idParams;
  }

  let cv_details;
  if (current_details.message === null) {
    let filterById = [];
    if (
      saved_cv_details.message !== null &&
      saved_cv_details.message !== undefined
    ) {
      saved_cv_details.message.forEach((prev) => {
        if (prev._id === param.cvID) {
          console.log("prev", prev);
          filterById.push(prev);
        }
      });
      console.log("filterById", filterById);
      cv_details = { message: filterById[0] };
    }
  } else {
    cv_details = { message: current_details.message };
  }
  const clickStat = useSelector(e => e.click);
  useEffect(() => {
    // let get the last resume aws_link and download it
    let lastCv = saved_cv_details?.message[saved_cv_details?.message.length - 1];
    let lastCvId = lastCv?.pdfId;
    console.log("Last cv Id", lastCvId);
    if (clickStat.message == true) {
      if (parseInt(lastCvId, 10) < 5 && lastCv?.aws_link !== undefined) {
        // then perform the download here.
        downloadFile(lastCv);
      } else {
        setProfileCreation(false);
        dispatch(ClickLoading(true));
      }
    }

  }, [saved_cv_details.message]);


//  useEffect(() => {
//     if (window.location.pathname.includes("resume")) {
//       if (saved_cv_details.message !== null) {
//         let resumeId = window.location.pathname.split("/")[2];
//         cv_details = saved_cv_details?.message.filter(e => e._id == resumeId);
//         console.log("Resume details ", cv_details);
//         dispatch(dispatch_cv_id(parseInt(cv_details?.pdfId, 10)));
//       }
//     }
//   }, [window.location.pathname]); 

  const [ downloadStat, setDownloadStat ] = useState(false);
  useEffect(() => {
    if (downloadStat == true) {
      setProfileCreation(true);
      setDownloadStat(false);
    }
  }, [current_details.message]);

  const downloadFile = async (lastCv) => {
      const fileUrl = lastCv?.aws_link;
      let link = document.createElement('a');
      link.href = fileUrl;
      link.download = lastCv?.CvType;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setProfileCreation(false);
      dispatch(ClickLoading(true));
  }

  useEffect(() => {
    if (postError !== null) {
      if (profile.message !== null) {
        setClicked(true);
      } else {
        setClicked(false);
      }
      setProfileCreation(false);
      setStat(false);
      // window.location.reload(); // reload the page here
      setTimeout(() => {
        toast.error(
          postError + "Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }, 50);
      dispatch(SiteErrorLoading(true));
    }
  }, [employee.message, postError]);

    useEffect(() => {
      saveIntoServerCache("step", 0);
    if (saved_cv_details?.message.length == 0 && current_details?.message == null) {
      let resumeDetails = getAllResumeDetailsInCache("personalInfo", "education", "experience", "technicalSkills", "personalSkills", "language", "award", "reference", "YOF", "title", "summary" );
      resumeDetails.then(res => res.json())
      .then(res => {
        if (res.success === true) {
          if (res.data !== null) {
            let resObj = res.data;
          console.log("respObj summ", resObj);
          let p = JSON.parse(resObj.personal);
          let ed = JSON.parse(resObj.Education);
          let Act = JSON.parse(resObj.Experience);
          let lang = JSON.parse(resObj.Language);
          let sk = JSON.parse(resObj.Technical_skills);
          let pk = JSON.parse(resObj.Personal_skills);
          let Aw = JSON.parse(resObj.Award);
          let ref = JSON.parse(resObj.Reference);
          let yof = JSON.parse(resObj.YOF);
          let title = JSON.parse(resObj.Title);
          let summary = JSON.parse(resObj.Summary);
          
          if (p == undefined || p == null && AcctType == "Official") {
            window.location.assign(baseURL1 + "/employer/dashboard");
          }

          dispatch(dispatch_cv_id(p?.pdfId));
            const credentials = {
              Fullname:
                p?.Firstname +
                " " +
                p?.LastName,
              Email: p?.Email,
              Phone_number: p?.Phone_number,
              Address: p?.Address,
              Professional_summary: summary,
              Linkedin: p?.Linkedin,
              Website: p?.Website,
              Education: ed,
              Experience: Act,
              Technical_skills: sk, //data.get('technical_skills'),
              Personal_skills: pk, //data.get('personal_skills'),
              // Volunteer: voluteer.voluntary,
              Reference: ref || [],
              Language: lang, //data.get('language'),
              CvType: title,
              Lang: ["English"],
              Award: Aw,
              year_of_experience: yof,
            };
            dispatch(generate_my_cv(credentials));
          } else {
            navigate("/employer/dashboard");
          }
        } else if (res.success === false && res.message == "Unauthorized") {
          dispatch(returnHome(res.status));
        }
      })
    } else {
      if (cv_details.message == undefined) {
        navigate("/employer/dashboard");
      }
    }
  }, [])

 if (cv_details.message !== null && cv_details.message !== undefined) {
  console.log("cv_details ---", cv_details.message)
   // editing start
  cv_details.message = AdjustGPTResponse(cv_details.message, id); // edit possible error from gpt

  // education date arrangement

  cv_details.message.Education = arrangeEducationDate(
    cv_details.message.Education
  );

  cv_details.message.Experience = AdjustExperienceDate(
    cv_details.message.Experience
  );
 }

  /*   const yourSecret = localStorage.getItem("secret");
  // Retrieve the "payment_intent_client_secret" query parameter appended to
  // your return_url by Stripe.js
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  ); */

  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (update_status === "cannot update") {
      setShowEditBtn(true);
    }
  }, []);
  console.log("showEditBtn", showEditBtn);

  useEffect(() => {
    if (type.type === "Spanish") {
      setText("English");
      dispatch(lang_type("English"));
    } else if (type.type === "English") {
      setText("Spanish");
      dispatch(lang_type("Spanish"));
    }
  }, []);

  const handleClick = () => {
    if ((update_status !== null && update_status == "cannot update") || AcctType == "Official") {
      let lastCv = saved_cv_details?.message[saved_cv_details?.message.length - 1];
      let lastCvId = lastCv?.pdfId;
       if (parseInt(lastCvId, 10) < 5 && lastCv?.aws_link !== undefined) {
        // then perform the download here.
        downloadFile(lastCv);
      }
    }

  };
  
  const handleProfileView = () => {
    if (jobName !== null && secondArg !== null && thirdArg !== null) {
      navigate(`/employer/jobs/${jobName}/candidates/${secondArg}/${thirdArg}`);
    } 
    if (msgReceiver !== null && msgIndex !== null) {
      navigate(`/employer/inbox/${msgReceiver}?i=${msgIndex}`)
    }
  }

  const hideDoneModal = () => {
    setShowDoneModal(false);
  };

  const handleSpanish = async (arg) => {
    dispatch(spanish_loading(true));
    setStat(true);
    console.log("texttt", text);
    let lan;
    if (text === "English") {
      lan = "Latin American Spanish";
      setText("Spanish");
    } else if (text === "Spanish") {
      lan = "English";
      setText("English");
    }
    const final_arg = { ...arg, Lang: [{ lang: lan }] };
    let flag = "resume";
    setDownloadStat(true);
    dispatch(convert(final_arg, flag, "none"));
  };


  console.log("Text", text);
  const styles = StyleSheet.create({
    viewer: {
      marginTop: 10,
      width: "65vw",
      height: "100vh",
      "@media max-width: 700": {
        width: "55vw",
        height: "60vh",
      },
    },
  });

  console.log("Update status", update_status);

  const handleJumpToPage = (pageIndex) => {
    if (update_status == "cannot update") {
      setTimeout(() => {
        toast.error(
          "You cannot update an existing resume. Kindly create another one.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }, 50);
    }
  };

  const handleBack = () => {
    if (update_status == "cannot update") {
      navigate(`/user/profile/:${userInfo.user.profile_id}`);
    }
  }

  if (cv_details.message !== null && cv_details.message !== undefined) {
    return (
<>
      <HelmetProvide
        title="Moil | Preview Your Resume"
        description="Preview your professional resume created with Moil's easy-to-use resume creation form. Showcase your skills, experience, and qualifications to potential employers."
      />

      <div className="grid grid-cols-auto md:grid-cols-smdash cont:grid-cols-dash grid-rows-dash">
        <TopBar topBarShowMenu={true} />
        <SideBar />
        {cv_details.message !== null ? (
          <div className="pl-3 flex justify-center overflow-y-scroll h-calc-70">
            <div className="max-w-[800px] xtraxl:max-w-calculate pl-3 flex flex-col gap-12">
              <div className="flex-col justify-start items-start inline-flex pl-3 mt-2">
                {profile.profile_status.AcctType === "Official" ? <BackButton className="pl-[-4px]" onClick={() => handleProfileView()} /> : <BackButton className="pl-[-10px]" onClick={() => handleBack()} />}
                <p className="PreviewYourDetails text-stone-950 text-[28px] font-semibold">
                  {showEditBtn ? "Candidate Resume" : "Preview your details" }
                </p>
                <p className="text-orange-500 text-base font-normal">
                  Preview the information and click on the edit button to make changes to a section.
                </p>
              </div>
              <div className="border-t border-black border-opacity-20 py-8 flex flex-col gap-y-6">
                <div className="max-[768px]:w-full max-[768px]:px-3 md:px-6 py-6 bg-slate-50 rounded-[10px] flex-col justify-start items-start gap-6 inline-flex">
                  <PreviewSection
                    header={showEditBtn ? "Preview candidate resume details" : "Personal Information"}
                    stat={showEditBtn}
                    handleJumpToPage={() => handleJumpToPage(0)}
                  />
                  <div className="max-[768px]:w-full flex flex-col md:flex-row gap-x-8 gap-y-4 md:gap-y-6 flex-wrap">
                    <PersonalInfo
                      field="Full name:"
                      value={cv_details.message?.Fullname}
                    />
                    <div className="w-full md:w-[1px] max-[768px]:h-[1px] md:h-auto bg-indigo-400 bg-opacity-35"></div>
                    <PersonalInfo
                      field="Email address:"
                      value={cv_details.message?.Email}
                    />
                    <div className="w-full md:w-[1px] max-[768px]:h-[1px] md:h-auto bg-indigo-400 bg-opacity-35"></div>
                    <PersonalInfo
                      field="Phone number:"
                      value={cv_details.message?.Phone_number}
                    />
                  </div>
                </div>
                <div className="max-[768px]:w-full max-[768px]:px-3 md:px-6 py-6 rounded-[10px] flex-col justify-start items-start gap-6 inline-flex">
                  <PreviewSection
                    header="Professional Summary"
                    stat={showEditBtn}
                    handleJumpToPage={() => handleJumpToPage(4)}
                  />
                  <div className="text-justify text-black text-opacity-90 text-xs font-normal">
                    {cv_details.message?.Professional_summary}
                  </div>
                </div>
                <div className="max-[768px]:w-full max-[768px]:px-3 md:px-6 py-6 rounded-[10px] flex-col justify-start items-start gap-6 inline-flex">
                  <PreviewSection
                    header="Education"
                    stat={showEditBtn}
                    handleJumpToPage={() => handleJumpToPage(1)}
                  />
                  <div className="max-[768px]:w-full flex flex-col md:flex-row gap-x-8 gap-y-4 md:gap-y-6 flex-wrap">
                    {Array.isArray(cv_details.message?.Education) === true ? (
                      <>
                        {cv_details.message?.Education?.map((data, key) => (
                          <>
                            <EducationSection
                              course={data?.Course}
                              institution={data?.School}
                              endYear={data?.End_date}
                              key={key}
                            />
                            <div className="w-full md:w-[1px] max-[768px]:h-[1px] md:h-auto bg-indigo-400 bg-opacity-35"></div>
                          </>
                        ))}
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="max-[768px]:w-full max-[768px]:px-3 md:px-6 py-6 rounded-[10px] flex-col justify-start items-start gap-6 inline-flex">
                  <PreviewSection
                    header="Job Type"
                    stat={showEditBtn}
                    handleJumpToPage={() => handleJumpToPage(4)}
                  />
                  <div className="max-[768px]:w-full flex flex-col md:flex-row gap-x-8 gap-y-4 md:gap-y-6 flex-wrap">
                    <JobType
                      jobType={cv_details.message?.CvType}
                      experience={cv_details.message?.year_of_experience}
                    />
                  </div>
                  <div className="w-full md:w-[1px] max-[768px]:h-[1px] md:h-auto bg-indigo-400 bg-opacity-35"></div>
                </div>
                <div className="max-[768px]:w-full max-[768px]:px-3 md:px-6 py-6 bg-slate-50 rounded-[10px] flex-col justify-start items-start gap-6 inline-flex">
                  <PreviewSection
                    header="Work Experience"
                    stat={showEditBtn}
                    handleJumpToPage={() => handleJumpToPage(2)}
                  />
                  <div className="max-[768px]:w-full flex flex-col md:flex-row gap-x-8 gap-y-4 md:gap-y-6 flex-wrap">
                    {Array.isArray(cv_details.message?.Experience) === true ? (
                      <>
                        {cv_details.message?.Experience?.map((data, key) => (
                          <>
                            <WorkExperience
                              institution={data?.Where_i_worked}
                              jobTitle={data?.My_job_title}
                              startDate={data?.Work_start_date}
                              endDate={data?.Work_end_date}
                              roles={[...data?.Day_to_day_work]}
                            />
                            <div className="w-full md:w-[1px] max-[768px]:h-[1px] md:h-auto bg-indigo-400 bg-opacity-35"></div>
                          </>
                        ))}
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="max-[768px]:w-full max-[768px]:px-3 md:px-6 py-6 rounded-[10px] flex-col justify-start items-start gap-6 inline-flex">
                  <PreviewSection
                    header="Skills & Licensing"
                    stat={showEditBtn}
                    handleJumpToPage={() => handleJumpToPage(3)}
                  />
                  <div className="max-[768px]:w-full flex md:flex-row gap-x-3 gap-y-4 md:gap-y-6 flex-wrap">
                    {Array.isArray(cv_details.message?.Technical_skills) ===
                    true ? (
                      <>
                        {cv_details.message?.Technical_skills?.map(
                          (data, key) => (
                            <>
                              {data?.value ? (
                                <PreviewButton text={data?.value} key={key} />
                              ) : (
                                <PreviewButton text={data} key={key} />
                              )}
                            </>
                          )
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="max-[768px]:w-full flex flex-col gap-x-3 gap-y-4 md:gap-y-6">
                    <p className="text-stone-500 text-base font-semibold">
                      Soft Skills
                    </p>
                    <div className="flex md:flex-row gap-x-3 gap-y-4 md:gap-y-6 flex-wrap">
                      {Array.isArray(cv_details.message?.Personal_skills) ===
                        true && cv_details.message?.Personal_skills !== null ? (
                        <>
                          {cv_details.message?.Personal_skills?.map(
                            (data, key) => (
                              <>
                                {data?.value ? (
                                  <PreviewButton text={data?.value} key={key} />
                                ) : (
                                  <PreviewButton text={data} key={key} />
                                )}
                              </>
                            )
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                {(cv_details.message?.Volunteer === null || cv_details.message?.Volunteer?.length === 0 || cv_details.message?.Volunteer === undefined) && AcctType === "Official" ? null : 
                  <div className="max-[768px]:w-full max-[768px]:px-3 md:px-6 py-6 rounded-[10px] flex-col justify-start items-start gap-6 inline-flex">
                    <PreviewSection
                      header="Volunteer Experience"
                      stat={showEditBtn}
                      handleJumpToPage={() => handleJumpToPage(6)}
                    />
                    <div className="max-[768px]:w-full flex flex-col md:flex-row gap-x-8 gap-y-4 md:gap-y-6 flex-wrap">
                      {cv_details.message?.Volunteer?.map((data, key) => (
                        <>
                          <Volunteer
                            // jobTitle="Logistics Coordinator"
                            institution={data?.Volunteer_Org}
                            year={data?.Volunteer_join_date}
                          />
                          <div className="w-full md:w-[1px] max-[768px]:h-[1px] md:h-auto bg-indigo-400 bg-opacity-35"></div>
                        </>
                      ))}                      
                    </div>
                  </div>
                }
                {(cv_details.message?.Award === null || cv_details.message?.Award?.length === 0 || cv_details.message?.Award === undefined) && AcctType === "Official" ? null : 
                <div className="max-[768px]:w-full max-[768px]:px-3 md:px-6 py-6 rounded-[10px] flex-col justify-start items-start gap-6 inline-flex">
                  <PreviewSection
                    header="Award and Honors"
                    stat={showEditBtn}
                    handleJumpToPage={() => handleJumpToPage(7)}
                  />
                  <div className="max-[768px]:w-full flex flex-col md:flex-row gap-x-8 gap-y-4 md:gap-y-6 flex-wrap">
                    {Array.isArray(cv_details.message?.Award) === true ? (
                      <>
                        {cv_details.message?.Award?.map((data, key) => (
                          <>
                            <Award
                              awardFrom={data?.Award_giver}
                              awardName={data?.Award_name}
                              // awardTitle="Most Versatile Contributor"
                              year={data?.Award_year}
                            />
                            <div className="w-full md:w-[1px] max-[768px]:h-[1px] md:h-auto bg-indigo-400 bg-opacity-35"></div>
                          </>
                        ))}
                      </>
                    ) : null}
                  </div>
                </div>
                }
                {(cv_details.message?.Reference === null || cv_details.message?.Reference?.length === 0 || cv_details.message?.Reference === undefined) && AcctType === "Official" ? null : 
                <div className="max-[768px]:w-full max-[768px]:px-3 md:px-6 py-6 rounded-[10px] flex-col justify-start items-start gap-6 inline-flex">
                  <PreviewSection
                    header="Reference"
                    stat={showEditBtn}
                    handleJumpToPage={() => handleJumpToPage(5)}
                  />
                  <div className="max-[768px]:w-full flex flex-col md:flex-row gap-x-8 gap-y-4 md:gap-y-6 flex-wrap">
                    {cv_details.message?.Reference?.map((data, key) => (
                      <>
                      <Reference
                        refreeName={data?.Reference_person_name}
                        refreeRelation={data?.How_do_i_know_this_person}
                        refreeInstititution={data?.Referencee_job_title}
                        refreePhone={data?.Reference_phone_number}
                        refreeEmail={data?.Reference_email}
                      />
                      <div className="w-full md:w-[1px] max-[768px]:h-[1px] md:h-auto bg-indigo-400 bg-opacity-35"></div>
                      </>
                    ))}
                  </div>
                </div>
                }
                <ul  className="text-justify text-orange-500 text-xs sm:text-base font-semibold list-disc vsm:ml-[30px] md:ml-[40px]">
                  <li>save resume in current language.</li>
                  <li>Translate & save resume in English/Spanish.</li>
                  {/* <li>Save again.</li> */}
                  <li>View my profile.</li>
                </ul>
                <div className="justify-even items-start gap-8 flex flex-wrap vsm:mx-4 md:px-6">
                  {
                    id >= 5 ?                   
                    <App1
                      details={cv_details.message}
                      onClick={handleClick}
                      id={`${id}`}
                      text="Save resume"
                     /*  {saveBtn} */
                    /> : 
                     <button
                      className="px-5 py-3 rounded-lg shadow border bg-[#5843BD] justify-center items-start lg:flex sm:max-w-[300px] w-full"
                      onClick={() => handleClick()}
                    >
                      {/* <span className="text-center text-white text-xs sm:text-base font-semibold">1.</span> */}
                      <span className="text-center text-white text-xs sm:text-base font-semibold">
                        Save resume
                      </span>
                    </button>
                  }
                  {/* <button
                    className="px-5 py-3 rounded-lg shadow border border-orange-500 bg-[#FCEDE8] justify-center items-center flex sm:max-w-[300px] w-full"
                    onClick={() => handleSpanish(cv_details.message)}
                  >
                    
                    <span className="text-center text-orange-500 text-xs sm:text-base font-semibold">
                      {text === "English" ? (
                        <p>Translate Resume to Spanish</p>
                      ) : (
                        <p>Translate Resume to English</p>
                      )}
                    </span>
                  </button> */}
                  {
                    profile.profile_status.AcctType === "Client" ? 
                    <button
                    className="px-5 py-3 rounded-lg shadow border border-orange-500 justify-center items-start lg:flex sm:max-w-[300px] w-full"
                    onClick={() => handleProfileView()}
                  >
                    {/* <span className="text-center text-orange-500 text-xs sm:text-base font-semibold">3.</span> */}
                    <span className="text-center text-orange-500 text-xs sm:text-base font-semibold">
                      Go to profile
                    </span>
                  </button> : null
                  }
                </div>
                <ToastContainer />
                {stat === true ? (
                  sp.spanish !== null ? (
                    <>{() => (setStat(false), setA(!a))}</>
                  ) : (
                    <LoaderModal text={`Translating resume into ${text}`} />
                  )
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div>{language["novalyet"]}</div>
        )}
      </div>
    </>
    )
  } else {
    return (<></>)
  }
}
