export default function PreviewSection(props) {

  return (
    <div className="flex gap-4 w-full justify-between md:justify-start">
      <p className="PersonalInformation text-orange-500 text-base font-semibold">
        {props.header}
      </p>
      
      { props.stat === true ? null : 
        <>
        <button onClick={props.handleJumpToPage}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hidden md:block"
          >
            <path
              d="M19 10.0058C18.7348 10.0058 18.4804 10.1112 18.2929 10.2987C18.1054 10.4862 18 10.7406 18 11.0058V17.0058C18 17.271 17.8946 17.5254 17.7071 17.7129C17.5196 17.9004 17.2652 18.0058 17 18.0058H3C2.73478 18.0058 2.48043 17.9004 2.29289 17.7129C2.10536 17.5254 2 17.271 2 17.0058V3.0058C2 2.74058 2.10536 2.48623 2.29289 2.29869C2.48043 2.11116 2.73478 2.0058 3 2.0058H9C9.26522 2.0058 9.51957 1.90044 9.70711 1.71291C9.89464 1.52537 10 1.27101 10 1.0058C10 0.740582 9.89464 0.486228 9.70711 0.298692C9.51957 0.111155 9.26522 0.00579834 9 0.00579834H3C2.20435 0.00579834 1.44129 0.321869 0.87868 0.884478C0.316071 1.44709 0 2.21015 0 3.0058V17.0058C0 17.8014 0.316071 18.5645 0.87868 19.1271C1.44129 19.6897 2.20435 20.0058 3 20.0058H17C17.7956 20.0058 18.5587 19.6897 19.1213 19.1271C19.6839 18.5645 20 17.8014 20 17.0058V11.0058C20 10.7406 19.8946 10.4862 19.7071 10.2987C19.5196 10.1112 19.2652 10.0058 19 10.0058ZM4 10.7658V15.0058C4 15.271 4.10536 15.5254 4.29289 15.7129C4.48043 15.9004 4.73478 16.0058 5 16.0058H9.24C9.37161 16.0066 9.50207 15.9813 9.62391 15.9316C9.74574 15.8818 9.85656 15.8085 9.95 15.7158L16.87 8.7858L19.71 6.0058C19.8037 5.91284 19.8781 5.80223 19.9289 5.68038C19.9797 5.55852 20.0058 5.42781 20.0058 5.2958C20.0058 5.16379 19.9797 5.03308 19.9289 4.91122C19.8781 4.78936 19.8037 4.67876 19.71 4.5858L15.47 0.295798C15.377 0.20207 15.2664 0.127676 15.1446 0.0769069C15.0227 0.0261382 14.892 0 14.76 0C14.628 0 14.4973 0.0261382 14.3754 0.0769069C14.2536 0.127676 14.143 0.20207 14.05 0.295798L11.23 3.1258L4.29 10.0558C4.19732 10.1492 4.12399 10.2601 4.07423 10.3819C4.02446 10.5037 3.99924 10.6342 4 10.7658ZM14.76 2.4158L17.59 5.2458L16.17 6.6658L13.34 3.8358L14.76 2.4158ZM6 11.1758L11.93 5.2458L14.76 8.0758L8.83 14.0058H6V11.1758Z"
              fill="#FF6633"
            />
          </svg>
        </button>
        <button
        className="w-10 h-5 py-3 bg-orange-500 rounded-[5px] shadow justify-center items-center gap-1.5 inline-flex md:hidden"
        onClick={props.handleJumpToPage}
      >
        <span className="text-center text-white text-xs font-normal">Edit</span>
      </button>
        </>
    }
    </div>
  );
}