import { useNavigate } from "react-router-dom";

export default function DecisionCard(props) {
  const navigate = useNavigate();

  const handleDecisionCardView = () => {
    if (props.acctType === "Official") {
      navigate(
        `/employer/inbox/${props.params}/decisionCard?index=${props.index}&view=true`
      );
    }
  };

  return (
    <>
      {props.offerText === "Offer Accepted." ? (
        <div className="max-w-[270px] p-1 bg-[#FAF2F4] rounded border border-orange-500 justify-center items-center gap-2 inline-flex">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.274 6.71967L7.27398 0.719674C7.03398 0.479674 6.70065 0.333008 6.33398 0.333008H1.66732C0.933984 0.333008 0.333984 0.933008 0.333984 1.66634V6.33301C0.333984 6.69967 0.480651 7.03301 0.727318 7.27967L6.72732 13.2797C6.96732 13.5197 7.30065 13.6663 7.66732 13.6663C8.03398 13.6663 8.36732 13.5197 8.60732 13.273L13.274 8.60634C13.5207 8.36634 13.6673 8.03301 13.6673 7.66634C13.6673 7.29967 13.514 6.95967 13.274 6.71967ZM2.66732 3.66634C2.11398 3.66634 1.66732 3.21967 1.66732 2.66634C1.66732 2.11301 2.11398 1.66634 2.66732 1.66634C3.22065 1.66634 3.66732 2.11301 3.66732 2.66634C3.66732 3.21967 3.22065 3.66634 2.66732 3.66634Z"
              fill="#FF6633"
            />
          </svg>
          <div className="flex-col justify-center items-start gap-1 inline-flex">
            <div className="text-[#1b1b1b] text-xs font-normal font-['Poppins'] leading-[18px]">
              {props.cardText}
            </div>
          </div>
        </div>
      ) : (
        <div className="pl-4 w-full max-w-[460px] sm:max-w-full pr-4 py-2 justify-start bg-rose-100 rounded-lg items-center gap-3.5 inline-flex relative">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0013 0.667969C6.6413 0.667969 0.667969 6.6413 0.667969 14.0013C0.667969 21.3613 6.6413 27.3346 14.0013 27.3346C21.3613 27.3346 27.3346 21.3613 27.3346 14.0013C27.3346 6.6413 21.3613 0.667969 14.0013 0.667969ZM15.3346 20.668H12.668V12.668H15.3346V20.668ZM15.3346 10.0013H12.668V7.33464H15.3346V10.0013Z" fill="#FF6633" />
          </svg>

          <div className="grow shrink basis-0 justify-between flex-col sm:flex-row items-center gap-2 flex">
            <div className="w-full">
              <p className="text-[#1b1b1b] text-base font-semibold font-['Poppins'] leading-tight">
                {props.offerText}
              </p>
              <p className="text-[#1b1b1b] text-xs sm:text-sm font-normal font-['Poppins'] leading-[21px]">
                {props.cardText}
              </p>
            </div>
            <button
              className="w-full max-w-[120px] self-start sm:self-center text-white text-sm sm:text-base font-medium  px-3 py-2.5 bg-[#FF6633] rounded-lg shadow"
              onClick={handleDecisionCardView}
            >
              View Card
            </button>
          </div>
        </div>
      )}
    </>
  );
}
