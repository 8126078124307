import UserAvatar from "../avatar/avatar";
import { useNavigate } from "react-router-dom";

export default function JobPostResponsiveCandidates(props) {
  const navigate = useNavigate();
  const handleApplicationView = () => {
    if (props.status === "INTERVIEW" || props.status === "HIRED") {
      navigate("/employer/inbox");
    } else {
      navigate(
        `/employer/jobs/${props.jobId}/candidates/${props.profileId}/${props.dataIndex}`
      )
    }
  }
  return (
    <div
      className={`md:hidden p-4 border border-[#D9D9D9] rounded-[0.5rem] bg-white flex-col justify-center items-start gap-6 inline-flex`}
    >
      <div className="w-full justify-between items-center inline-flex">
        <div className="flex gap-x-2 h-full items-center">
          <div>
            <img className="w-[45px] h-[45px] rounded-[4px]" src={props.avatar} />
          </div>
          <div>
            <p class="text-[20px] capitalize font-medium truncate leading-normal text-left text-[#5843BD]">
              {props.candidate.toLowerCase()}
            </p>
            <p class="text-[12px] text-[#111111] uppercase font-medium leading-[15px] text-left">
              {`${props.jobApplicationDate}`}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full justify-between items-center gap-4 inline-flex">
        <div className="flex gap-x-2 items-center">
          <div className="flex-col justify-center items-start gap-1 inline-flex">
            <div className={`py-3 pr-3 whitespace-nowrap ${props.status === "Matched"
              ? "rounded-[4px] py-2 px-3 bg-[#5843BD80] text-sm lg:text-base font-medium leading-normal text-white"
              : props.status === "Pending"
                ? "rounded-[4px] py-2 px-3 bg-[#FF663326] text-sm lg:text-base font-medium leading-normal text-[#111111]"
                : "rounded-[4px] py-2 px-3 bg-[#FF6633] text-sm lg:text-base font-medium leading-normal text-[#FFFFFF]"
              }`}>{props.status}</div>
          </div>


          <div className="flex-col justify-center items-start gap-1 inline-flex">
            <div className="flex gap-1 px-2 py-1 items-center">
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.59828 11.5786L6.99828 9.72863L9.39828 11.5786L8.49828 8.52863L10.7483 6.92863H7.94828L6.99828 3.82863L6.04828 6.92863H3.24828L5.49828 8.52863L4.59828 11.5786ZM2.68828 14.2746L4.30228 8.94462L0.113281 5.92863H5.32928L6.99828 0.390625L8.66828 5.92863H13.8833L9.69428 8.94462L11.3083 14.2746L6.99828 10.9986L2.68828 14.2746Z" fill="#F24E1E" />
              </svg>
              <p className="text-[#111111] text-base font-medium">
                {props.testScore !== undefined ? `${props.testScore}%` : "0%"}
              </p>
            </div>
          </div>
        </div>

        <button
          className="py-3 focus:bg-transparent px-2"
          onClick={
            handleApplicationView
          }
        >
          <span className="whitespace-nowrap text-base font-medium leading-normal text-left text-[#5843BD]">
            View Details
          </span>
        </button>
      </div>
    </div>
  );
}
