import * as prototype from "./prototypes";

// last page visited implementation
export const Last_page = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.GET_LAST_PAGE_VISITED_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.GET_LAST_PAGE_VISITED_ERROR:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.GET_LAST_PAGE_VISITED:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state
  }
}

export const Login = (state = {loading: true, errMess: null, isAuthenticated: false, profile_status: "false" }, action) => {
  switch (action.type) {
    case prototype.LOGIN_LOADING:
      return {...state, loading: true, errMess: null, isAuthenticated: false, profile_status: "false"}
    
    case prototype.LOGIN_FAILED:
      return {...state, loading: false, errMess: action.payload, isAuthenticated: false, profile_status: "false" }

    case prototype.LOGIN_USER:
      return {...state, loading: false, errMess: null, isAuthenticated: true, profile_status: action.payload }
    
    case prototype.LOGOUT_LOADING:
      return { ...state, loading: true, errMess: null, isAuthenticated: true}
    
    case prototype.LOG_OUT:
      return { ...state, loading: true, errMess: null, isAuthenticated: false, profile_status: "false"}

    default:
      return state
  }
}
// signing

export const Sign_up = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.SIGNUP_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.SIGNUP_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.SIGNUP_USER:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state
  }
}


export const generate_cv = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.CV_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.GENERATING_CV_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.CV_GENERATED_SUCCESSFULLY:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state
  }
}

// cv id 
export const cv_id = (state = {isloading: true, id: null}, action) => {
  switch (action.type) {

    case prototype.CV_ID_LOADING:
      return { ...state, isloading: true, id: null}
    
    case prototype.CV_ID:
      return {...state, isloading: false, id: action.payload};
    
    default:
      return state;
  }
}

export const UserInfo = (state = {isloading: true, user: null}, action) => {
  switch (action.type) {

    case prototype.LOADING_USER_NAME:
      return {...state, isloading: true, user: null };

    case prototype.USER_NAME_DB:
      return { ...state, isloading: false, user: action.payload }
    
    default:
      return state;
  }
}


export const get_me_my_cv = (state = { isloading: true, errMess: null, message: []}, action) => {

  switch (action.type) {

    case prototype.GETTING_CV_LOADING:
      return { ...state, isloading: true, errMess: null, message: []}
    
    case prototype.GETTING_CV_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: []}
    
    case prototype.GETTING_CV_SUCCESS:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state
  }
}

// employer

export const get_employer_profile = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.GET_EMPLOYER_PROFILE_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.GET_EMPLOYER_PROFILE_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.GET_EMPLOYER_PROFILE_SUCCESS:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

// employer



// save link
export const save_link = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.SAVE_LINK_LOADING:
      return { ...state, isloading: true,  message: null}
    
    case prototype.SAVE_LINK:
      return { ...state, isloading: false,  message: action.payload}
  
    default:
      return state;
  }
}

// public profile reducer
export const get_public_profile = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.GET_PUBLIC_PROFILE_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}

    case prototype.GET_PUBLIC_PROFILE_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}

    case prototype.GET_PUBLIC_PROFILE_SUCCESS:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

export const get_my_profile = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.GET_PROFILE_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.GET_PROFILE_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.GET_PROFILE_SUCCESS:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}


export const verify_mail = (state = { isloading: true, errMess: null, message: ""}, action) => {

  switch (action.type) {

    case prototype.V_MAIL_LOADING:
      return { ...state, isloading: true, errMess: null, message: ""}
    
    case prototype.V_MAIL_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: "done"}

    default:
      return state;
  }
}

export const password_reset = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.R_SET_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.R_SET_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.R_SET_SUCCESS:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

export const jobTitle = (state = {isloading: true, jobtitle: null}, action) => {
  switch (action.type) {

    case prototype.JOBTITLE_LOADING:
      return { ...state, isloading: true, jobtitle: null}
    

    case prototype.JOBTITLE:
      return { ...state, isloading: false,  jobtitle: action.payload}
    
    default:
      return state;
  }
}



export const JobDetails = (state = {isloading: true, jobDetails: null}, action) => {
  switch (action.type) {
    case prototype.JOBDETAILS_LOADING:
      return { ...state, isloading: true, jobDetails: null}
    
    case prototype.JOBDETAILS:
      return { ...state, isloading: false,  jobDetails: action.payload}
    
    default:
      return state;
  }
}

export const JobDescription = (state = {isloading: true, jobDescription: null}, action) => {
  switch (action.type) {
    case prototype.JOBDESCRIPTION_LOADING:
      return { ...state, isloading: true, jobDescription: null}
    
    case prototype.JOBDESCRIPTION:
      return { ...state, isloading: false,  jobDescription: action.payload}
    
    default:
      return state;
  }
}

export const lang = (state = {isloading: true, lang: null}, action) => {
  switch (action.type) {
    case prototype.LANG_LOADING:
      return { ...state, isloading: true, lang: null}
    
    case prototype.LANG:
      return { ...state, isloading: false, lang: action.payload}
    
    default:
      return state;
  }
}


export const translate_lang = (state = {isloading: false, selected: null}, action) => {
  switch (action.type) {
    // case prototype.LANGUAGE_TRANSLATION_LOADING:
    //   return { ...state, isloading: true, selected: "en"}
    
    case prototype.LANGUAGE_TRANSLATION:
      return { ...state, isloading: false, selected: action.payload}
    
    default:
      return state;
  }
}

export const langType = (state = {isloading: true, type: ""}, action) => {
  switch (action.type) {
    case prototype.LANG_TYPE:
      return { ...state, isloading: true, type: ""}
    
    case prototype.LANG_TYPE_S:
      return { ...state, isloading: false,  type: action.payload}
    
    default:
      return state;
  }
}

export const count = (state = {isloading: true, count: 0}, action) => {
  switch (action.type) {
    case prototype.COUNT_LOADING:
      return { ...state, isloading: true, count: 0}
    
    case prototype.COUNT:
      return { ...state, isloading: false,  count: state.count + 1}
    
    default:
      return state;
  }
}

export const spanish = (state = {isloading: true, spanish: null}, action) => {
  switch (action.type) {
    case prototype.SPANISH_LOADING:
      return { ...state, isloading: true, spanish: null}
    
    case prototype.SPANISH:
      return { ...state, isloading: false,  spanish: action.payload}
    
    default:
      return state;
  }
}


export const employer_about = (state = {isloading: false, errMess: null, message: null}, action) => {
  switch (action.type) {
    case prototype.ABOUT_LOADING:
      return { ...state, isloading: false, errMess: null, message: null}
      
    case prototype.ABOUT_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null }
  
    case prototype.ABOUT:
      return { ...state, isloading: false, errMess: null, message: action.payload}
    
    default:
      return state;
  }
}


export const last_resume = (state = {isloading: true, message: null}, action) => {
  switch (action.type) {
    case prototype.LAST_RESUME:
      return { ...state, isloading: false, message: null}
    
    case prototype.LAST_RESUME_LOADING:
      return { ...state, isloading: true, message: null}


    default:
      return state;
  }
}

export const secret = (state = {isloading: true, secret: null}, action) => {
  switch (action.type) {
    case prototype.SECRET_LOADING:
      return { ...state, isloading: true, secret: null}
    
    case prototype.SECRET:
      return { ...state, isloading: false, secret: action.payload}

    default:
      return state;
  }
}

export const job_posting_details = (state = {isloading: false, message: null}, action) => {
  switch (action.type) {
    case prototype.JOB_POSTING_DETAILS_LOADING:
      return { ...state, isloading: true, message: null}
      
    case prototype.JOB_POSTING_DETAILS:
      return { ...state, isloading: false, message: action.payload}
    
    default:
      return state;
  }
}

export const create_job = (state = {isloading: false, errMess: null, message: null}, action) => {
  switch (action.type) {
    case prototype.JOB_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
      
    case prototype.JOB_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null }
  
    case prototype.JOB:
      return { ...state, isloading: false, errMess: null, message: action.payload}
    
    default:
      return state;
  }
}

export const jobs_doc = (state = {isloading: true, errMess: null, message: null}, action) => {
  switch (action.type) {
    case prototype.JOBS_Doc_LOADING:
      return { ...state, isloading: true, errMess: null, message: null }
  
    case prototype.JOBS_Doc_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null }
  
    case prototype.JOBS_Doc:
      return { ...state, isloading: false, errMess: null, message: action.payload}
    
    default:
      return state;
  }
}


export const posting_status = (state = {isloading: true, errMess: null, message: null}, action) => {
  switch (action.type) {
    case prototype.POSTING_LOADING:
      return { ...state, isloading: true, errMess: null, message: null }
  
    case prototype.POSTING_ERR:
      return { ...state, isloading: false, errMess: action.payload, message: null }
  
    case prototype.POSTING_SUCCESS:
      return { ...state, isloading: false, errMess: null, message: action.payload}
    
    default:
      return state;
  }
}

export const jobs_posted = (state = {isloading: true, errMess: null, message: null}, action) => {
  switch (action.type) {
    case prototype.JOBS_POSTED_LOADING:
      return { ...state, isloading: true, errMess: null, message: null }
  
    case prototype.JOBS_POSTED_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null }
  
    case prototype.JOBS_POSTED:
      return { ...state, isloading: false, errMess: null, message: action.payload}
    
    default:
      return state;
  }
}

export const job_to_update = (state = {isloading: true, message: null}, action) => {
  switch (action.type) {
    case prototype.JOB_TO_UPDATE_LOADING:
      return { ...state, isloading: true,  message: null }

    case prototype.JOB_TO_UPDATE:
      return { ...state, isloading: false,  message: action.payload}
    
    default:
      return state;
  }
}

export const job_applicants = (state = {isloading: true, errMess: null, message: null}, action) => {
  switch (action.type) {
    case prototype.JOB_APPLICANTS_LOADING:
      return { ...state, isloading: true, errMess: null,  message: null }

    case prototype.JOB_APPLICANTS_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}

    case prototype.JOB_APPLICANTS :
      return { ...state, isloading: false, errMess: null, message: action.payload}
    
    default:
      return state;
  }
}

export const recommended_candid = (state = {isloading: true, errMess: null, message: null}, action) => {
  switch (action.type) {
    case prototype.RECOMMENDED_CANDIDATE_LOADING:
      return { ...state, isloading: true, errMess: null,  message: null }

    case prototype.RECOMMENDED_CANDIDATE_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}

    case prototype.RECOMMENDED_CANDIDATE :
      return { ...state, isloading: false, errMess: null, message: action.payload}
    
    default:
      return state;
  }
}

// employee job interest

export const saveJob = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.SAVE_JOB_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.SAVE_JOB_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.SAVE_JOB:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

// get chat information

export const chatInformation = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.CHAT_INFO_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.CHAT_INFO_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.CHAT_INFO:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

// get end to end information

export const endToEndChatInformation = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.END_TO_END_CHAT_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.END_TO_END_CHAT_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.END_TO_END_CHAT:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

// get end to end information

export const sendMessage = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.SEND_MESSAGE_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.SEND_MESSAGE_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.SEND_MESSAGE:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

// DECISION CARD
export const decisionCard = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.DECISION_CARD_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.DECISION_CARD_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.DECISION_CARD:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

export const notification = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.NOTIFICATION_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.NOTIFICATION:
      return { ...state, isloading: false, message: action.payload}
    
    default:
      return state;
  }
}

export const msg_notification = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.MESSAGE_NOTIFICATION_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.MESSAGE_NOTIFICATION:
      return { ...state, isloading: false, message: action.payload}
    
    default:
      return state;
  }
}

// OTP VERIFICATION
export const OtpVerification = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.PHONE_VERIFICATION_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.PHONE_VERIFICATION_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.PHONE_VERIFICATION:
      return { ...state, isloading: false, errMess: null, message: action.payload}
      
    default:
      return state;
  }
}


// OTP VERIFICATION
export const OtpSending = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.SEND_OTP_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.SEND_OTP_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.SEND_OTP:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

export const total_page = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.TOTAL_PAGE_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.TOTAL_PAGE:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const get_a_job = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.GET_A_JOB_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.GET_A_JOB_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.GET_A_JOB:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

export const getpublicResume = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.PUBLIC_RESUME_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.PUBLIC_RESUME_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.PUBLIC_RESUME:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

export const errors = (state = { isloading: true, errMess: null}, action) => {

  switch (action.type) {

    case prototype.ERROR_LOADING:
      return { ...state, isloading: true, errMess: null}
    
    case prototype.ERROR:
      return { ...state, isloading: false, errMess: action.payload}

    default:
      return state;
  }
}

export const click = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.CLICK_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.CLICK:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const set_new_job_state = (state = {isloading: true, errMess: null, message: null}, action) => {
  switch (action.type) {
    case prototype.NEW_JOB_POST_LOADING:
      return { ...state, isloading: true, errMess: null,  message: null }

    case prototype.NEW_JOB_POST_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}

    case prototype.NEW_JOB_POST_SUCCESSFUL:
      return { ...state, isloading: false, errMess: null, message: action.payload}
    
    default:
      return state;
  }
}

export const createReferral = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.CREATE_REFERRAL_LINK_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}
    
    case prototype.CREATE_REFERRAL_LINK_FAILED:
      return { ...state, isloading: false, errMess: action.payload, message: null}
    
    case prototype.CREATE_REFERRAL_LINK_SUCCESS:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

export const referral_link = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.REFERRER_LINK_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.REFERRER_LINK:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const employer_employee_info = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.EMPLOYER_EMPLOYEEINFO_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.EMPLOYER_EMPLOYEEINFO:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const socket = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.SOCKET_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.SOCKET:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const candidate_type = (state = { isloading: true, message: "title"}, action) => {

  switch (action.type) {

    case prototype.CANDIDATE_TYPE_LOADING:
      return { ...state, isloading: true, message: "title"}
    
    case prototype.CANDIDATE_TYPE:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const candidate_category = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.CANDIDATE_CATEGORY_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.CANDIDATE_CATEGORY:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const candidate_skill_match = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.CANDIDATE_SKILL_MATCH_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.CANDIDATE_SKILL_MATCH:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const online = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.ONLINE_LOADING:
      return { ...state, isloading: true, message: null}
    
    case prototype.ONLINE:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const individual_job_cat = (state = { isloading: true, errMess: null, message: null}, action) => {

  switch (action.type) {

    case prototype.INDIVIDUAL_JOB_CANDIDATE_LOADING:
      return { ...state, isloading: true, errMess: null, message: null}

    case prototype.INDIVIDUAL_JOB_CANDIDATE_FAILED:
        return { ...state, isloading: true,  errMess: action.payload, message: null}
    
    case prototype.INDIVIDUAL_JOB_CANDIDATE_SUCCESSFUL:
      return { ...state, isloading: false, errMess: null, message: action.payload}

    default:
      return state;
  }
}

export const Sidebar_Open_reducer = (state = { isloading: true, message: true}, action) => {

  switch (action.type) {
    
    case prototype.SIDEBAR:
      let boolean_val = state.message;
      return { ...state, isloading: false, message: !boolean_val}

    default:
      return state;
  }
}

export const analytics = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.ANALYTICS_LOADING:
      return { ...state, isloading: true, message: null}

    case prototype.ANALYTICS:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

export const analytics_weekly = (state = { isloading: true, message: null}, action) => {

  switch (action.type) {

    case prototype.ANALYTICS_LOADING_WEEKLY:
      return { ...state, isloading: true, message: null}

    case prototype.ANALYTICS_WEEKLY:
      return { ...state, isloading: false, message: action.payload}

    default:
      return state;
  }
}

