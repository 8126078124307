export default function Reference(props) {
  return (
    <div className="flex-col justify-start items-start gap-2 inline-flex">
      <p className="text-indigo-700 text-sm font-medium">
        {props.refreeName}
      </p>
      <p className="text-black text-opacity-60 text-xs font-normal">
        {props.refreeRelation}
      </p>
      <p className="text-black text-opacity-90 text-xs font-medium">
        {props.refreeInstititution}
      </p>
      <p className="text-black text-opacity-60 text-xs font-normal">
        {props.refreePhone}
      </p>
      <p className="text-black text-opacity-60 text-xs font-normal">
        {props.refreeEmail}
      </p>
    </div>
  );
}