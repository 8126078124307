import {
  DashoardSvg,
  AnalyticsSvg,
  ResumeSvg,
  JobPostSvg,
  EmployeesSvg,
  DetailsSvg,
  CalendarSvg,
  InboxSvg,
  CandidatesSvg,
  HotIcon,
  SettingIcon,
  NotificationIcon,
  TranslateIcon,
  Dropdown,
  SearchIcon,
} from "../svgs/svgs";
// import UserAvatar from "../../../../components/avatar/avatar";
// import previewcvicon from "../../../../Assets/previewcvicon.svg";

const User = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704142/Website%20images/memoji_pkkvwn.png';
const profilephoto1 = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704276/Website%20images/profilephoto1_el05hg.svg';
const fivestars = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704256/Website%20images/fivestars_oci4j1.svg';
const checkedbox = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704285/Website%20images/checkedbox_uchbuk.svg';
const ppediticon = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704274/Website%20images/ppediticon_rm33kk.svg';
const ResumeAppSvg = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704277/Website%20images/resumeSvg_mg77gp.svg';
const profilephotodefault = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704276/Website%20images/profilephoto1_el05hg.svg';
const Newmoilsvg = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704272/Website%20images/newmoilsvg_hvo0w5.svg';
const Moil = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704267/Website%20images/moil_zafedh.png';
const UserAvatar = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704158/Website%20images/user-avatar_g1d998.png';
const previewcvicon = 'https://res.cloudinary.com/drlcisipo/image/upload/v1705704275/Website%20images/previewcvicon_vdadnl.svg'

export default {
  DashoardSvg,
  AnalyticsSvg,
  ResumeSvg,
  JobPostSvg,
  EmployeesSvg,
  DetailsSvg,
  CalendarSvg,
  InboxSvg,
  CandidatesSvg,
  HotIcon,
  SettingIcon,
  NotificationIcon,
  TranslateIcon,
  Dropdown,
  SearchIcon,
  UserAvatar,
  User,
  Moil,
  Newmoilsvg,
  profilephoto1,
  fivestars,
  profilephotodefault,
  checkedbox,
  ppediticon,
  previewcvicon,
  ResumeAppSvg
};
