import React, { useState, useEffect } from "react";
// import reference from "../reference";
import { baseURL, baseURL1 } from "../../Redux/baseURL";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getting_profile_successful,
  getting_employer_profile_successful,
  SiteErrorLoading,
  SiteError,
  getting_employer_profile_loading,
  userInfo,
  returnHome
} from "../../Redux/ActionCreator";
import reference from "../common/reference";
import TopBar from "../topbar/Top-Bar";
import SideBar from "../sidebar/Side-Bar";
import { createPortal } from "react-dom";
import LoaderModal from "../common/LoaderModal";
import { ToastContainer, toast } from "react-toastify";
import { getOneFromServerCache } from "../../utils/saveIntoCache";

function EmployerProfilePhoto() {
  const dispatch = useDispatch();
  const stat = useSelector((e) => e.login);
  const errors = useSelector(e => e.errors.errMess);
  const userInfor = useSelector((e) => e.userInfo);
  const profile = useSelector(e => e.profile.message);
  const [previewUrl, setPreviewUrl] = useState("");
  const navigate = useNavigate();
  const url = window.location.pathname;
  let [ proceed, setProceed ] = useState(false);
  let [ show, setShow ] = useState(false);
  const savedLink = useSelector(e => e.userLink.message); // get the stored link from landing page due to authentication been false.
  const employer = useSelector(e => e.employer.message);
  const [ tempInfo, setTempInfo ] = useState();

  useEffect(() => {
    if (stat.profile_status.AcctType === "Official" && employer == null) {
      // get employer info from cache
      let profileInfo = getOneFromServerCache("employerProfileInfo");
      profileInfo.then(resp => resp.json())
      .then(resp => {
        let resOps = JSON.parse(resp.data);
        if (resOps !== null) {
          setTempInfo(resOps);
        } else {
          setTimeout(() => {
            toast.error(
              "please provide your information before you proceed",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }, 50);
          navigate("/employer/form");
        }
      }).catch(err => console.log("error from getting temporary employer profile details", err));
    }
  }, [])

  useEffect(() => {
    if (errors !== null) {
      setTimeout(() => {
        toast.error(
          errors,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }, 50);
      dispatch(SiteErrorLoading(true));
    }
  }, [errors]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // e.target.result contains the base64-encoded image data URL
        setPreviewUrl(e.target.result);
        console.log("We did it baby"); // yes bro!!
      };

      // Read the file as a data URL (base64)
      reader.readAsDataURL(file);
    } else {
      // Handle the case where no file is selected.
      console.error("No file selected.");
    }
  };

  console.log("Base64URL", previewUrl);
  
  const sendBase64UrlToBackend = () => {
    if (previewUrl) {
      let token = localStorage.getItem("token");
      const body = {
        image_uri: previewUrl, // Send the base64 image data URL
      };

      const headers = {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      if (stat.profile_status.AcctType === "Official") {
        if (employer == null) {
          setShow(true);
          let cred = {};
          if (tempInfo !== null && tempInfo !== undefined) {
            cred = tempInfo;
            cred.photo = body.image_uri;
          } else {
            setTimeout(() => {
              toast.error(
                "No valid information. Please fill the profile creation form again.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            }, 50);
            navigate("/employer/form");
          }
          fetch(baseURL + "/api/employer/upload_photo", { // this create employer profile if it doesn't exit.
            method: "PATCH",
            body: JSON.stringify(cred),
            headers: {
              "Content-Type": "application/json",
              // Authorization: "Bearer " + localStorage.getItem("token"),
            },
            credentials: "include"
          })
          .then((resp) => resp.json())
          .then((resp) => {
            console.log(resp);
            if (resp.success === true && resp.statusCode == 200) {
              setShow(false);
              dispatch(userInfo(resp?.data?.user));
              dispatch(getting_employer_profile_successful(resp.data?.profile))
              navigate("/employer/profile");
            } else if (resp.success === false && resp.message == "Unauthorized") {
              dispatch(returnHome(resp.status));
            }
            if (resp.title == "Error") {
              setTimeout(() => {
                toast.error("Please try again", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
              setShow(false);
            }
            if (resp.title == "Operation Failed") {
              setTimeout(() => {
                toast.error(resp.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 50);
              setShow(false);
            }
            if (
              resp.title === "Duplicate Field" ||
              resp.title === "Field Error"
            ) {
              let err;
              if (resp.message.includes("_")) {
                err = resp.message.replace("_", " ");
                setShow(false);
                setTimeout(() => {
                  toast.error(err, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
                dispatch(getting_employer_profile_loading())
              } else {
                setShow(false);
                setTimeout(() => {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
                dispatch(getting_employer_profile_loading());
              }
            }
          });
        } else {
          setShow(true);
          fetch(baseURL + "/api/employer/upload_photo", {
            method: "PATCH",
            body: JSON.stringify(body), // Send the JSON object containing the base64 URL
            headers,
            credentials: "include"
          })
            .then((resp) => resp.json())
            .then((resp) => {
              console.log(resp);
              if (resp.success === true) {
                setShow(false);
                dispatch(getting_employer_profile_successful(resp.data));
                console.log("Success, we did it"); // yes bro!!
                if (savedLink !== null) {
                  setProceed(true); // display the modal questioning if the client wants to proceed with the pre-login action.
                } else {
                  navigate("/employer/profile");
                }
              } else if (resp.success === false && resp.message == "Unauthorized") {
                dispatch(returnHome(resp.status));
              }
            }).catch(err => {
              console.log("Error", err);
              dispatch(SiteError(err));
              setShow(false);
            });
        }
      }
    } else {
      console.error("No file selected.");
    }
  };

  const handleContinue = () => {
    if (savedLink !== null) {
      window.location.assign(savedLink);
    } else {
      ToProfile();
    }
  }

  const ToProfile = () => {
    if (stat.profile_status.AcctType === "Official") {
      navigate("/employer/profile");
    }
  }

  const continueToTheSavedLink = proceed ? 
  createPortal(
    <div className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
      <div className="w-screen aspect-[1/1] sm:aspect-[3/2] lg:aspect-[7/5] xl:aspect-[3/2] flex items-center justify-center px-2 ">
        <div className="bg-white py-7 px-5 rounded-xl ">
        <p>Do you want to continue with the job application or proceed to your dashboard</p>
        <div className="flex items-center justify-between pt-5">
          <button
              className="px-3 sm:px-6 py-3 bg-indigo-700 rounded-3xl shadow justify-center items-center gap-2.5 inline-flex"
              onClick={handleContinue}
            >
            <span className="text-center text-white  text-[12px] sm:text-base font-semibold font-['Poppins']">
              Yes
            </span>
          </button>
          <button
            className="px-3 sm:px-6 py-3 bg-indigo-700 rounded-3xl shadow justify-center items-center gap-2.5 inline-flex"
            onClick={ToProfile}
          >
          <span className="text-center text-white  text-[12px] sm:text-base font-semibold font-['Poppins']">
            No
          </span>
        </button>
        </div>
        </div>
      </div>
    </div>, document.querySelector("#continueToTheSavedLink")) : null

  return (
    <>
      <div className="grid grid-cols-auto md:grid-cols-smdash cont:grid-cols-dash grid-rows-dash">
      <TopBar topBarShowMenu={true} />
      <SideBar />
      <div className="px-[4.5%] tablet:max-w-[763px] w-full h-full overflow-y-hidden mx-auto">
        <div className="w-full mt-4 bg-transparent border border-[#777DEA54] border-opacity-[0.33] shadow-md rounded-full px-6 py-[0.375rem] font-semibold text-3xl text-black">
          Upload logo
        </div>
        <p className="w-full my-12 font-medium text-base text-black">
          Complete this form to upload a logo to your profile.",
  itllbevisible: "It will be visible to anyone who visits your profile.
        </p>

        {/* Main content of page  */}
        <div className="bg-[#FEFEFE] w-full rounded-2xl border border-[#777DEA54] border-opacity-[0.33] shadow-md flex flex-col justify-center gap-16 tablet:px-16 vsm:px-[4.5%] py-8">
          <div className="w-full font-medium text-base text-black">
            Upload logo
            <p>It will be visible to anyone who visits your profile.</p>
          </div>

          <div className="flex flex-col gap-2">
            <div
              className={` max-w-[220px] ${
                previewUrl === "" ? "h-fit" : "max-h-[220px]"
              } w-full h-full `}
            >
              {previewUrl === "" ? (
                <div>
                  <p className="mb-2">Click on the button below to select an Image to preview</p>
                  <img src={profile?.photo !== "" && profile?.photo !== undefined ? profile?.photo : "https://res.cloudinary.com/drlcisipo/image/upload/v1705704277/Website%20images/profilephotodefault_rsir6r.svg" } alt="" />
                </div>
              ) : (
                <img
                  src={previewUrl}
                  alt=""
                  className="max-w-[220px] max-h-[220px] object-cover w-full h-full rounded-lg"
                />
              )}
            </div>

            {/* image upload input field  */}
            <label className="custom-file-upload">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="invisible w-[0px] h-[0px]"
              />
              <div className="bg-[#FED1C1] px-7 py-3 font-medium text-lg text-black rounded-full flex items-center gap-2 w-full">
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 20C1.45 20 0.979002 19.804 0.587002 19.412C0.195002 19.02 -0.000664969 18.5493 1.69779e-06 18V6C1.69779e-06 5.45 0.196002 4.979 0.588002 4.587C0.980002 4.195 1.45067 3.99933 2 4H5.15L7 2H13V4H7.875L6.05 6H2V18H18V9H20V18C20 18.55 19.804 19.021 19.412 19.413C19.02 19.805 18.5493 20.0007 18 20H2ZM18 6V4H16V2H18V0H20V2H22V4H20V6H18ZM10 16.5C11.25 16.5 12.3127 16.0623 13.188 15.187C14.0633 14.3117 14.5007 13.2493 14.5 12C14.5 10.75 14.0623 9.68733 13.187 8.812C12.3117 7.93667 11.2493 7.49933 10 7.5C8.75 7.5 7.68734 7.93767 6.812 8.813C5.93667 9.68833 5.49934 10.7507 5.5 12C5.5 13.25 5.93767 14.3127 6.813 15.188C7.68834 16.0633 8.75067 16.5007 10 16.5ZM10 14.5C9.3 14.5 8.70834 14.2583 8.225 13.775C7.74167 13.2917 7.5 12.7 7.5 12C7.5 11.3 7.74167 10.7083 8.225 10.225C8.70834 9.74167 9.3 9.5 10 9.5C10.7 9.5 11.2917 9.74167 11.775 10.225C12.2583 10.7083 12.5 11.3 12.5 12C12.5 12.7 12.2583 13.2917 11.775 13.775C11.2917 14.2583 10.7 14.5 10 14.5Z"
                    fill="#F24E1E"
                  />
                </svg>
                Upload logo
              </div>
            </label>
          </div>

          <div className="w-full flex justify-end">

            <button
              onClick={sendBase64UrlToBackend}
              className="text-white text-base font-semibold rounded-lg bg-[#5843BD] py-3 px-5 whitespace-nowrap"
            >
              Save logo
            </button>
          </div>
        </div>
      </div>
    </div>
    <ToastContainer />
    {show ? <LoaderModal text={employer == null ? "Processing your information" : "Uploading logo..."} /> : null}
    {continueToTheSavedLink}
    </>
  );
}

export default EmployerProfilePhoto;

