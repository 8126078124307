import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MessageVerticalItem(props) {
  const navigate = useNavigate();
  const chat_info = useSelector(e => e.chat_info.message);

  const handleClick = () => {
    chat_info[props.index].Notification = 0;
    navigate(
      `/employer/inbox/${props.receiver}?i=${props.index}`
    )
  }

  return (
    <button
      className={`flex justify-between  py-3 w-full focus:bg-transparent`}
      onClick={handleClick}
    >
      <span className="flex gap-x-3 items-center">
        <span className="block relative w-14 h-14">
          <img
            className="Avatar w-full h-full rounded-full"
            src={props.avatar}
            alt={`${props.name} avatar`}
          />
        </span>
        <span className="block text-start">
          <span className="capitalize max-w-[160px] truncate text-black block text-base font-semibold">
            {props.name.toLowerCase()}
          </span>
          <span className="block text-red-500 text-[10px] font-normal">
            {props.position}
          </span>
          <span className="block text-black text-opacity-50 text-[10px] font-normal">
            {/* {props.team} */}
          </span>
        </span>
      </span>
      <span className="flex flex-col items-center gap-2">
        <span className="text-black text-opacity-50 text-[10px] font-normal">
          {props.messageAge}
        </span>
        <span className="w-4 h-4 bg-indigo-700 rounded-full flex justify-center items-center">
          <span className="self-end text-white text-[10px] font-normal">
            {props.messageNo}
          </span>
        </span>
      </span>
    </button>
  );
}
