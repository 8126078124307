import React, { useContext, useState, useEffect } from "react";
import TextInput from "../../common/text_input/text_input";
import Tab from "../../common/tab/tab";
import Radio from "../../common/radio/radio";
import { ToastContainer, toast } from "react-toastify";
import BackButton from "../../common/back_button";
import CustomDate from "../../common/custom_date";
import Autocomplete from "@mui/material/Autocomplete";
import {
  JobDescription_loading,
  dispatchJobDetail,
  updateJob,
  job_posting_details,
  SiteErrorLoading,
} from "../../../Redux/ActionCreator";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skills from "../../common/skills/skills";
import Skills_sp from "../../common/skills/spSkill";
import $ from "jquery";
import HelmetProvide from "../../common/helmet";
import { handleKeyDown } from "../../../utils/keydown/keydown";
import { useAnalytics } from "../../../context/AnalyticsContext";
import { getOneFromServerCache, saveIntoServerCache } from "../../../utils/saveIntoCache";
import { convert_employer_JobPost } from "../../../Redux/convertResume";
import LoaderModal from "../../common/LoaderModal";
import TextEditor from "../../common/textarea/NewTextArea.js";

import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles(() => ({
  notranslate: {
    '& .MuiPopover-root': {
      '& .MuiPaper-root': {
        '& .MuiDatePicker-root': {
          '& .MuiTypography-root': {
            '& .MuiButtonBase-root': {
              '& .MuiIconButton-label': {
                '& .MuiSvgIcon-root': {
                  transform: 'none',
                },
              },
            },
          },
        },
      },
    },
  },
}));


export default function EmploymentDetails(props) {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t_lang = useSelector((lg) => lg.t_lang);
  const gpt_help = useSelector((e) => e.job);
  const autofill = useSelector((e) => e.job_posting_details.message);
  const [jobDescription, setJobDescription] = useState({
    selectedSkills: [],
  });
  const [sk, setSk] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(sk);
  const [errMessage, setErrMessage] = useState(null);
  const info = useSelector((info) => info.jobDetails.jobDetails);
  const jTU = useSelector((e) => e.job_to_update.message);
  const urlPath1 = window.location.pathname;
  const jobName = urlPath1.split("/")[3];
  const [save, setSave] = useState(false);
  const profile = useSelector(e => e.employer);
  const [startExecution, setStartExecution] = useState(false);
  const errors = useSelector(e => e.errors.errMess);

  const [lang, setLang] = useState("");
  useEffect(() => {
    let Language = getOneFromServerCache("Language");
    Language.then(resp => resp.json())
      .then(resp => {
        console.log("Language", resp.data);
        if (resp.data == null) {
          setLang("Spanish");
        } else {
          setLang(resp.data);
        }
      }).catch(err => {
        console.log("Language getting error", err);
      });

  }, [])
  console.log("lang", lang)
  useEffect(() => {
    if (startExecution == true) {
      // do sth
      if (info !== null) {
        if (lang == '"Spanish"') {
          dispatch(convert_employer_JobPost(info, "job posting", "/employer/create-job/preview"));
        }
      } else {
        setStartExecution(false);
      }
    }
  }, [info, startExecution]);

  useEffect(() => {
    if (errors !== null) {
      setTimeout(() => {
        toast.error(errors, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }, 50);
      setStartExecution(false);
      dispatch(SiteErrorLoading(true));
    }
  }, [errors])

  console.log("urlPath1", urlPath1);
  useEffect(() => {
    if (urlPath1 === "/employer/create-job") {
      if (gpt_help.message !== null) {
        let tech_skills = [];
        if (Array.isArray(gpt_help.message?.Skills)) {
          // for (let i = 0; i <= gpt_help.message?.Skills.length - 1; i++) {
          //   tech_skills.push(gpt_help.message?.Skills[i]);
          // }
          setSk(gpt_help.message?.Skills);
        } else if (typeof gpt_help.message?.Skills === "string") {
          // split by comma
          tech_skills = gpt_help.message?.Skills.split(",");
          setSk(tech_skills);
        }
      }
      if (autofill !== null) {
        if (autofill.employmentType === "Full-time") {
          $("#Full-time").prop("checked", true);
          setJobDescription((e) => ({ ...e, employmentType: "Full-time" }));
        }
        if (autofill.employmentType === "Part-time") {
          $("#Part-time").prop("checked", true);
          setJobDescription((e) => ({ ...e, employmentType: "Part-time" }));
        }
        if (autofill.employmentType === "per-day") {
          $("#per-day").prop("checked", true);
          setJobDescription((e) => ({ ...e, employmentType: "per-day" }));
        }
        if (autofill.employmentType === "Contract") {
          $("#Contract").prop("checked", true);
          setJobDescription((e) => ({ ...e, employmentType: "Contract" }));
        }
        if (
          autofill.compensation !== null &&
          autofill.compensation !== undefined
        ) {
          $('[name="compensation"]').val(autofill.compensation);
          setJobDescription((e) => ({
            ...e,
            compensation: autofill.compensation,
          }));
        }
        if (autofill.paymentPlan === "Hourly") {
          $("#Hourly").prop("checked", true);
          setJobDescription((e) => ({ ...e, paymentPlan: "Hourly" }));
        }
        if (autofill.paymentPlan === "Weekly") {
          $("#Weekly").prop("checked", true);
          setJobDescription((e) => ({ ...e, paymentPlan: "Weekly" }));
        }
        if (autofill.paymentPlan === "Monthly") {
          $("#Monthly").prop("checked", true);
          setJobDescription((e) => ({ ...e, paymentPlan: "Monthly" }));
        }
        if (autofill.duration !== null && autofill.duration !== undefined) {
          let month = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const d = autofill.duration.replace(",", "").split(" ");
          let dt = `${month.indexOf(d[1]) + 1}/${d[0]}/${d[2]}`;
          setJobDescription((e) => ({ ...e, duration: dt }));
        }
        if (autofill.city !== null && autofill.city !== undefined) {
          $('[name="city"]').val(autofill.city);
          setJobDescription((e) => ({ ...e, city: autofill.city }));
        }
        if (autofill.state !== null && autofill.state !== undefined) {
          $('[name="state"]').val(autofill.state);
          setJobDescription((e) => ({ ...e, state: autofill.state }));
        }
        if (autofill.zipCode !== null && autofill.zipCode !== undefined) {
          $('[name="zipCode"]').val(autofill.zipCode);
          setJobDescription((e) => ({ ...e, zipCode: autofill.zipCode }));
        }
      } else {
        // if it is null lets check backend server and get anything we have there.
        let cacheInfo = getOneFromServerCache("jobPostPG2");
        cacheInfo.then(res => res.json())
          .then(res => {
            console.log("server cache value", res);
            if (res.data !== null && res.data !== undefined) {
              let resObj = JSON.parse(res.data);
              console.log("respObj", resObj);
              dispatch(job_posting_details(resObj));
              setJobDescription(resObj);
              if (resObj.employmentType === "Full-time") {
                $("#Full-time").prop("checked", true);
                setJobDescription((e) => ({ ...e, employmentType: "Full-time" }));
              }
              if (resObj.employmentType === "Part-time") {
                $("#Part-time").prop("checked", true);
                setJobDescription((e) => ({ ...e, employmentType: "Part-time" }));
              }
              if (resObj.employmentType === "per-day") {
                $("#per-day").prop("checked", true);
                setJobDescription((e) => ({ ...e, employmentType: "per-day" }));
              }
              if (resObj.employmentType === "Contract") {
                $("#Contract").prop("checked", true);
                setJobDescription((e) => ({ ...e, employmentType: "Contract" }));
              }
              if (
                resObj.compensation !== null &&
                resObj.compensation !== undefined
              ) {
                $('[name="compensation"]').val(resObj.compensation);
                setJobDescription((e) => ({
                  ...e,
                  compensation: resObj.compensation,
                }));
              }
              if (resObj.paymentPlan === "Hourly") {
                $("#Hourly").prop("checked", true);
                setJobDescription((e) => ({ ...e, paymentPlan: "Hourly" }));
              }
              if (resObj.paymentPlan === "Weekly") {
                $("#Weekly").prop("checked", true);
                setJobDescription((e) => ({ ...e, paymentPlan: "Weekly" }));
              }
              if (resObj.paymentPlan === "Monthly") {
                $("#Monthly").prop("checked", true);
                setJobDescription((e) => ({ ...e, paymentPlan: "Monthly" }));
              }
              if (resObj.city !== null && resObj.city !== undefined) {
                $('[name="city"]').val(resObj.city);
                setJobDescription((e) => ({ ...e, city: resObj.city }));
              }
              if (resObj.state !== null && resObj.state !== undefined) {
                $('[name="state"]').val(resObj.state);
                setJobDescription((e) => ({ ...e, state: resObj.state }));
              }
              if (resObj.zipCode !== null && resObj.zipCode !== undefined) {
                $('[name="zipCode"]').val(resObj.zipCode);
                setJobDescription((e) => ({ ...e, zipCode: resObj.zipCode }));
              }
            } else {
              $('[name="compensation"]').val("$");
              setJobDescription((e) => ({
                ...e,
                compensation: "$" + "",
              }));
            }
            console.log("Done");
          });
      }
    } else {
      if (urlPath1.includes("update-job")) {
        setSave(true);
        console.log("jTU", jTU);
        if (jTU !== null) {
          let tech_skills = [];
          if (Array.isArray(jTU[0]?.skills)) {
            // for (let i = 0; i <= jTU?.Skills.length - 1; i++) {
            //   tech_skills.push(jTU?.Skills[i]);
            // }
            setSk(jTU[0]?.skills);
            setJobDescription(e => ({ ...e, selectedSkills: jTU[0]?.skills }));
          } else if (typeof jTU[0]?.skills === "string") {
            // split by comma
            tech_skills = jTU[0]?.skills.split(",");
            setSk(tech_skills);
          }
        }
      }
      if (jTU[0]?.jobType === "FULL-TIME") {
        $("#Full-time").prop("checked", true);
        setJobDescription((e) => ({ ...e, employmentType: "Full-time" }));
      }
      if (jTU[0]?.jobType === "PART-TIME") {
        $("#Part-time").prop("checked", true);
        setJobDescription((e) => ({ ...e, employmentType: "Part-time" }));
      }
      if (jTU[0]?.jobType === "PART-TIME") {
        $("#per-day").prop("checked", true);
        setJobDescription((e) => ({ ...e, employmentType: "per-day" }));
      }
      if (jTU[0]?.jobType === "CONTRACT") {
        $("#Contract").prop("checked", true);
        setJobDescription((e) => ({ ...e, employmentType: "Contract" }));
      }
      if (jTU[0]?.compensation !== null && jTU[0]?.compensation !== undefined) {
        $('[name="compensation"]').val(jTU[0]?.compensation);
        setJobDescription((e) => ({
          ...e,
          compensation: jTU[0]?.compensation,
        }));
      }
      if (jTU[0]?.paymentPlan === "HOURLY") {
        $("#Hourly").prop("checked", true);
        setJobDescription((e) => ({ ...e, paymentPlan: "HOURLY" }));
      }
      if (jTU[0]?.paymentPlan === "WEEKLY") {
        $("#Weekly").prop("checked", true);
        setJobDescription((e) => ({ ...e, paymentPlan: "WEEKLY" }));
      }
      if (jTU[0]?.paymentPlan === "MONTHLY") {
        $("#Monthly").prop("checked", true);
        setJobDescription((e) => ({ ...e, paymentPlan: "MONTHLY" }));
      }
      if (jTU[0]?.duration !== null && jTU[0]?.duration !== undefined) {
        let month = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const d = jTU[0]?.duration.replace(",", "").split(" ");
        let dt = `${month.indexOf(d[1]) + 1}/${d[0]}/${d[2]}`;
        setJobDescription((e) => ({ ...e, duration: dt }));
      }
      if (
        jTU[0]?.location.city !== null &&
        jTU[0]?.location.city !== undefined
      ) {
        $('[name="city"]').val(jTU[0]?.location.city);
        setJobDescription((e) => ({ ...e, city: jTU[0]?.location.city }));
      }
      if (
        jTU[0]?.location.state !== null &&
        jTU[0]?.location.state !== undefined
      ) {
        $('[name="state"]').val(jTU[0]?.location.state);
        setJobDescription((e) => ({ ...e, state: jTU[0]?.location.state }));
      }
      if (
        jTU[0]?.location.zipcode !== null &&
        jTU[0]?.location.zipcode !== undefined
      ) {
        $('[name="zipCode"]').val(jTU[0]?.location.zipcode);
        setJobDescription((e) => ({ ...e, zipCode: jTU[0]?.location.zipcode }));
      }
      console.log("jobDescription", jobDescription)
    }
  }, [urlPath1, jTU]);

  const handleChange = (event, value) => {
    console.log("change value", value);
    setSk(value);
    setJobDescription((e) => ({ ...e, sk }));
  };
  console.log("sk", sk);

  const handleInputChangep = (event, value) => {
    if (!value.includes(".")) {
      setJobDescription((prev) => {
        return { ...prev, selectedSkills: value };
      });
      return;
    }

    const newOptions = value
      .split(/,|\.\s|\n/)
      .map((item) => item.trim())
      .filter((item) => item !== "")
      .filter((item) => !selectedOptions.includes(item));
    console.log("new Options", newOptions);
    if (newOptions.length === 0) {
      return false;
    }
    const newArray = [...newOptions, ...selectedOptions];
    setJobDescription((prev) => {
      return { ...prev, selectedSkills: newArray };
    });
  };

  console.log("jobDescription", jobDescription);

  // useEffect(() => {
  //   dispatch(JobDescription_loading());
  // }, [])

  const ChangeHandler = (e) => {
    const { name, value, type, checked } = e.target;

    setJobDescription((prev) => {
      if (name == "compensation") {
        return {
          ...prev,
          [name]: e.target.value.includes("$") ? e.target.value : "$" + e.target.value,
        }
      }
      if (type === "checkbox") {
        // For checkboxes, toggle the 'checked' property
        return {
          ...prev,
          [name]: !prev[name],
        };
      } else if (type === "radio") {
        // For radio buttons, set the value directly
        return {
          ...prev,
          [name]: value,
        };
      } else {
        // For other input types, set the 'value' property
        return {
          ...prev,
          [name]: value,
        };
      }
    });
    dispatch(job_posting_details(jobDescription));
  };

  // console.log(jobDescription);

  const handleSubmitJobDescription = (e) => {
    e.preventDefault();
    // let errMessage;
    // if (jobDescription.employmentType === undefined ) {
    //   errMessage = 'Job Title is required';
    // } else if (jobDescription.paymentPlan === undefined) {
    //   errMessage = 'Compensation is required';
    // } else if (jobDescription.duration === null) {
    //   errMessage = 'Recruitment duration is required';
    // } else if (jobDescription.city === undefined) {
    //   errMessage ='City is required';
    // } else if (jobDescription.state === undefined) {
    //   errMessage = 'State is required';
    // } else if (jobDescription.zipCode === undefined) {
    //   errMessage = 'zip code is required';
    // } else {
    //   errMessage = null
    // }

    // setTimeout(() => {
    //   if (errMessage !== null) {
    //     toast.error(errMessage, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //   }
    // }, 50);

    // if (errMessage === null) {
    //   dispatch(dispatchJobDescription(jobDescription));
    //   props.handleStepChangeForward();
    // }
  };

  console.log(jobDescription);

  const handleJobPreview = () => {
    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    console.log("duration", jobDescription.duration)
    const d = jobDescription.duration?.split("/");
    console.log("d", d);
    let mm = Number(d?.[0]);
    console.log("MM", mm);
    let date = `${d?.[1]}, ${month?.[mm - 1]} ${d?.[2]}`;
    console.log("Date", date);

    if (urlPath1 === "/employer/create-job") {
      console.log("jobDescription.paymentPlan", jobDescription.paymentPlan)
      let errMessage;
      if (jobDescription.employmentType === undefined) {
        errMessage = "Job type is required";
      } else if (jobDescription.compensation === undefined || jobDescription.compensation == "$" || jobDescription.compensation == "") {
        errMessage = "Compensation is required";
      } else if (jobDescription.duration === null ||
        jobDescription.duration === undefined ||
        jobDescription.duration === "undefined, undefined undefined") {
        errMessage = "Recruitment duration is required";
      } else if (jobDescription.city === undefined || jobDescription.city === "") {
        errMessage = "City is required";
      } else if (jobDescription.state === undefined || jobDescription.state === "") {
        errMessage = "State is required";
      } else if (jobDescription.paymentPlan === undefined || jobDescription.zipCode === "") {
        errMessage = "payment plan is required";
      } else if (typeof jobDescription.selectedSkills == "array" && !jobDescription.selectedSkills[0].includes(",")) {
        errMessage = "Skills number be seperated by a ','";
      } else {
        errMessage = null;
      }

      setTimeout(() => {
        if (errMessage !== null) {
          toast.error(errMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }, 50);

      if (errMessage === null) {
        let values = gpt_help.message;
        values.Skills = [...sk, ...jobDescription.selectedSkills];
        jobDescription.duration = date;
        dispatch(job_posting_details(jobDescription)); // only save this page info here so as to make autofill easy.
        dispatch(dispatchJobDetail({ ...values, ...jobDescription }));
        saveIntoServerCache("jobPostPG2", jobDescription);
        if (profile.message !== null) {
          analytics.track("Job post submit btn", {
            Type: "Employer",
            time: new Date(),
            Email: profile?.message?.email,
            Company_name: profile.message?.name,
            profileId: profile.message?._id,
            userId: profile.message?.userId
          });
        }
        console.log("lang", lang)
        if (lang === '"Spanish"') {
          setStartExecution(true);
        } else {
          props.handleStepChangeForward()
          // navigate("/employer/create-job/preview");
        }
      }
    } else {
      if (urlPath1.includes("update-job")) {
        let formerValue = jTU[0];
        formerValue.skills = [...sk, ...jobDescription.selectedSkills];
        formerValue.jobType = jobDescription.employmentType.toUpperCase();
        formerValue.compensation = jobDescription.compensation;
        formerValue.paymentPlan = jobDescription.paymentPlan.toUpperCase();
        formerValue.applicationDeadline = date;
        formerValue.location.city = jobDescription.city;
        formerValue.location.state = jobDescription.state;
        formerValue.location.zipcode = jobDescription.zipCode;

        let errMessage;
        if (jobDescription.employmentType === undefined || jobDescription.employmentType == "") {
          errMessage = "Job type is required";
        } else if (jobDescription.compensation === undefined || jobDescription.compensation == "$" || jobDescription.compensation == "") {
          errMessage = "Compensation is required";
        } else if (
          date === null ||
          date === undefined ||
          date === "undefined, undefined undefined"
        ) {
          errMessage = "Recruitment duration is required";
        } else if (
          jobDescription.city === undefined ||
          jobDescription.city === ""
        ) {
          errMessage = "City is required";
        } else if (
          jobDescription.state === undefined ||
          jobDescription.state === ""
        ) {
          errMessage = "State is required";
        } else if (
          jobDescription.zipCode === undefined ||
          jobDescription.zipCode === ""
        ) {
          errMessage = "zip code is required";
        } else {
          errMessage = null;
        }

        setTimeout(() => {
          if (errMessage !== null) {
            toast.error(errMessage, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }, 50);

        console.log("update", errMessage);
        console.log("update", date);
        if (errMessage === null) {
          dispatch(updateJob(jobName, formerValue));
          navigate("/employer/jobs");
        }
      }
    }
  };

  console.log(save);

  useEffect(() => {
    // Assuming the component renders an element with class 'flowbite-datepicker'
    const datePickerElement = document.querySelector('.MuiPaper-root');

    if (datePickerElement) {
      // Apply the notranslate attribute to prevent automatic translation
      datePickerElement.setAttribute('data-notranslate', 'true');
      // datePickerElement.addClass('notranslate');
    }
  }, [])


  const classes = useStyles();


  return (
    <>
      <HelmetProvide
        title="Moil | Create job"
        description="Create and manage job listings on Moil. Connect with talented blue-collar workers and find the best candidates for your team."
      />

      <div className="px-3 lg:pl-6 py-8 w-full flex justify-center h-calc-150 md:h-calc-70 overflow-y-scroll">
        <div className="h-max w-full flex justify-center">
          <div className="flex w-full max-w-[900px] xtraxl:max-w-[1200px] flex-col gap-y-6 justify-center">
            <BackButton onClick={() => props.handleStepChangeBackward()} />
            <div className="flex flex-col gap-y-1">
              <p class=" text-[24px] font-semibold leading-normal text-left text-[#0D0D0D]">
                Post a job
              </p>

              <p class="text-base font-normal leading-normal text-left text-[#0D0D0D]">
                Create job with just 2 easy steps.<br />Type in your job title and click “Generate for me”.
              </p>
            </div>

            <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0  overflow-hidden">
              <Tab
                // label="Personal Information"
                label="Job Details"
                current={false}
                completed={true}
                num="1"
              />
              <Tab num="2" label="Employment Details" current={true} completed={false} />
              <Tab num="3" label="Skill Test" current={false} completed={false} />
            </div>

            <form
              className="flex-col justify-start items-start gap-12 flex w-full"
              onSubmit={handleSubmitJobDescription}
            >
              <div className="flex-col justify-start items-start gap-y-6 flex w-full p-6 bg-white bg-opacity-80 rounded-2xl shadow border border-indigo-400 border-opacity-30">
                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <li className="list-disc ml-2 font-medium text-base text-black"
                    for="company"
                  >
                    Required Skills
                  </li>
                  <p className="text-[#FF6633] text-xs font-medium">
                    Are there any specific skills, certifications, or experience required? Please seperate each requirement or skill with a full-stop (.) or a comma (,)
                  </p>
                  <div className="w-full">
                    <Box className="w-full mb-4 ">
                      <Autocomplete
                        onCut={(e) => e.preventDefault()}
                        onCopy={(e) => e.preventDefault()}
                        onKeyDown={handleKeyDown}
                        multiple
                        value={sk}
                        onChange={handleChange}
                        id="multiple-limit-tags"
                        options={selectedOptions}
                        onInputChange={handleInputChangep}
                        freeSolo
                        filterOptions={(options, state) => options}
                        name="job skill"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="seperated with ."
                          />
                        )}
                      />
                    </Box>
                  </div>
                </div>
                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <li className="list-disc ml-2 font-medium text-base text-black"
                    for="company"
                  >
                    Job Type
                  </li>
                  <p className="text-[#FF6633] text-xs font-medium">
                    Is this a full-time, part-time, contract, or temporary position? Choose one option.
                  </p>
                  <div className="flex gap-2 flex-wrap">
                    <Radio
                      label="Full-time"
                      type="radio"
                      id="Full-time"
                      name="employmentType"
                      ChangeHandler={ChangeHandler}
                    />
                    <Radio
                      label="Part-time"
                      type="radio"
                      id="Part-time"
                      name="employmentType"
                      ChangeHandler={ChangeHandler}
                    />
                    <Radio
                      label="Contract"
                      type="radio"
                      id="Contract"
                      name="employmentType"
                      ChangeHandler={ChangeHandler}
                    />
                  </div>
                </div>

                {/* Payment Plan */}
                <div className="flex gap-3 flex-col xm:flex-row w-full">
                  <li className="list-disc ml-2 font-medium text-base text-black"
                  >
                    Payment Plan
                  </li>
                  <p className="text-[#FF6633] text-xs font-medium">Please select your payment plan for this role.</p>
                  <div className="flex gap-2 flex-wrap">
                    <Radio
                      label="Hourly"
                      name="paymentPlan"
                      type="radio"
                      id="Hourly"
                      ChangeHandler={ChangeHandler}
                    />
                    <Radio
                      label="Weekly"
                      name="paymentPlan"
                      type="radio"
                      id="Weekly"
                      ChangeHandler={ChangeHandler}
                    />
                    <Radio
                      label="Monthly"
                      name="paymentPlan"
                      type="radio"
                      id="Monthly"
                      ChangeHandler={ChangeHandler}
                    />
                    <Radio
                      label="per-day"
                      name="paymentPlan"
                      type="radio"
                      id="per-day"
                      ChangeHandler={ChangeHandler}
                    />
                  </div>
                </div>

                <div className="flex gap-3 flex-col xm:flex-row w-full">
                  <li className="list-disc ml-2 font-medium text-base text-black"
                  >
                    Compensation
                  </li>
                  <p className="text-[#FF6633] text-xs font-medium">
                    What is the expected pay for this position?
                  </p>
                  <TextInput
                    className="p-4 w-full rounded-lg border border-indigo-400 border-opacity-30 text-gray-900 focus:ring-indigo-400 focus:border-indigo-400 font-['Poppins'] text-base border-left-0"
                    name="compensation"
                    value={jobDescription.compensation}
                    placeholder="Compensation"
                    onChangeHandler={ChangeHandler}
                    id="compensation"
                  />
                </div>

                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <li className="list-disc ml-2 font-medium text-base text-black"
                  >
                    Deadline for Applications
                  </li>
                  <p className="text-[#FF6633] text-xs font-medium">
                    When do you intend to stop recruiting for this job?
                  </p>
                  {/* <BasicSelect
                  placeholder={`Select date here`}
                  id="deadline"
                  name="deadline"
                /> */}
                  {/* <CustomDate
                    ChangeHandler={ChangeHandler}
                    name="duration"
                    selectedDate={jobDescription.duration}
                  /> */}
                  <div className="relative w-full notranslate" data-notranslate>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <div className={` ${classes.notranslate} relative w-full`} data-notranslate>
                          <DatePicker
                            label="Duration"
                            notranslate
                            value={dayjs(jobDescription.duration)}
                            name="duration"
                            onChange={(e) => setJobDescription((prev) => ({
                              ...prev, duration: e["$M"] + 1 + '/' + e["$D"] + '/' + e["$y"]
                            }))} />
                          {/* <DatePicker
                          label="Controlled picker"
                          value={props.selectedDate}
                          name={props.name}
                          onChange={props.ChangeHandler}
                        /> */}
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="flex-col justify-start items-start gap-2 flex w-full">
                  <li className="list-disc ml-2 font-medium text-base text-black"
                  >
                    Job Location
                  </li>
                  <p className="text-[#FF6633] text-xs font-medium">
                    Please specify what city/town in which you need an employee for this job. We will use information to provide you the best hands in that city.
                  </p>
                  <div className="flex gap-3 flex-col md:flex-row w-full">
                    <TextInput
                      name="city"
                      placeholder="City"
                      onChangeHandler={ChangeHandler}
                      id="city"
                    />
                    <TextInput
                      name="state"
                      placeholder="State"
                      onChangeHandler={ChangeHandler}
                      id="state"
                    />
                    <TextInput
                      name="zipCode"
                      placeholder="Zipcode"
                      onChangeHandler={ChangeHandler}
                      id="zipCode"
                    />
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-between items-start gap-4 inline-flex">
                {
                  <button
                    className="px-3 xsm:px-5 text-sm sm:text-base py-3 justify-center items-start gap-2.5 flex items-center"
                    onClick={props.handleStepChangeBackward}
                  >
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16 8L17.43 9.393L11.85 15H24V17H11.85L17.43 22.573L16 24L8 16L16 8Z" fill="#FF6633" />
                      <path d="M16 30C18.7689 30 21.4757 29.1789 23.778 27.6406C26.0803 26.1022 27.8747 23.9157 28.9343 21.3576C29.9939 18.7994 30.2712 15.9845 29.731 13.2687C29.1908 10.553 27.8574 8.05845 25.8995 6.10051C23.9416 4.14258 21.447 2.80921 18.7313 2.26901C16.0155 1.72882 13.2006 2.00607 10.6424 3.06569C8.08426 4.12532 5.89776 5.91973 4.35942 8.22202C2.82108 10.5243 1.99999 13.2311 1.99999 16C2.00423 19.7117 3.48059 23.2702 6.10518 25.8948C8.72977 28.5194 12.2883 29.9958 16 30ZM16 4.00001C18.3734 4.00001 20.6934 4.70379 22.6668 6.02237C24.6402 7.34095 26.1783 9.21509 27.0865 11.4078C27.9948 13.6005 28.2324 16.0133 27.7694 18.3411C27.3064 20.6689 26.1635 22.8071 24.4853 24.4853C22.807 26.1635 20.6688 27.3064 18.3411 27.7694C16.0133 28.2325 13.6005 27.9948 11.4078 27.0866C9.21508 26.1783 7.34094 24.6402 6.02236 22.6668C4.70378 20.6935 3.99999 18.3734 3.99999 16C4.0037 12.8185 5.26917 9.76845 7.5188 7.51882C9.76844 5.26918 12.8185 4.00371 16 4.00001Z" fill="#FF6633" />
                    </svg>
                    <span className="text-center text-[#FF6633] text-sm sm:text-base font-semibold">
                      Previous
                    </span>
                  </button>
                }
                <button
                  type="submit"
                  className="px-3 xsm:px-5 py-3 text-sm sm:text-base bg-indigo-700 rounded-lg shadow justify-center items-start gap-2.5 flex items-center"
                  onClick={handleJobPreview}
                >

                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8L14.57 9.393L20.15 15H8V17H20.15L14.57 22.573L16 24L24 16L16 8Z" fill="white" />
                    <path d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05845 6.10051 6.10051C8.05845 4.14258 10.553 2.80921 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.06569C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C29.9958 19.7117 28.5194 23.2702 25.8948 25.8948C23.2702 28.5194 19.7117 29.9958 16 30ZM16 4.00001C13.6266 4.00001 11.3066 4.70379 9.33316 6.02237C7.35977 7.34095 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.69361 20.6689 5.83649 22.8071 7.51472 24.4853C9.19296 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2325 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9963 12.8185 26.7308 9.76845 24.4812 7.51882C22.2316 5.26918 19.1815 4.00371 16 4.00001Z" fill="white" />
                  </svg>

                  <span className="text-center text-white text-sm sm:text-base font-semibold whitespace-nowrap">
                    <>
                      {save ? (
                        "Update and continue"
                      ) : (
                        <>Save and Continue</>
                      )}
                    </>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      {startExecution == true ? <LoaderModal text="Processing job information" /> : null
      }
    </>
  );
}
