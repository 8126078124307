export default function BackButton(props) {
  return (
    <button
      className={`h-11 sm:pr-6 py-3 self-start rounded-3xl justify-start items-center gap-2.5 inline-flex ${props.className} focus:bg-transparent`}
      onClick={props.onClick}
    >
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.65625 16.4062H29.5312C29.8213 16.4062 30.0995 16.5215 30.3046 16.7266C30.5098 16.9317 30.625 17.2099 30.625 17.5C30.625 17.7901 30.5098 18.0683 30.3046 18.2734C30.0995 18.4785 29.8213 18.5938 29.5312 18.5938H7.65625C7.36617 18.5938 7.08797 18.4785 6.88285 18.2734C6.67773 18.0683 6.5625 17.7901 6.5625 17.5C6.5625 17.2099 6.67773 16.9317 6.88285 16.7266C7.08797 16.5215 7.36617 16.4062 7.65625 16.4062Z"
          fill="#FF6633"
        />
        <path
          d="M8.10905 17.4999L17.1806 26.5693C17.386 26.7747 17.5014 27.0532 17.5014 27.3437C17.5014 27.6341 17.386 27.9127 17.1806 28.118C16.9752 28.3234 16.6967 28.4388 16.4062 28.4388C16.1158 28.4388 15.8372 28.3234 15.6319 28.118L5.78812 18.2743C5.68626 18.1727 5.60545 18.052 5.55031 17.9191C5.49517 17.7862 5.46679 17.6438 5.46679 17.4999C5.46679 17.3561 5.49517 17.2136 5.55031 17.0807C5.60545 16.9478 5.68626 16.8271 5.78812 16.7255L15.6319 6.88179C15.8372 6.67641 16.1158 6.56104 16.4062 6.56104C16.6967 6.56104 16.9752 6.67641 17.1806 6.88179C17.386 7.08717 17.5014 7.36572 17.5014 7.65617C17.5014 7.94661 17.386 8.22516 17.1806 8.43054L8.10905 17.4999Z"
          fill="#FF6633"
        />
      </svg>
      <span className="text-center text-orange-500 text-base font-medium">
        Back
      </span>
    </button>
  );
}
