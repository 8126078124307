import React from "react";

export default function Radio(props) {
  return (
    <div class="flex items-center border border-indigo-400 border-opacity-30 w-max py-1 px-2 rounded-[8px]">
      <input
        id={props.id}
        type={props.type === 'radio' ? 'radio' : 'checkbox'}
        name={props.name}
        class="w-4 h-4 accent-red-500 border-indigo-400 accent-red focus:ring-transparent"
        onChange={props.ChangeHandler}
        value={props.label}
      />
      <label
        for={props.id}
        class="ml-2 text-black text-base font-normal"
      >
        {props.label}
      </label>
    </div>
  );
}
