import React, { useState } from 'react';
import FlagIconFactory from 'react-flag-icon-css';
import { saveIntoServerCache } from '../../../utils/saveIntoCache';
import "./index.css";

export default function CustomSelect({ countryCodes, handleSelectCountryCode, countryCode, hanldeSetCountryCodeCountry, countryCodeCountry }) {
    const [searchWord, setSearchWord] = useState("");
    const [isActive, setIsActive] = useState(false);

    const FlagIcon = FlagIconFactory(React, { useCssModules: false });

    const handleCountryClick = (countryCode, country) => {
        handleSelectCountryCode(countryCode);
        toggleActive();
        hanldeSetCountryCodeCountry(country);
        saveIntoServerCache("country_code", countryCode);
        saveIntoServerCache("country_code_country", country);
    };

    const handleSearchWord = (e) => {
        setSearchWord(e.target.value);
    }

    const toggleActive = () => {
        setIsActive(!isActive);
    }

    let arr = countryCodes.filter(country => {
        // Search within both code and country properties
        if (searchWord == "us" || searchWord == "US") {
            return country?.name.toLowerCase().includes("United States".toLowerCase());
        }
        else if (searchWord == "uk" || searchWord == "UK") {
            return country?.name.toLowerCase().includes("United Kingdom".toLowerCase());
        }
        return country?.code.includes(searchWord) ||
               country?.name.toLowerCase().includes(searchWord.toLowerCase());
        
      }).map(country => {
        return (<li onClick={() => handleCountryClick(country.code, country.name)} className={country?.code === countryCode ? "selected flex items-center justify-between" : "flex justify-between items-center"}>
            <FlagIcon code={country?.alpha2.toLowerCase()} size="lg" />
            <p className='truncate' style={{fontSize: "14px", whiteSpace: "nowrap", maxWidth: "130px", textOverflow: "ellipsis"}}>{country?.name}</p>
            <p>{country?.code}</p>
        </li>)
    });


    return (
        <div className={"wrapper" + (isActive ? " active" : "")}>
            <div className="select-btn border border-indigo-400 border-opacity-30 max-h-[60px]" onClick={toggleActive}>
                <span>{countryCode ? <FlagIcon code={countryCodes.find(country => country?.name === countryCodeCountry)?.alpha2.toLowerCase()}/> : "Select"}</span>
                <i className="uil uil-angle-down"></i>
            </div>
            <div className="content-drop border border-indigo-400 border-opacity-80">
                <div className="search">
                    <i className="uil uil-search"></i>
                    <input spellCheck="false" type="text" placeholder="Search" onChange={handleSearchWord} className='text-base'/>
                </div>
                <ul className="options w-[250px] lg:w-[300px]">
                    {
                        arr ? arr : <p style={{marginTop: "10px"}}>Oops! Country not found</p>
                    }
                </ul>
            </div>
        </div>
    );
}