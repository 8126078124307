import React, { useState, useEffect } from "react";
import MessageLanding from "./components/message_box/landing";
import MessageVerticalItem from "./components/message_vertical_item";
import { useSelector, useDispatch } from "react-redux";
import { getChatInformation, decision_loading, send_message_loading, end_to_end_chat_loading, getEndToEndChatInformation } from "../../Redux/ActionCreator";
import { baseURL } from "../../Redux/baseURL";
import timeResolver from "../common/timeResolver";
import { ToastContainer } from "react-toastify";

export default function EmployerInbox() {
  const dispatch = useDispatch();
  const userInfo = useSelector(e => e.userInfo.user);
  const chat_info = useSelector(e => e.chat_info.message);
  const login_stat = useSelector(e => e.login);
  const AcctType = login_stat?.profile_status?.AcctType;
  const [socket, setSocket] = useState(null);
  const [onlineUser, setOnlineUser] = useState([]);
  const [notification, setNotification] = useState([]);
  const employer = useSelector(e => e.employer);

  const matchedUserId = new URLSearchParams(window.location.search).get("matched");

  useEffect(() => {
    dispatch(send_message_loading(true));
    dispatch(end_to_end_chat_loading(true));
    dispatch(decision_loading(true));
    if (userInfo !== null && userInfo !== undefined) {
      dispatch(getChatInformation(userInfo?.profile_id));
    }
  }, []);

  useEffect(() => {
    if (userInfo !== null && userInfo !== undefined) {
      dispatch(getChatInformation(userInfo?.profile_id));
    }
  }, [userInfo]);

  console.log("chat_info", chat_info);

  return (
    <>
      <div className="Inbox px-3 sm:px-6 pb-2 h-calc-150 md:h-calc-70">
        <div className="flex flex-col max-w-[520px] mb-4 gap-y-1">
          <p className="text-black text-[24px] font-semibold  leading-normal">Messages and Interview</p>
          <p className="text-black text-base font-normal  leading-normal">Chat with candidates and interview them. Remember to send an offer card when you find a candidate who fits your needs.</p>
        </div>
        <div className="ipad:flex ipad:gap-[0.5rem] h-calc-chatarea">
          <div className="w-full max-w bg-white bg-opacity-80 rounded-xl shadow border border-indigo-300 border-opacity-30 xl:max-w-[320px] xtraxl:max-w-[340px]">
            <div className="ChatMessage h-full overflow-y-scroll px-4 pb-4">
              {chat_info !== null && (
                <>{chat_info?.map((data, key) => (
                  <MessageVerticalItem
                    name={data?.chatIds[0]?.employeeId?.Fullname}
                    position={data?.chatIds[0]?.jobTitle}
                    team="Design Team"
                    messageAge={timeResolver(data?.createdAt)}
                    messageNo={data?.Notification}
                    avatar={data?.chatIds[0]?.employeeId?.photo}
                    avatarStyle="bg-rose-300"
                    receiver={data?.chatIds[0]?.employeeId?.Fullname}
                    userType="employer"
                    index={chat_info?.indexOf(data)}
                    key={key}
                  />
                ))}</>
              )}
            </div>
          </div>
          <MessageLanding />
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
