import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(getItem, theme) {
  return {
    fontWeight:
    getItem
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectPlaceholder(props) {
  const theme = useTheme();

  return (
    <div className='w-full'>
      <FormControl fullWidth={true} sx={{ m: 1, mt: 3, maxWidth: "800px", width: '100%', py: '4px' }}>
        <Select
          displayEmpty
          value={props.selectedItem}
          onChange={props.handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected === undefined) {
              return <em>{props.placeholder}</em>;
            }

            return selected
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          
        >
          <MenuItem disabled value="" sx={{py: '4px'}}>
            <em>Placeholder</em>
          </MenuItem>
          {props.items.map((item) => (
            <MenuItem
              key={item}
              value={item}
              style={getStyles(props.selectedItem, theme)}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}